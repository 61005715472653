import { useState, useEffect } from "react";
import moment from "moment";
import PsychologyAltIcon from "@mui/icons-material/PsychologyAlt";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import PauseCircleFilledIcon from "@mui/icons-material/PauseCircleFilled";
import StrollerIcon from "@mui/icons-material/Stroller";
import VaccinesIcon from "@mui/icons-material/Vaccines";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";

export default function PrintIndvShift({ shift }) {
    //Organise number and types of shift
    const [onLeave, setOnLeave] = useState(false);
    const [leaveType, setLeaveType] = useState(0);
    const [leaveStatus, setLeaveStatus] = useState(0);

    useEffect(() => {
        //Check shift numbers and if there is any leave
        console.log(shift);
        if (shift.leave) {
            setOnLeave(true);
            setLeaveType(shift.leaveType);
            setLeaveStatus(shift.status);
        }
    }, []);

    return (
        <>
            {!onLeave ? (
                <p style={{ color: "black" }}>
                    {/* Display actual start and end if the shift if confirmed */}
                    {moment(shift.startTime).format("HH:mm")}-
                    {moment(shift.endTime).format("HH:mm")}
                    {!shift.published ? <span> (U)</span> : null}{" "}
                </p>
            ) : onLeave && leaveType == 1 && leaveStatus == 2 ? (
                <BeachAccessIcon
                    color="success"
                    fontSize="large"
                    sx={{
                        width: "95%",
                    }}
                />
            ) : onLeave && leaveType == 2 && leaveStatus == 2 ? (
                <BeachAccessIcon
                    color="secondary"
                    fontSize="large"
                    sx={{
                        width: "95%",
                    }}
                />
            ) : onLeave &&
              (leaveType == 1 || leaveType == 2) &&
              leaveStatus == 1 ? (
                <PauseCircleFilledIcon
                    color="warning"
                    fontSize="large"
                    sx={{
                        width: "95%",
                    }}
                />
            ) : onLeave && leaveType == 4 ? (
                <StrollerIcon
                    color="primary"
                    fontSize="large"
                    sx={{
                        width: "95%",
                    }}
                />
            ) : onLeave && leaveType == 3 ? (
                <VaccinesIcon
                    color="error"
                    fontSize="large"
                    sx={{
                        width: "95%",
                    }}
                />
            ) : onLeave && leaveType == 5 ? (
                <PsychologyAltIcon
                    color="warning"
                    fontSize="large"
                    sx={{
                        width: "95%",
                    }}
                />
            ) : onLeave && leaveType == 6 ? (
                <NewReleasesIcon
                    color="primary"
                    fontSize="large"
                    sx={{
                        width: "95%",
                    }}
                />
            ) : null}
        </>
    );
}
