import "./staffBanking.scss";
import { useState, useEffect } from "react";
import axios from "axios";
import { decryptData } from "../../../../utils/cryptoUtils";
import Loader from "../../../animations/Loader";
import IndvAccount from "./IndvAccount";

export default function StaffBanking() {
    const [bankData, setBankData] = useState([]);
    const excludeFieldsFromDecryption = [
        "companyName",
        "employeeDetailsId",
        "startDate",
    ];
    const [showLoader, setShowLoader] = useState(true);
    const [reload, setReload] = useState(false);
    const [token, setToken] = useState("");

    useEffect(() => {
        let user = localStorage.getItem("user");
        let parseU = JSON.parse(user);
        let localToken = parseU.token;
        setToken(localToken);
        let localConfig = {
            headers: {
                "Content-Type": "application/json",
                "x-auth-token": localToken,
            },
        };
        axios
            .get(
                `${process.env.REACT_APP_API_URL}/banking/pending`,
                localConfig
            )
            .then((res) => {
                getDecryptedData(res.data);
            })
            .catch((err) => {
                console.log(`Could not get all the bank details: ${err}`);
            });
    }, [reload]);

    const rel = () => {
        setReload(!reload);
    };

    const getDecryptedData = (data) => {
        setBankData([]);

        data.forEach((employee) => {
            let decryptedEmployee = {};
            Object.keys(employee).forEach((field) => {
                if (
                    !employee[field] ||
                    excludeFieldsFromDecryption.includes(field)
                ) {
                    decryptedEmployee[field] = employee[field];
                } else {
                    decryptedEmployee[field] = decryptData(
                        employee[field],
                        process.env.REACT_APP_EMP_KEY
                    );
                }
            });
            setBankData((bankData) => [...bankData, decryptedEmployee]);
        });
        setShowLoader(false);
    };
    return (
        <main className="staff_banking">
            <section className="banking_main">
                <h1 className="heading-master">Staff Banking</h1>
                {showLoader ? (
                    <Loader />
                ) : (
                    <>
                        {bankData && bankData.length > 0 ? (
                            <table>
                                <thead>
                                    <tr>
                                        <td>Employee name</td>
                                        <td>Site</td>
                                        <td>Bank Name</td>
                                        <td>Sortcode</td>
                                        <td>Account Number</td>
                                        <td>Start Date</td>
                                        <td>Confirm</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {bankData.map((data) => {
                                        return (
                                            <IndvAccount
                                                data={data}
                                                rel={rel}
                                                token={token}
                                            />
                                        );
                                    })}
                                </tbody>
                            </table>
                        ) : (
                            <h2>No changes to show</h2>
                        )}
                    </>
                )}
            </section>
        </main>
    );
}
