import { Button, Alert } from "@mui/material";
import IndvDiscrep from "./IndvDiscrep";
import SwapVerticalCircleIcon from '@mui/icons-material/SwapVerticalCircle';
import { useState, useEffect } from "react";
import { chargeDiscrepancies } from "../../../../../utils/apiUtils";

const alertStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60vw",
};

export default function UserDiscreps(props) {
  const discrepancies = props.discrepancies;
  const [showTable, setShowTable] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [user, setUser] = useState("");
  //alerts
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("userName")));
    setDisableBtn(props.letterSent)
  }, []);

  const handleTable = () => {
    setShowTable(!showTable);
  };

  const handleChargeAll = () => {
    setDisableBtn(true);
    let localDiscrepancies = [];
    for (let d = 0; d < discrepancies.length; d++) {
      let chargeId;
      if (discrepancies[d].currentCharge) {
        chargeId = discrepancies[d].currentCharge.chargeId;
      }
      localDiscrepancies.push({
        detailsId: discrepancies[d].detailsId,
        month: props.month,
        amount: discrepancies[d].totalLeft,
        chargeId: chargeId,
        user: user,
      });
    }
    chargeDiscrepancies(localDiscrepancies)
      .then((res) => {
        setDisableBtn(false);
        props.reloadMonth()
      })
      .catch((err) => {
        console.log(`Could not update the discrepancies: ${err}`);
        setMessage(`Could not update the discrepancy: ${err}`);
        setSeverity("error");
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
          setDisableBtn(false);
        }, 3000);
      });
    setTimeout(() => {
      setDisableBtn(false);
    }, 2000);
  };

  return (
    <section className="discrepancy">
      {showAlert ? (
        <Alert severity={severity} style={alertStyle} variant="filled">
          {message}
        </Alert>
      ) : null}
      <div className="discrepancy_header">
        <h4>
          Discrepancies for <span>{props.name}</span>
        </h4>
        <h4>
          Total: <span>£{props.total}</span>
        </h4>
        <Button
          variant="contained"
          color="success"
          onClick={handleChargeAll}
          id="charge_btn"
          disabled={disableBtn}
        >
          Charge all
        </Button>
        <SwapVerticalCircleIcon color="primary" onClick={handleTable} sx={{cursor: "pointer"}}/>
      </div>
      {showTable ? (
        <table>
          <thead>
            <tr>
              <th>Reason</th>
              <th>Notes</th>
              <th>Total Amount</th>
              <th>Paid</th>
              <th>Owed</th>
              <th>This Month Payment</th>
              <th>Transaction Date</th>
              <th>Confirm</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {discrepancies.map((discrep) => {
              return (
                <IndvDiscrep
                  discrepancy={discrep}
                  month={props.month}
                  user={user}
                  rel={props.reloadMonth}
                  disable={disableBtn}
                />
              );
            })}
          </tbody>
        </table>
      ) : null}
    </section>
  );
}
