import { useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";

export default function BankDetails(props) {
    const employeeDetails = props.employee;

  useEffect(() => {}, [props.employee]);

  return (
    <section className="employee_personal">
      <h4>Bank Details</h4>
      <div className="personal_row">
        <div className="personal_column">
          <div className="edit_row">
            <strong>
              Bank name: <span>{employeeDetails.bankingDetails.bankName}</span>
            </strong>
            <EditIcon
              fontSize="small"
              className="edit_icon"
              onClick={() => props.handleEdit("bank_name")}
            />
            <input
              type="text"
              maxLength={75}
              id="bank_name"
              onChange={(e) =>
                props.handleEditEmployee("bankingDetails.bankName", e.target.value)
              }
            />
          </div>
          <div className="edit_row">
            <strong>
              Account number:{" "}
              <span>{employeeDetails.bankingDetails.accountNumber}</span>
            </strong>
            <EditIcon
              fontSize="small"
              className="edit_icon"
              onClick={() => props.handleEdit("bank_account")}
            />
            <input
              type="number"
              pattern="[0-9]*"
              maxLength={8}
              id="bank_account"
              onChange={(e) =>
                props.handleEditEmployee(
                  "bankingDetails.accountNumber",
                  e.target.value
                )
              }
            />
          </div>
          <div className="edit_row">
            <strong>
              Sortcode: <span>{employeeDetails.bankingDetails.sortCode}</span>
            </strong>
            <EditIcon
              fontSize="small"
              className="edit_icon"
              onClick={() => props.handleEdit("bank_sort")}
            />
            <input
              type="number"
              pattern="[0-9]*"
              maxLength={6}
              id="bank_sort"
              onChange={(e) =>
                props.handleEditEmployee("bankingDetails.sortCode", e.target.value)
              }
            />
          </div>
        </div>
      </div>
    </section>
  );
}
