import React, { useState, useEffect } from "react";
import CompanyList from "./CompanyList";
import CompanyDetails from "./CompanyDetails";
import Header from "./../../header/Header";
import Footer from "./../../footer/Footer";
import { useAlert } from "../../context/AlertContext";
import { Box } from "@mui/material";
import {
  getCompanies,
  updateCompany,
  deleteCompany,
} from "../../../utils/apiUtils";

export default function UpdateCompany() {
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [companies, setCompanies] = useState([]);
  const { showAlert } = useAlert();
  const [token, setToken] = useState("");

  const fetchCompanies = async (token) => {
    try {
      const data = await getCompanies(token);
      setCompanies(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    let user = localStorage.getItem("user");
    let parseU = JSON.parse(user);
    let token = parseU.token;
    setToken(token);

    fetchCompanies(token);
  }, []);

  const handleSelectCompany = (company) => {
    setSelectedCompany(company);
  };

  const handleEditCompany = async (updatedCompany) => {
    try {
      await updateCompany(updatedCompany, token);
      showAlert("Company updated successfully", "success");
      setSelectedCompany(updatedCompany);
      fetchCompanies();
    } catch (error) {
      console.error("Error updating company:", error);
      showAlert("Error updating company");
    }
  };

  const handleDeleteCompany = async (companyId) => {
    try {
      await deleteCompany(companyId, token);

      showAlert("Company deleted successfully", "success");
      fetchCompanies();
      setSelectedCompany(null);
    } catch (error) {
      console.error("Error deleting company:", error);
      showAlert("Error deleting company");
    }
  };

  return (
    <>
      <Header />
      <Box sx={{ display: "flex", gap: 5 }}>
        <CompanyList
          companies={companies}
          onSelectCompany={handleSelectCompany}
          fetchCompanies={fetchCompanies}
        />
        {selectedCompany && (
          <CompanyDetails
            key={selectedCompany.companyId}
            company={selectedCompany}
            onEditCompany={handleEditCompany}
            onDeleteCompany={handleDeleteCompany}
          />
        )}
      </Box>
      <Footer />
    </>
  );
}
