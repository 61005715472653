import Footer from "../../../footer/Footer";
import Header from "../../../header/Header";
import '../../employeeManagement/manualContract/manualContract.scss'
import { useState, useEffect } from "react";
import { getCompanies, getPositions } from "../../../../utils/apiUtils";
import moment from "moment";
import { Alert, Button } from "@mui/material";
import { encryptData } from "../../../../utils/cryptoUtils";
import axios from "axios";

export default function ManualContract() {
  const [companies, setCompanies] = useState([]);
  const [roles, setRoles] = useState([]);
  const [message, setMessage] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [firstname, setFirstname] = useState("");
  const [fullName,setFullName]= useState("")
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [roleId, setRoleId] = useState();
  const [startDate, setStartDate] = useState("");
  const [contract, setContract] = useState("");
  const [hrUser, setHrUser] = useState("")
  const today = new Date();
  //alerts
  const [showAlert, setShowAlert] = useState("");
  const [severity, setSeverity] = useState("");

  const [token, setToken] = useState("");
  const config = {
    headers: { "Content-Type": "application/json", "x-auth-token": token },
  };

  useEffect(() => {
    let token = localStorage.getItem("user");
    let getToken = JSON.parse(token);
    let final = getToken.token;
    setToken(final);
    setHrUser(getToken.user)

    getCompanies(final)
      .then((data) => {
        setCompanies(data);
      })
      .catch((err) => {
        console.log(err);
      });

    getPositions(final)
      .then((data) => {
        setRoles(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleEmail = (mail) => {
    var userEmail = mail.toLowerCase()
    const regex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
      if (userEmail.match(regex)) {
        setEmail(userEmail);
      } else {
        setTimeout(() => {
          setMessage("Please insert the right format for the email");
          document.getElementById("email_alert").style.display = "flex";
          setTimeout(() => {
            document.getElementById("email_alert").style.display = "none";
            setMessage("");
          }, 3000);
        },500)
      }
  };

  const handleStartDate = (date) => {
    if (moment(today).format("YYYY-MM-DD") > date) {
      setMessage("Start date cannot be in the past");
      document.getElementById("start_alert").style.display = "flex";
      setTimeout(() => {
        document.getElementById("start_date").value = today;
        document.getElementById("start_alert").style.display = "none";
        setMessage("");
      }, 3000);
    } else {
      setStartDate(date);
    }
  };

  const hanleContract = (hours) => {
    if (parseInt(hours) < 0) {
      setMessage("Contracted hours cannot be negative");
      document.getElementById("hours_alert").style.display = "flex";
      setTimeout(() => {
        document.getElementById("contract_hours").value = "";
        document.getElementById("hours_alert").style.display = "none";
        setMessage("");
      }, 3000);
    } else if (parseInt(hours) > 45) {
      setMessage("Contracted hours cannot be over 45");
      document.getElementById("hours_alert").style.display = "flex";
      setTimeout(() => {
        document.getElementById("contract_hours").value = "";
        document.getElementById("hours_alert").style.display = "none";
        setMessage("");
      }, 3000);
    } else {
      setContract(hours);
    }
  };

  const handleSubmit = () => {
    document.getElementById("submit_btn").style.display = "none";
    console.log(firstname, lastname, fullName, companyId, email, startDate, contract)
    if (
      fullName === ""||
      companyId === "" ||
      email === "" ||
      startDate === "" ||
      contract === ""
    ) {
      setMessage("Please ensure all fields are filled in");
      setSeverity("warning");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
        setMessage("");
        setSeverity("");
        document.getElementById("submit_btn").style.display = "flex";
      }, 3500);
    } else {
      let data = {
        firstname: firstname,
        lastname: lastname,
        fullName:encryptData(fullName, process.env.REACT_APP_NS_ENC),
        email: encryptData(email, process.env.REACT_APP_NS_ENC),
        companyId: companyId,
        roleId: roleId,
        startDate: startDate,
        hours: contract,
        user: hrUser
      };
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/interview/new/manual`,
          data,
          config
        )
        .then((res) => {
          setMessage("Submitted");
          setSeverity("success");
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
            setMessage("");
            setSeverity("");
            document.getElementById("name_input").value = "";
            document.getElementById("email_input").value = "";
            document.getElementById("start_date").value = today;
            document.getElementById("contract_hours").value = "";
            setRoleId(null);
            setCompanyId(null);
            let inputs = document.querySelectorAll("select");
            for (let i = 0; i < inputs.length; i++) {
              inputs[i].selectedIndex = 0;
            }
            document.getElementById("submit_btn").style.display = "flex";
          }, 3500);
        })
        .catch((err) => {
          console.log(err);
          setMessage("Something went wrong, please try again");
          setSeverity("error");
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
            setMessage("");
            setSeverity("");
            document.getElementById("submit_btn").style.display = "flex";
          }, 3500);
        });
    }
  };

  return (
    <section className="manual__contracts">
      <Header />
      <h1>Insert employee details for NSP generation!</h1>
      <section className="contract">
        <div className="contract__row">
          <strong>Select a company:</strong>
          <select onChange={(e) => setCompanyId(parseInt(e.target.value))}>
            <option>--Please select one--</option>
            {companies.map(function (company, idx) {
              return (
                <option value={company.companyId} key={idx}>
                  {company.siteName}
                </option>
              );
            })}
          </select>
        </div>
        <div className="contract__row">
          <strong>Full Name:</strong>
          <input
            type="text"
            placeholder="Full Name"
            onChange={(e) => setFullName(e.target.value)}
            max={20}
            id="name_input"
          />
        </div>
        <div className="contract__row">
          <strong>Email:</strong>
          <input
            type="email"
            placeholder="employee@mail.com"
            onChange={(e) => handleEmail(e.target.value)}
            id="email_input"
            max={50}
          />
          <span id="email_alert" className="error_alert">
            {message}
          </span>
        </div>

        <div className="contract__row">
          <strong>Select a role:</strong>
          <select onChange={(e) => setRoleId(parseInt(e.target.value))}>
            <option>--Please select one--</option>
            {roles.map(function (role, idx) {
              return (
                <option value={role.roleId} key={idx}>
                  {role.roleName}
                </option>
              );
            })}
          </select>
        </div>
        <div className="contract__row">
          <strong>Start Date:</strong>
          <input
            type="date"
            min={today}
            onChange={(e) => setStartDate(e.target.value)}
            id="start_date"
          />
          <span id="start_alert" className="error_alert">
            {message}
          </span>
        </div>
        <div className="contract__row">
          <strong>Contracted hours:</strong>
          <input
            type="number"
            pattern="[0-9]*"
            onChange={(e) => hanleContract(e.target.value)}
            id="contract_hours"
          />
          <span id="hours_alert" className="error_alert">
            {message}
          </span>
        </div>
        <Button
          variant="contained"
          color="success"
          id="submit_btn"
          onClick={handleSubmit}
        >
          Send
        </Button>
        {showAlert ? <Alert severity={severity}>{message}</Alert> : null}
      </section>

      <Footer />
    </section>
  );
}
