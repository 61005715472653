import { useState, useEffect } from "react";
import axios from "axios";
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import { Alert, Button, Modal, Box, Stack, Typography } from "@mui/material";

export default function Alerts(props) {

    const [managers, setManagers] = useState([])
    const [showUpdateAlert, setShowUpdateAlert] = useState(false)
    const [users, setUsers] = useState([])
    const [extraUsers, setExtraUsers] = useState([])
    const [showUsers, setShowUsers] = useState(false)
    const [groupId, setGroupId] = useState(0)
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [style, setStyle] = useState({})

    useEffect(() => {
        setManagers(props.managers)
        setGroupId(props.groupId)
        let tempStyle = props.style;
        tempStyle.width = "50vw"
        setStyle(tempStyle)
    }, [props])

    const getUsers = async () => {
        await axios.get(`${process.env.REACT_APP_API_URL}/groups/alerts/users/${groupId}`).then((resp) => {
            setUsers(resp.data)
            setShowUsers(true)
        }).catch((err) => {
            console.log(err)
            setMessage("Something Went Wrong. Please Try Again.")
            setSeverity("error");
            setShowAlert(true)
            setTimeout(() => {
                setShowAlert(false)
                setMessage("")
                setSeverity("")
            }, 3000)
        })
    }

    const removeUser = async (idx) => {
        var removed = true
        if (managers[idx].removed != null) {
            removed = !managers[idx].removed;
        }
        const updatedManagers = [...managers];
        updatedManagers[idx].removed = removed;
        setManagers(updatedManagers);

        const filtered = updatedManagers.filter((m) => m.removed != null && m.removed == true)
        if (filtered.length > 0) {
            setShowUpdateAlert(true)
        } else {
            setShowUpdateAlert(false)
        }
    }

    const updateAlerts = async () => {

        const manArray = managers.filter((m) => m.removed == null || m.removed == false)
        var newManagerList = [...manArray, ...extraUsers]
        let data = {
            managers: newManagerList,
            groupId: groupId
        }

        await axios.post(`${process.env.REACT_APP_API_URL}/groups/update/alerts`, data).then((resp) => {
            console.log("Alerts Updated!")
            setShowUsers(false)
            setShowUpdateAlert(false)
            reset()
        }).catch((err) => {
            console.log(err)
            setMessage("Something Went Wrong. Please Try Again.")
            setSeverity("error");
            setShowAlert(true)
            setTimeout(() => {
                setShowAlert(false)
                setMessage("")
                setSeverity("")
            }, 3000)
        })
    }

    const addUser = async (email) => {
        var idx = extraUsers.findIndex((u)=>u.email==email)
        if(idx>=0){
            setExtraUsers([...extraUsers.slice(0, idx), ...extraUsers.slice(idx + 1)]);
        }else{
            setExtraUsers(oldArray => [...oldArray, { email: email }]);
        }
    }

    const reset = () => {
        setShowUsers(false)
        setExtraUsers([])
        props.reset();
    }

    return (
        <div className="splitSection">
            <div className="heading">
                <h3>Alerts</h3>
            </div>
            {showAlert ? (
                <Alert severity={severity} >{message}</Alert>
            ) : null}
            <div className="data">
                {managers.length > 0 ?
                    <>
                        {managers.map(function (d, i) {
                            var bgColor; d.removed !== null && d.removed == true ? bgColor = "#64c4e0" : bgColor = "";
                            return (
                                <div className="clickableData" style={{ backgroundColor: bgColor }} onClick={() => removeUser(i)}>
                                    <p key={i} >{d.email}</p>
                                </div>
                            )
                        })}

                        <Button onClick={getUsers} variant="contained" className="alertBtns addAlert" id="usr_btn">+</Button>
                        {showUpdateAlert ?
                            <Button onClick={updateAlerts} variant="contained" color="error" className="alertBtns removeAlert" id="usr_btn" ><PersonRemoveIcon /></Button>
                            : null}
                    </>
                    :
                    <p>Select Group</p>
                }

            </div>
            <Modal
                open={showUsers}
                aria-labelledby="stock-addition"
                aria-describedby="add-stock-to-product"
                onClose={reset}
            >
                <Box sx={style}>
                    <div id="payrollTable">
                        <Typography id="stock-addition" variant="h6" component="h2">
                            The following users are available to add to this group -
                        </Typography><hr />
                        <div style={{ columns: 2, textAlign: "center", marginTop: "2%", columnRule: "4px double #2c53a0 "}}>
                            {users.map(function (d, i) {
                                var bgColor;
                                var idx = extraUsers.findIndex((e) => e.email == d.email);
                                idx >= 0 ? bgColor = "#64c4e0" : bgColor = "";
                                var mTop; i == 0 ? mTop = "-0.2%" : mTop = "0%"
                                return (
                                    <p style={{ borderRadius: "20px", marginTop: mTop, backgroundColor: bgColor }} onClick={() => addUser(d.email)}>{d.email}</p>
                                )
                            })}
                        </div>

                    </div>
                    <Typography id="modal-modal-description" sx={{ mt: 2, position: "sticky", bottom: "0" }}>

                        {showAlert ? (
                            <Alert severity={severity} >{message}</Alert>
                        ) : null}
                        <Stack spacing={20} direction="row" sx={{ width: "35%", margin: "auto" }}>
                            <Button variant="contained" color="error" onClick={reset}>BACK</Button>
                            <Button variant="contained" color="success" onClick={updateAlerts}>CONFIRM</Button>

                        </Stack>
                    </Typography>

                </Box>
            </Modal>
        </div>
    )
}