import { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import Alert from "@mui/material/Alert";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

export default function Nok(props) {
  const employeeDetails = props.employee;
  const [clipAlert, setClipAlert] = useState(false);

  useEffect(() => {}, [props.employee]);

  const copy = (toCopy) => {
    navigator.clipboard.writeText(toCopy);
    setClipAlert(true);
    setTimeout(() => {
      setClipAlert(false);
    }, 1500);
  };

  return (
    <section className="employee_personal">
      <h4>Address and NOK details</h4>
      {clipAlert ? (
        <Alert severity="info" sx={style}>
          Copied to clipboard
        </Alert>
      ) : null}
      <div className="personal_row">
        <div className="personal_column">
          <h6>Address</h6>
          <div className="edit_row">
            <strong onClick={() => copy(employeeDetails.addressLine1)}>
              Address line 1: <span>{employeeDetails.addressLine1}</span>
            </strong>
            <EditIcon
              fontSize="small"
              className="edit_icon"
              onClick={() => props.handleEdit("addressLine1")}
            />
            <input
              type="text"
              maxLength={75}
              id="addressLine1"
              onChange={(e) =>
                props.handleEditEmployee("addressLine1", e.target.value)
              }
            />
          </div>
          <div className="edit_row">
            <strong>
              Address line 2: <span>{employeeDetails.addressLine2}</span>
            </strong>
            <EditIcon
              fontSize="small"
              className="edit_icon"
              onClick={() => props.handleEdit("addressLine2")}
            />
            <input
              type="text"
              maxLength={75}
              id="addressLine2"
              onChange={(e) =>
                props.handleEditEmployee("addressLine2", e.target.value)
              }
            />
          </div>
          <div className="edit_row">
            <strong>
              City: <span>{employeeDetails.city}</span>
            </strong>
            <EditIcon
              fontSize="small"
              className="edit_icon"
              onClick={() => props.handleEdit("city")}
            />
            <input
              type="text"
              maxLength={75}
              id="city"
              onChange={(e) => props.handleEditEmployee("city", e.target.value)}
            />
          </div>
          <div className="edit_row">
            <strong onClick={() => copy(employeeDetails.addressLine1)}>
              Postcode: <span>{employeeDetails.postcode}</span>
            </strong>
            <EditIcon
              fontSize="small"
              className="edit_icon"
              onClick={() => props.handleEdit("postcode")}
            />
            <input
              type="text"
              maxLength={75}
              id="postcode"
              onChange={(e) =>
                props.handleEditEmployee("postcode", e.target.value)
              }
            />
          </div>
          {/* <div className="edit_row">
            <strong>
              Country: <span>{employeeDetails.country}</span>
            </strong>
            <EditIcon
              fontSize="small"
              className="edit_icon"
              onClick={() => props.handleEdit("country")}
            />
            <input
              type="text"
              maxLength={75}
              id="country"
              onChange={(e) =>
                props.handleEditEmployee("country", e.target.value)
              }
            />
          </div> */}
        </div>
        <div className="personal_column">
          <h6>NOK</h6>
          <div className="edit_row">
            <strong>
              NOK Name: <span>{employeeDetails.nextOfKin.firstName}</span>
            </strong>
            <EditIcon
              fontSize="small"
              className="edit_icon"
              onClick={() => props.handleEdit("nokName")}
            />
            <input
              type="text"
              maxLength={75}
              id="nokName"
              onChange={(e) =>
                props.handleEditEmployee("nextOfKin.firstName", e.target.value)
              }
            />
          </div>
          <div className="edit_row">
            <strong>
              NOK Lastname: <span>{employeeDetails.nextOfKin.lastName}</span>
            </strong>
            <EditIcon
              fontSize="small"
              className="edit_icon"
              onClick={() => props.handleEdit("nokSurname")}
            />
            <input
              type="text"
              maxLength={75}
              id="nokSurname"
              onChange={(e) =>
                props.handleEditEmployee("nextOfKin.lastName", e.target.value)
              }
            />
          </div>
          <div className="edit_row">
            <strong>
              NOK number: <span>{employeeDetails.nextOfKin.phoneNumber}</span>
            </strong>
            <EditIcon
              fontSize="small"
              className="edit_icon"
              onClick={() => props.handleEdit("nokPhone")}
            />
            <input
              type="number"
              pattern="[0-9]*"
              maxLength={11}
              id="nokPhone"
              onChange={(e) =>
                props.handleEditEmployee(
                  "nextOfKin.phoneNumber",
                  e.target.value
                )
              }
            />
          </div>
        </div>
      </div>
    </section>
  );
}
