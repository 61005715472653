//MUI
import { Box } from "@mui/material";
import { Button } from "@mui/material";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import VaccinesIcon from "@mui/icons-material/Vaccines";
//Styling
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
};

export default function LeaveModal({ actualWeek, setLeaveModal }) {
   
    return (
        <Box sx={style} className="payroll_leave_modal">
            <h2>Employee Leave Breakdown</h2>
            <table>
                <thead>
                    <th>Leave Type</th>
                    <th>Total Days</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                </thead>
                <tbody>
                    {actualWeek.sickness.length > 0
                        ? actualWeek.sickness.map((d, i) => {
                              return (
                                  <tr key={i}>
                                      <td>
                                          <VaccinesIcon color="error" />
                                      </td>
                                      <td>{d.daysTaken}</td>
                                      <td>{d.startDate}</td>
                                      <td>{d.endDate}</td>
                                  </tr>
                              );
                          })
                        : null}
                    {actualWeek.holidays.length > 0
                        ? actualWeek.holidays.map((d, i) => {
                              return (
                                  <tr key={i}>
                                      <td>
                                          <BeachAccessIcon color="success" />
                                      </td>
                                      <td>{d.daysTaken}</td>
                                      <td>{d.startDate}</td>
                                      <td>{d.endDate}</td>
                                  </tr>
                              );
                          })
                        : null}
                </tbody>
            </table>
            <Button
                variant="contained"
                color="error"
                onClick={() => setLeaveModal(false)}
            >
                Close
            </Button>
        </Box>
    );
}
