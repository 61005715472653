import logo from "./RT_Logo.png";
import "../../payroll.scss";
import moment from "moment";

export default function DiscrepPdf(props) {
  const today = moment();
  const charges = props.charges;

  return (
    <section className="pdf_content">
      <section className="header">
        <img src={logo} alt="logo"/>
        <h4>Private &amp; Confidential</h4>
      </section>
      <section className="letter_body">
        <p>{today.format("dddd, Do MMMM YYYY")}</p>
        <p>Dear {props.name},</p>
        <p>
          The purpose of this letter is to inform you of your discrepancy total
          for the month. Throughout the previous month you had{" "}
          <strong>£{props.total}</strong> in discrepancies.</p>
          <p>This will be
          reflected on your individual payslip sent to you. If this figure is
          above £20.00 HR will write to you with a letter of concern in due
          course. This will set out your target for the upcoming month, only.
          All discrepancy information is provided to HR in a monetary value.
        </p>
        <p>Your discrepancies explained:</p>
        <table>
          <thead>
            <tr>
              <th>Amount</th>
              <th>Reason</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {charges.map((charge) => {
              if (charge.currentCharge === null){
                return null
              }
              return (
                <tr key={charge.detailsId}>
                  <td>£{charge.currentCharge.amount}</td>
                  {charge.sourceName === "Fuel" ? (
                    <td>Fuel - Mischarge</td>
                  ) : (
                    <td>{charge.reason}</td>
                  )}
                  <td>
                    {new Date(charge.transactionDate).toLocaleDateString(
                      "en-GB"
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <p>
          If you have any questions in regards to your discrepancies you should
          ask your line manager.
        </p>
        <p>Kind regards</p>
        <strong>HR Team</strong>
        <span style={{ color: "#878787", fontSize: "10pt" }}>
          HR Department
          <br />
          RaceTrack Pitstop
        </span>
        <div
          style={{
            maxWidth: "300px",
            fontSize: "0.9em",
            whiteSpace: "nowrap",
            borderLeft: "2px solid #BF1A1A",
            marginLeft: "0",
            paddingLeft: "20px",
          }}
        >
          <div>
            <div>
              <span>w: </span>
              <a
                style={{ color: "#BF1A1A" }}
                href="https://racetrackpitstop.co.uk"
                target="_blank"
                rel="noreferrer"
              >
                racetrackpitstop.co.uk
              </a>
            </div>
            <div>
              <span>e: </span>
              <a
                style={{ color: "#BF1A1A" }}
                href="mailto:hr@racetrackpitstop.co.uk"
              >
                hr@racetrackpitstop.co.uk
              </a>
            </div>
            <div>
              <span style={{ marginRight: "8px" }}>
                <span>p: </span>
                <a style={{ color: "#BF1A1A" }} href="tel:0141 319 8451">
                  0141 319 8451
                </a>
              </span>
            </div>
            <div style={{ wordWrap: "break-word" }}>
              <span>a: </span>
              <a
                style={{ wordWrap: "break-word", color: "#BF1A1A" }}
                href="https://maps.google.com/?q=St.+James+Church+30+Underwood+Road+Paisley+PA3+1TL"
                target="_blank"
                rel="noreferrer"
              >
                St. James Church, 30 Underwood Road, Paisley, PA3 1TL
              </a>
            </div>
            <div></div>
          </div>
        </div>
      </section>
    </section>
  );
}
