import Footer from "../../footer/Footer";
import Header from "../../header/Header";
import { useState, useEffect } from "react";
import axios from "axios";
import "./admin.scss";
import { Alert, Button, Modal, Box, Stack, Typography } from "@mui/material";
import Alerts from "./HolidayGrouping/Alerts";
import Updates from "./HolidayGrouping/Updates";
import Roles from "./HolidayGrouping/Roles";
import Unassigned from "./HolidayGrouping/Unassigned";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "30vw",
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "1rem",
    boxShadow: 24,
    maxHeight: "70vh",
    overflowY: "scroll",
    p: 4,
};

export default function HolidayGroups() {
    const [user, setUser] = useState("");
    const [token, setToken] = useState("")
    const [groupName, setGroupName] = useState("")
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("");

    const [showAlert, setShowAlert] = useState(false);
    const [showAddGroup, setShowAddGroup] = useState(false)
    const [reload, setReload] = useState(false);

    const [groupData, setGroupData] = useState([])
    const [childData, setChildData] = useState([])
    const [managers, setManagers] = useState([])

    const [groupId, setGroupId] = useState(0);
    const [child, setChild] = useState({})

    const config = { headers: { "Content-Type": "application/json", "x-auth-token": token } };

    useEffect(() => {
        let checkId = localStorage.getItem("user");
        let parsed = JSON.parse(checkId);
        let token = parsed.token

        setUser(parsed.user);
        setToken(token)

        axios.get(`${process.env.REACT_APP_API_URL}/groups/all`).then((resp) => {
            setGroupData(resp.data)
            if (groupId != 0) {
                var idx = resp.data.findIndex((g) => g.groupId == groupId);
                selectGroup(idx, resp.data)
            }
        }).catch((err) => {
            setMessage("Something went wrong. Please try again.")
            setSeverity("error");
            setShowAlert(true)
            setTimeout(() => {
                setShowAlert(false)
                setMessage("")
                setSeverity("")
            }, 3000)
        })

    }, [reload]);

    const selectGroup = async (idx, arr) => {
        let data = groupData;
        if (arr) {
            data = arr
        }
        setChildData(data[idx].childData)
        setGroupId(data[idx].groupId)
        setManagers(data[idx].managerAlerts)
        setChild({})
    }

    const selectChild = async (idx) => {
        setChild(childData[idx])
    }

    const addGroup = async () => {

        if (groupName == "") {
            setMessage("You must enter a group name!")
            setSeverity("warning");
            setShowAlert(true)
            setTimeout(() => {
                setShowAlert(false)
                setMessage("")
                setSeverity("")
            }, 3000)
        } else {
            await axios.post(`${process.env.REACT_APP_API_URL}/groups/new`, { groupName: groupName }, config).then((resp) => {
                setMessage("Successfully added new group.")
                setSeverity("success");
                setShowAlert(true)
                setTimeout(() => {
                    setShowAlert(false)
                    setMessage("")
                    setSeverity("")
                    reset();
                }, 3000)
            }).catch((err) => {
                setMessage("Something went wrong. Please try again.")
                setSeverity("error");
                setShowAlert(true)
                setTimeout(() => {
                    setShowAlert(false)
                    setMessage("")
                    setSeverity("")
                }, 3000)
            })
        }

    }

    const reset = () => {
        setShowAddGroup(false)
        setReload(!reload)
    }

    return (
        <main className="main">
            <Header />
            <h1>Holiday Groups</h1>

            <div className="holidayGroup" >
                <section >
                    <div className="heading">
                        <h3>Groups</h3>
                    </div>
                    <div className="groupData">
                        {groupData.map(function (d, i) {
                            var bgColor; groupId == d.groupId ? bgColor = "#64c4e0" : bgColor = ""
                            return (
                                <div className="clickableData" style={{ backgroundColor: bgColor }} onClick={() => selectGroup(i)}>
                                    <p key={i} >{d.name}</p>
                                </div>
                            )
                        })}
                    </div>
                    <Button onClick={() => setShowAddGroup(!showAddGroup)} variant="contained" className="grp_btn" id="grp_btn">+</Button>
                </section>

                <section >
                    <Roles childData={childData} selectChild={selectChild} child={child} />
                    <Alerts managers={managers} groupId={groupId} style={style} reset={reset} />
                </section>

                <Updates child={child} reset={reset} groupData={groupData} />

            </div>

            <Unassigned style={style} groupData={groupData} reset={reset} />

            <Modal
                open={showAddGroup}
                aria-labelledby="stock-addition"
                aria-describedby="add-stock-to-product"
                onClose={reset}
            >
                <Box sx={style}>
                    <div style={{ textAlign: "center" }}>
                        <Typography id="stock-addition" variant="h6" component="h2">
                            Add New Group
                        </Typography>

                        <input type="text" placeholder="Enter group name ..." onChange={(e) => setGroupName(e.target.value)} />

                    </div>
                    <Typography id="modal-modal-description" sx={{ mt: 2, position: "sticky", bottom: "0" }}>
                        <Stack spacing={12} direction="row" sx={{ width: "50%", margin: "auto" }}>
                            <Button variant="contained" color="error" onClick={reset}>BACK</Button>
                            <Button variant="contained" color="success" onClick={addGroup}>CONFIRM</Button>
                        </Stack>
                        {showAlert ? (
                            <Alert severity={severity}>{message}</Alert>
                        ) : null}
                    </Typography>
                </Box>
            </Modal>


            <Footer />
        </main>
    )
}