import { useState, useEffect } from "react";
import axios from "axios";
import { Button, Typography, Box } from "@mui/material";
import { decryptData } from "../../../../utils/cryptoUtils";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  maxHeight: "90vh",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "1rem",
  boxShadow: 24,
  p: 4,
  overflowY: "scroll",
};

export default function InterviewModal({ modalData, close }) {
  const [data, setData] = useState({});
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    let token = localStorage.getItem("user");
    let getToken = JSON.parse(token);
    let final = getToken.token;
    const config = {
      headers: { "Content-Type": "application/json", "x-auth-token": final },
    };

    setData({
      name: decryptData(modalData.firstName, process.env.REACT_APP_EMP_KEY),
      lastname: decryptData(modalData.lastName, process.env.REACT_APP_EMP_KEY),
      companyName: modalData.companyName,
      role: modalData.roleName,
    });
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/interview/questions/${modalData.employeeDetailsId}`,
        config
      )
      .then((res) => {
        setQuestions(res.data.questions);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Box sx={style}>
      <Typography
        id="review-contracts"
        variant="h6"
        component="h2"
        sx={{ textAlign: "center" }}
      >
        Review Interview
      </Typography>
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        <section className="review_interview_modal">
          <h4>
            Applicant name: <span>{data.name}</span>
          </h4>
          <h4>
            Applicant lastname: <span>{data.lastname}</span>
          </h4>
          {questions && questions.length > 0 ? (
            questions.map((question) => {
              return (
                <>
                  <strong>{question.question}</strong>
                  <p>{question.answer}</p>
                </>
              );
            })
          ) : (
            <h3>
              This employee did not go through the in-store interview process.
            </h3>
          )}
        </section>
      </Typography>
    </Box>
  );
}
