import "./rota.scss";
import { useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import Rota from "./Rota";
import moment from "moment";
import { decryptData } from "./cryptoUtils";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { getEmployeesByCompany } from "../../../../utils/apiUtils";
import { Button, Alert } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import PublishIcon from "@mui/icons-material/Publish";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import PrintIcon from "@mui/icons-material/Print";
import Modal from "@mui/material/Modal";
import NotesModal from "./modal/NotesModal";
import ReactToPrint from "react-to-print";
import { getCompanies } from "../../../../utils/apiUtils";
import { getSitesData } from "../../../../utils/apiUtils";
import SquareIcon from "@mui/icons-material/Square";
import StrollerIcon from "@mui/icons-material/Stroller";
import VaccinesIcon from "@mui/icons-material/Vaccines";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import PsychologyAltIcon from "@mui/icons-material/PsychologyAlt";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import PauseCircleFilledIcon from "@mui/icons-material/PauseCircleFilled";
import ModeNightIcon from "@mui/icons-material/ModeNight";
import Loader from "../../../animations/Loader";
import Publish from "./modal/Publish";
import Box from "@mui/material/Box";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ResetRota from "./modal/ResetRota";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

export default function RotaHome() {
    //Main - Admin
    const url = "https://nitropos-backend.nitrosolutions.co.uk";
    const hrUrl = process.env.REACT_APP_API_URL;

    const [token, setToken] = useState("");
    const config = {
        headers: { "Content-Type": "application/json", "x-auth-token": token },
    };
    const componentRef = useRef();
    const myRefname = useRef(null);

    //User
    const [user, setUser] = useState("");

    //Sites
    const [managedSites, setManagedSites] = useState([]);
    const [siteName, setSiteName] = useState("");
    const [siteId, setSiteId] = useState("");
    const [oneSiteManager, setOneSiteManager] = useState(false);
    const [addEmployeeSites, setAddEmployeeSites] = useState([]);

    //Dates
    const [currentDate, setCurrentDay] = useState(moment().format());
    const [firstWeekDay, setFirstWeekDay] = useState("");
    const [previousWeek, setPreviousWeek] = useState(false);
    const [isCurrentWeek, setIsCurrentWeek] = useState(false);
    const [shortDates, setShortDates] = useState([]);
    const [fullDates, setFullDates] = useState([]);

    //Rota
    const [rotaId, setRotaId] = useState("");
    const [rotaNotes, setRotaNotes] = useState([]);
    const [published, setPublished] = useState(false);
    const [confirmedDates, setConfirmedDates] = useState({});

    //Staff
    const [staffList, setStaffList] = useState([]);
    const [tempStaffList, setTempStaffList] = useState([]);
    const [employeeList, setEmployeeList] = useState([]);
    const [addEmployeeId, setAddEmployeeId] = useState("");
    const [lastEmployeeId, setLastEmployeeId] = useState(null);

    //Filters
    const [brands, setBrands] = useState([]);
    const [roles, setRoles] = useState([]);

    //Alerts
    const [employeeAlert, setEmployeeAlert] = useState(false);
    const [employeeMessage, setEmployeeMessage] = useState("");
    const [employeeSeverity, setEmployeeSeverity] = useState("");
    const [loader, setLoader] = useState(false);

    //Modals
    const [noteModal, setNoteModal] = useState(false);
    const [publishModal, setPublishModal] = useState(false);
    const [resetRota, setResetRota] = useState(false);
    //Outer page Navigation Trigger
    const location = useLocation();
    const employeeId = location.state?.employeeId;

    //PARAMS
    const [searchParams] = useSearchParams();
    const site = searchParams.get("site");
    const pStart = searchParams.get("start");
    const pEnd = searchParams.get("end");
    const pEmployee = searchParams.get("employee");

    //ON PAGE RENDER / RELOAD
    useEffect(() => {
        //updates moment to get first day of the week Saturday
        //first week of the year must contained 1st of January

        moment.updateLocale("en-UK", {
            week: {
                dow: 6,
                doy: 12,
            },
        });
        setStaffList([]);
        setTempStaffList([]);

        //Get user save to state
        let userD = localStorage.getItem("user");
        let user = JSON.parse(userD);
        setUser(user.user);
        setToken(user.token);

        //Set Dates
        let weekShortDates = [];
        let weekFullDates = [];
        for (let w = 0; w <= 6; w++) {
            if (w === 0) {
                setFirstWeekDay(moment().weekday(0).format("DD-MM-YYYY"));
            }
            weekShortDates.push(moment().weekday(w).format("dddd - Do"));
            weekFullDates.push(moment().weekday(w).format("YYYY-MM-DD"));
        }

        setShortDates(weekShortDates);
        setFullDates(weekFullDates);

        //Set site list for adding employees
        async function fecthCompanies() {
            await getCompanies(user.token).then((res) => {
                setAddEmployeeSites(res);
            });
        }
        fecthCompanies();

        //Get Sites for HR
        async function fetchSites() {
            await getSitesData().then((res) => {
                let formattedSites = res.map((site, i) => {
                    return { eposId: site.epos, siteName: site.name };
                });
                setManagedSites(formattedSites);
            });
        }
        fetchSites();
        //This will run if params
        const loadIfParams = async () => {
            setSiteId(site);
            handleSiteSelection(site);
            setTimeout(() => {
                reloadData(
                    pEmployee,
                    moment(pStart).format("YYYY-MM-DD"),
                    moment(pEnd).format("YYYY-MM-DD"),
                    site,
                    user.token
                );
            }, 500);
        };
        if (site) {
            loadIfParams();
        }
    }, []);

    //Reload Data
    const reloadData = async (
        employeeId,
        start = "",
        end = "",
        _site = siteId
    ) => {
        console.log(employeeId, start, end, _site);
        //updates moment to get first day of the week Saturday
        //first week of the year must contained 1st of January
        moment.updateLocale("en-UK", {
            week: {
                dow: 6,
                doy: 12,
            },
        });

        setStaffList([]);
        setTempStaffList([]);
        await axios
            .post(
                `${hrUrl}/rota/employees/week`,
                {
                    siteId: parseInt(_site),
                    start: start == "" ? fullDates[0] : start,
                    end: end == "" ? fullDates[6] : end,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "x-auth-token": token,
                    },
                }
            )
            .then(async (res) => {
                setPublished(res.data.rota.published);
                setRotaId(res.data.rota.rotaId);
                setRotaNotes(res.data.rota.notes);
                setConfirmedDates(res.data.rota.confirmedDates);
                //To find out if the week is the current week
                for (let i = 0; i < fullDates.length; i++) {
                    let findDate = fullDates.some(
                        (day) => day === moment().format("YYYY-MM-DD")
                    );
                    findDate ? setIsCurrentWeek(true) : setIsCurrentWeek(false);
                }
                const decryptEmployees = await res.data.staff.map(
                    (employee) => ({
                        ...employee,
                        firstName: decryptData(
                            employee.firstName,
                            process.env.REACT_APP_EMP_KEY
                        ),
                        lastName: decryptData(
                            employee.lastName,
                            process.env.REACT_APP_EMP_KEY
                        ),
                        position: employee.position,
                        worked: employee.worked,
                    })
                );
                let sorted1 = decryptEmployees.sort(
                    (a, b) =>
                        a.priority - b.priority ||
                        a.roleId - b.roleId ||
                        a.firstName.localeCompare(b.firstName)
                );
                setStaffList(sorted1);
                setTempStaffList(sorted1);

                let brandsArr = [];
                let rolesArr = [];
                for (let i = 0; i < res.data.staff.length; i++) {
                    if (!brandsArr.includes(res.data.staff[i].name)) {
                        brandsArr.push(res.data.staff[i].name);
                    }
                    if (!rolesArr.includes(res.data.staff[i].position)) {
                        rolesArr.push(res.data.staff[i].position);
                    }
                }
                setBrands(brandsArr);
                setRoles(rolesArr);

                if (employeeId) {
                    let scrollHere = `employee-row-${employeeId}`;
                    document.getElementById(scrollHere).scrollIntoView({
                        block: "center",
                        behavior: "smooth",
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    //Show Alert for adding employee to rota
    const showAddEmployeeAlert = (message, severity) => {
        setEmployeeMessage(message);
        setEmployeeSeverity(severity);
        setEmployeeAlert(true);
        setTimeout(() => {
            setEmployeeAlert(false);
            setEmployeeMessage("");
            setEmployeeSeverity("");
        }, 3000);
    };

    //Select a site for rota to appear
    const handleSiteSelection = async (id) => {
        // updates moment to get first day of the week Saturday
        // first week of the year must contained 1st of January
        moment.updateLocale("en-UK", {
            week: {
                dow: 6,
                doy: 12,
            },
        });

        setLoader(true);
        setStaffList([]);
        setTempStaffList([]);
        setPreviousWeek(false);
        setEmployeeList([]);
        setCurrentDay(moment().format());
        setSiteId(id);

        for (let ms = 0; ms < managedSites.length; ms++) {
            if (managedSites[ms].eposId == id) {
                setSiteName(managedSites[ms].siteName);
            }
        }

        // Set Dates
        let weekShortDates = [];
        let weekFullDates = [];

        for (let w = 0; w <= 6; w++) {
            if (w === 0) {
                setFirstWeekDay(moment().weekday(0).format("DD-MM-YYYY"));
            }
            weekShortDates.push(moment().weekday(w).format("dddd - Do"));
            weekFullDates.push(moment().weekday(w).format("YYYY-MM-DD"));
        }

        setShortDates(weekShortDates);
        setFullDates(weekFullDates);

        // if(rotaid){
        //     console.log("rotaid found!")
        //     await axios
        //     .post(
        //         `${hrUrl}/rota/employees/week`,
        //         { rotaId: id, start: weekFullDates[0], end: weekFullDates[6] },
        //         {
        //             headers: {
        //                 "Content-Type": "application/json",
        //                 "x-auth-token": token,
        //             },
        //         }
        //     )
        //     .then(async (res) => {
        //         setPublished(res.data.rota.published);
        //         setRotaId(res.data.rota.rotaId);
        //         setRotaNotes(res.data.rota.notes);
        //         setConfirmedDates(res.data.rota.confirmedDates);

        //         //To find out if the week is the current week
        //         for (let i = 0; i < weekFullDates.length; i++) {
        //             let findDate = weekFullDates.some(
        //                 (day) => day === moment().format("YYYY-MM-DD")
        //             );
        //             findDate ? setIsCurrentWeek(true) : setIsCurrentWeek(false);
        //         }
        //         const decryptEmployees = await res.data.staff.map(
        //             (employee) => ({
        //                 ...employee,
        //                 firstName: decryptData(
        //                     employee.firstName,
        //                     process.env.REACT_APP_EMP_KEY
        //                 ),
        //                 lastName: decryptData(
        //                     employee.lastName,
        //                     process.env.REACT_APP_EMP_KEY
        //                 ),
        //                 position: employee.position,
        //                 worked: employee.worked,
        //             })
        //         );

        //         let sorted1 = decryptEmployees.sort((a, b) => a.priority - b.priority || a.roleId - b.roleId || a.firstName.localeCompare(b.firstName))
        //         setStaffList(sorted1)
        //         setTempStaffList(sorted1);

        //         let brandsArr = []
        //         let rolesArr = []
        //         for (let i = 0; i < res.data.staff.length; i++) {
        //             if (!brandsArr.includes(res.data.staff[i].name)) {
        //                 brandsArr.push(res.data.staff[i].name)
        //             }
        //             if (!rolesArr.includes(res.data.staff[i].position)) {
        //                 rolesArr.push(res.data.staff[i].position)
        //             }
        //         }
        //         setBrands(brandsArr)
        //         setRoles(rolesArr)
        //         setLoader(false);
        //     })
        //     .catch((err) => {
        //         console.log(err);
        //         setLoader(false);
        //     });
        // }else{
        await axios
            .post(
                `${hrUrl}/rota/employees/week`,
                { siteId: id, start: weekFullDates[0], end: weekFullDates[6] },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "x-auth-token": token,
                    },
                }
            )
            .then(async (res) => {
                setPublished(res.data.rota.published);
                setRotaId(res.data.rota.rotaId);
                setRotaNotes(res.data.rota.notes);
                setConfirmedDates(res.data.rota.confirmedDates);

                //To find out if the week is the current week
                for (let i = 0; i < weekFullDates.length; i++) {
                    let findDate = weekFullDates.some(
                        (day) => day === moment().format("YYYY-MM-DD")
                    );
                    findDate ? setIsCurrentWeek(true) : setIsCurrentWeek(false);
                }
                const decryptEmployees = await res.data.staff.map(
                    (employee) => ({
                        ...employee,
                        firstName: decryptData(
                            employee.firstName,
                            process.env.REACT_APP_EMP_KEY
                        ),
                        lastName: decryptData(
                            employee.lastName,
                            process.env.REACT_APP_EMP_KEY
                        ),
                        position: employee.position,
                        worked: employee.worked,
                    })
                );

                let sorted1 = decryptEmployees.sort(
                    (a, b) =>
                        a.priority - b.priority ||
                        a.roleId - b.roleId ||
                        a.firstName.localeCompare(b.firstName)
                );
                setStaffList(sorted1);
                setTempStaffList(sorted1);

                let brandsArr = [];
                let rolesArr = [];
                for (let i = 0; i < res.data.staff.length; i++) {
                    if (!brandsArr.includes(res.data.staff[i].name)) {
                        brandsArr.push(res.data.staff[i].name);
                    }
                    if (!rolesArr.includes(res.data.staff[i].position)) {
                        rolesArr.push(res.data.staff[i].position);
                    }
                }
                setBrands(brandsArr);
                setRoles(rolesArr);
                setLoader(false);
            })
            .catch((err) => {
                console.log(err);
                setLoader(false);
            });
        // }
    };

    //React Print functions
    const handleClick = () => {
        myRefname.current.click();
    };
    const linkToPrint = () => {
        return (
            <Button
                variant="contained"
                color="warning"
                ref={myRefname}
                style={{ display: "none" }}
            >
                Print PDF
            </Button>
        );
    };

    //If adding an employee select their core site
    const handleSelectSite = async (siteId) => {
        if (siteId) {
            let allEmployees = await getEmployeesByCompany(siteId, token);
            setEmployeeList(allEmployees);
        } else {
            setEmployeeList([]);
            setAddEmployeeId("");
        }
    };

    //Select an employee from the previously selected site
    const handleAddEmployee = () => {
        let employeeExists = false;

        let data = {
            siteId: siteId,
            employeeDetailsId: addEmployeeId,
            start: moment(firstWeekDay, "DD-MM-YYYY").format("YYYY-MM-DD"),
            end: moment(firstWeekDay, "DD-MM-YYYY")
                .add(6, "d")
                .format("YYYY-MM-DD"),
        };

        for (let s = 0; s < staffList.length; s++) {
            if (staffList[s].employeeDetailsId === Number(addEmployeeId)) {
                employeeExists = true;
            }
        }

        if (addEmployeeId === "") {
            showAddEmployeeAlert("Please select an employee", "warning");
        } else if (employeeExists) {
            showAddEmployeeAlert("Employee already added", "warning");
        } else {
            axios
                .post(`${hrUrl}/rota/employee/add `, data)
                .then(async (res) => {
                    showAddEmployeeAlert("Employee added to rota", "success");
                    setAddEmployeeId("");
                    setEmployeeList([]);
                    reloadData();
                })
                .catch((err) => {
                    console.log(err);
                    showAddEmployeeAlert("Couldn't add employee", "error");
                });
        }
    };

    //Go to previous rota week
    const handlePrevWeek = () => {
        //updates moment to get first day of the week Saturday
        //first week of the year must contained 1st of January
        moment.updateLocale("en-UK", {
            week: {
                dow: 6,
                doy: 12,
            },
        });
        setStaffList([]);
        setTempStaffList([]);
        setEmployeeList([]);
        let prevWeek = moment(currentDate).subtract(1, "week").format();
        setCurrentDay(prevWeek);
        let week = [];
        let beWeek = [];

        for (let w = 0; w <= 6; w++) {
            if (w === 0) {
                setFirstWeekDay(
                    moment(prevWeek).weekday(w).format("DD-MM-YYYY")
                );
            }
            week.push(moment(prevWeek).weekday(w).format("dddd - Do"));
            beWeek.push(moment(prevWeek).weekday(w).format("YYYY-MM-DD"));
        }

        axios
            .post(
                `${hrUrl}/rota/employees/week`,
                { siteId: siteId, start: beWeek[0], end: beWeek[6] },
                config
            )
            .then((res) => {
                setPublished(res.data.rota.published);
                setRotaId(res.data.rota.rotaId);
                setRotaNotes(res.data.rota.notes);
                setConfirmedDates(res.data.rota.confirmedDates);
                //To find out if this is a week in the past
                moment().diff(
                    moment(res.data.rota.startDate).format(),
                    "days"
                ) > 6
                    ? setPreviousWeek(true)
                    : setPreviousWeek(false);

                //To find out if the week is the current week
                for (let i = 0; i < beWeek.length; i++) {
                    let findDate = beWeek.some(
                        (day) => day === moment().format("YYYY-MM-DD")
                    );

                    findDate ? setIsCurrentWeek(true) : setIsCurrentWeek(false);
                }

                const decryptEmployees = res.data.staff.map((employee) => ({
                    ...employee,
                    firstName: decryptData(
                        employee.firstName,
                        process.env.REACT_APP_EMP_KEY
                    ),
                    lastName: decryptData(
                        employee.lastName,
                        process.env.REACT_APP_EMP_KEY
                    ),
                    position: employee.position,
                    worked: employee.worked,
                }));

                let sorted1 = decryptEmployees.sort(
                    (a, b) =>
                        a.priority - b.priority ||
                        a.roleId - b.roleId ||
                        a.firstName.localeCompare(b.firstName)
                );
                setStaffList(sorted1);
                setTempStaffList(sorted1);

                let brandsArr = [];
                let rolesArr = [];
                for (let i = 0; i < res.data.staff.length; i++) {
                    if (!brandsArr.includes(res.data.staff[i].name)) {
                        brandsArr.push(res.data.staff[i].name);
                    }
                    if (!rolesArr.includes(res.data.staff[i].position)) {
                        rolesArr.push(res.data.staff[i].position);
                    }
                }
                setBrands(brandsArr);
                setRoles(rolesArr);
            })
            .catch((err) => {
                console.log(err);
            });

        setShortDates(week);
        setFullDates(beWeek);
    };

    //Go to next rota week
    const handleNextWeek = () => {
        //updates moment to get first day of the week Saturday
        //first week of the year must contained 1st of January
        moment.updateLocale("en-UK", {
            week: {
                dow: 6,
                doy: 12,
            },
        });
        setStaffList([]);
        setTempStaffList([]);
        setEmployeeList([]);

        let nextWeek = moment(currentDate).add(1, "week").format();
        setCurrentDay(nextWeek);
        let week = [];
        let beWeek = [];
        for (let w = 0; w <= 6; w++) {
            if (w === 0) {
                setFirstWeekDay(
                    moment(nextWeek).weekday(0).format("DD-MM-YYYY")
                );
            }

            week.push(moment(nextWeek).weekday(w).format("dddd - Do"));
            beWeek.push(moment(nextWeek).weekday(w).format("YYYY-MM-DD"));
        }
        axios
            .post(
                `${hrUrl}/rota/employees/week`,
                { siteId: siteId, start: beWeek[0], end: beWeek[6] },
                config
            )
            .then((res) => {
                //To find out if this is a week in the past
                moment().diff(
                    moment(res.data.rota.startDate).format(),
                    "days"
                ) > 6
                    ? setPreviousWeek(true)
                    : setPreviousWeek(false);

                //To find out if the week is the current week
                for (let i = 0; i < beWeek.length; i++) {
                    let findDate = beWeek.some(
                        (day) => day === moment().format("YYYY-MM-DD")
                    );

                    findDate ? setIsCurrentWeek(true) : setIsCurrentWeek(false);
                }

                setPublished(res.data.rota.published);
                setRotaId(res.data.rota.rotaId);
                setRotaNotes(res.data.rota.notes);
                setConfirmedDates(res.data.rota.confirmedDates);
                const decryptEmployees = res.data.staff.map((employee) => ({
                    ...employee,
                    firstName: decryptData(
                        employee.firstName,
                        process.env.REACT_APP_EMP_KEY
                    ),
                    lastName: decryptData(
                        employee.lastName,
                        process.env.REACT_APP_EMP_KEY
                    ),
                    position: employee.position,
                    worked: employee.worked,
                }));

                let sorted1 = decryptEmployees.sort(
                    (a, b) =>
                        a.priority - b.priority ||
                        a.roleId - b.roleId ||
                        a.firstName.localeCompare(b.firstName)
                );
                setStaffList(sorted1);
                setTempStaffList(sorted1);

                let brandsArr = [];
                let rolesArr = [];
                for (let i = 0; i < res.data.staff.length; i++) {
                    if (!brandsArr.includes(res.data.staff[i].name)) {
                        brandsArr.push(res.data.staff[i].name);
                    }
                    if (!rolesArr.includes(res.data.staff[i].position)) {
                        rolesArr.push(res.data.staff[i].position);
                    }
                }
                setBrands(brandsArr);
                setRoles(rolesArr);
            })
            .catch((err) => {
                console.log(err);
            });

        setShortDates(week);
        setFullDates(beWeek);
    };

    const sortName = async (field) => {
        if (field == "") {
            setTempStaffList([]);
            setTimeout(() => {
                reloadData();
            }, 500);
        } else {
            var sorted = tempStaffList;
            let val;
            let type;
            let split = field.split(" ");
            type = split[1];
            if (field.includes("Name")) {
                val = "firstName";
            } else {
                val = "position";
            }

            if (type == "ASC") {
                sorted = tempStaffList.sort((a, b) =>
                    a[val].localeCompare(b[val])
                );
            } else {
                sorted = tempStaffList.sort((a, b) =>
                    b[val].localeCompare(a[val])
                );
            }
            setTempStaffList([]);
            setTimeout(() => {
                setTempStaffList(sorted);
            }, 500);
        }
    };

    const filterStaff = (type, val) => {
        if (val == "") {
            setTempStaffList([]);
            setTimeout(() => {
                reloadData();
            }, 500);
        } else {
            var filtered = staffList.filter((i) => i[type] == val);
            setTempStaffList([]);
            setTimeout(() => {
                setTempStaffList(filtered);
            }, 500);
        }
    };

    return (
        <section>
            <h1 className="heading-master">Rotas</h1>
            <section
                className="staff__management rota_mobile_toggle"
                ref={componentRef}
            >
                {!loader ? (
                    <>
                        <div className="top_bar">
                            <div className="top_bar_left">
                                {siteId ? (
                                    <Button
                                        onClick={handlePrevWeek}
                                        variant="contained"
                                        sx={{
                                            borderRadius: "6px",
                                            marginRight: "10px",
                                            width: "64px",
                                            minWidth: "none",
                                            height: "50px",
                                        }}
                                    >
                                        <KeyboardArrowLeftIcon fontSize="small" />
                                    </Button>
                                ) : null}

                                <select
                                    onChange={(e) =>
                                        handleSiteSelection(e.target.value)
                                    }
                                >
                                    <option>--Select Site--</option>
                                    {managedSites &&
                                        managedSites.map(function (
                                            site,
                                            index
                                        ) {
                                            return (
                                                <option
                                                    key={index}
                                                    value={site.eposId}
                                                >
                                                    {site.siteName}
                                                </option>
                                            );
                                        })}
                                    <option value={24753}>Hillington</option>
                                    <option value={1}>RTMS</option>
                                    <option value={0}>Speed Queen</option>
                                </select>

                                {siteId ? (
                                    <h2>
                                        <span>{siteName}</span> Rota | W/C:{" "}
                                        <span>{firstWeekDay}</span>
                                    </h2>
                                ) : null}
                            </div>
                            {siteId ? (
                                <>
                                    <div className="filter_section">
                                        <select
                                            onChange={(e) =>
                                                sortName(e.target.value)
                                            }
                                        >
                                            <option value="">SORT BY:</option>
                                            <option>Name ASC</option>
                                            <option>Name DESC</option>
                                            <option>Role ASC</option>
                                            <option>Role DESC</option>
                                        </select>
                                        <select
                                            onChange={(e) =>
                                                filterStaff(
                                                    "name",
                                                    e.target.value
                                                )
                                            }
                                        >
                                            <option value="">
                                                FILTER BRAND:
                                            </option>
                                            {brands.map(function (d, i) {
                                                return <option>{d}</option>;
                                            })}
                                        </select>
                                        <select
                                            onChange={(e) =>
                                                filterStaff(
                                                    "position",
                                                    e.target.value
                                                )
                                            }
                                        >
                                            <option value="">
                                                FILTER ROLE:
                                            </option>
                                            {roles.map(function (d, i) {
                                                return <option>{d}</option>;
                                            })}
                                        </select>
                                    </div>
                                    <div className="top_bar_right">
                                        {!previousWeek && !isCurrentWeek ? (
                                            !published ? (
                                                <Button
                                                    variant="contained"
                                                    color="success"
                                                    onClick={() =>
                                                        setPublishModal(true)
                                                    }
                                                    endIcon={<PublishIcon />}
                                                    className="pulse-button"
                                                >
                                                    Publish
                                                </Button>
                                            ) : (
                                                <Button
                                                    disable
                                                    variant="contained"
                                                    color="error"
                                                    endIcon={<PublishIcon />}
                                                    onClick={() =>
                                                        setPublishModal(true)
                                                    }
                                                    className="republish_btn"
                                                >
                                                    Republish
                                                </Button>
                                            )
                                        ) : null}
                                        <Box
                                            sx={{
                                                height: "50px",

                                                transform: "translateZ(0px)",
                                                flexGrow: 1,
                                            }}
                                        >
                                            <SpeedDial
                                                ariaLabel="SpeedDial basic example"
                                                sx={{
                                                    position: "absolute",
                                                    bottom: 0,
                                                    right: 0,
                                                }}
                                                icon={<HelpOutlineIcon />}
                                                direction={"left"}
                                            >
                                                <SpeedDialAction
                                                    sx={{
                                                        marginTop: "30px",
                                                    }}
                                                    key={"Holiday paid"}
                                                    icon={
                                                        <BeachAccessIcon
                                                            color="success"
                                                            fontSize="small"
                                                        />
                                                    }
                                                    tooltipTitle={
                                                        "Holiday paid"
                                                    }
                                                />
                                                <SpeedDialAction
                                                    sx={{
                                                        marginTop: "30px",
                                                    }}
                                                    key={"Holiday Unpaid"}
                                                    icon={
                                                        <BeachAccessIcon
                                                            color="secondary"
                                                            fontSize="small"
                                                        />
                                                    }
                                                    tooltipTitle={
                                                        "Holiday Unpaid"
                                                    }
                                                />
                                                <SpeedDialAction
                                                    sx={{
                                                        marginTop: "30px",
                                                    }}
                                                    key={
                                                        "Holiday Pending Approval"
                                                    }
                                                    icon={
                                                        <PauseCircleFilledIcon
                                                            color="warning"
                                                            fontSize="small"
                                                        />
                                                    }
                                                    tooltipTitle={
                                                        "Holiday Pending Approval"
                                                    }
                                                />
                                                <SpeedDialAction
                                                    sx={{
                                                        marginTop: "30px",
                                                    }}
                                                    key={"NightShift"}
                                                    icon={
                                                        <ModeNightIcon
                                                            color="warning"
                                                            fontSize="small"
                                                        />
                                                    }
                                                    tooltipTitle={"NightShift"}
                                                />
                                                <SpeedDialAction
                                                    sx={{
                                                        marginTop: "30px",
                                                    }}
                                                    key={"Sick"}
                                                    icon={
                                                        <VaccinesIcon
                                                            color="error"
                                                            fontSize="small"
                                                        />
                                                    }
                                                    tooltipTitle={"Sick"}
                                                />
                                                <SpeedDialAction
                                                    sx={{
                                                        marginTop: "30px",
                                                    }}
                                                    key={"Mat/Pat"}
                                                    icon={
                                                        <StrollerIcon
                                                            color="primary"
                                                            fontSize="small"
                                                        />
                                                    }
                                                    tooltipTitle={"Mat/Pat"}
                                                />
                                                <SpeedDialAction
                                                    sx={{
                                                        marginTop: "30px",
                                                    }}
                                                    key={"AWOL"}
                                                    icon={
                                                        <PsychologyAltIcon
                                                            color="warning"
                                                            fontSize="small"
                                                        />
                                                    }
                                                    tooltipTitle={"AWOL"}
                                                />
                                                <SpeedDialAction
                                                    sx={{
                                                        marginTop: "30px",
                                                    }}
                                                    key={"other"}
                                                    icon={
                                                        <NewReleasesIcon
                                                            color="primary"
                                                            fontSize="small"
                                                        />
                                                    }
                                                    tooltipTitle={"other"}
                                                />
                                                <SpeedDialAction
                                                    sx={{
                                                        marginTop: "30px",
                                                    }}
                                                    key={"Unpublished"}
                                                    icon={
                                                        <SquareIcon
                                                            sx={{
                                                                color: "#dfe6f0",
                                                                borderRadius:
                                                                    "50px",
                                                            }}
                                                        />
                                                    }
                                                    tooltipTitle={"Unpublished"}
                                                />
                                                <SpeedDialAction
                                                    sx={{
                                                        marginTop: "30px",
                                                    }}
                                                    key={"Published"}
                                                    icon={
                                                        <SquareIcon
                                                            sx={{
                                                                borderRadius:
                                                                    "50px",
                                                                color: "#64c4e0",
                                                            }}
                                                        />
                                                    }
                                                    tooltipTitle={"Published"}
                                                />
                                                <SpeedDialAction
                                                    sx={{
                                                        marginTop: "30px",
                                                    }}
                                                    key={"Confirmed"}
                                                    icon={
                                                        <SquareIcon
                                                            sx={{
                                                                borderRadius:
                                                                    "50px",
                                                                color: "#41f2b1",
                                                            }}
                                                        />
                                                    }
                                                    tooltipTitle={"Confirmed"}
                                                />

                                                <SpeedDialAction
                                                    sx={{
                                                        marginTop: "30px",
                                                    }}
                                                    key={"Other Site"}
                                                    icon={
                                                        <SquareIcon
                                                            sx={{
                                                                borderRadius:
                                                                    "50px",
                                                                color: "#f2f277",
                                                            }}
                                                        />
                                                    }
                                                    tooltipTitle={"Other Site"}
                                                />
                                                <SpeedDialAction
                                                    sx={{
                                                        marginTop: "30px",
                                                    }}
                                                    key={"Other Site"}
                                                    icon={
                                                        <SquareIcon
                                                            sx={{
                                                                borderRadius:
                                                                    "50px",
                                                                color: "#f44b48",
                                                            }}
                                                        />
                                                    }
                                                    tooltipTitle={"No Show"}
                                                />
                                            </SpeedDial>
                                        </Box>
                                        {!previousWeek && !isCurrentWeek ? (
                                            <Button
                                                variant="contained"
                                                color="error"
                                                title="Reset Current Rota"
                                                onClick={() =>
                                                    setResetRota(true)
                                                }
                                            >
                                                <RestartAltIcon />
                                            </Button>
                                        ) : null}
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => setNoteModal(true)}
                                        >
                                            <SpeakerNotesIcon
                                                className={
                                                    rotaNotes &&
                                                    rotaNotes.length > 0
                                                        ? "rota_note_icon"
                                                        : ""
                                                }
                                            ></SpeakerNotesIcon>
                                        </Button>
                                        <Link
                                            className="print_btn"
                                            to={`print-rota/${rotaId}/${siteId}/${currentDate}`}
                                            target="_blank"
                                        >
                                            <Button
                                                variant="contained"
                                                color="warning"
                                            >
                                                <PrintIcon />
                                            </Button>
                                        </Link>
                                        <Button
                                            onClick={handleNextWeek}
                                            variant="contained"
                                        >
                                            <KeyboardArrowRightIcon />
                                        </Button>
                                    </div>
                                </>
                            ) : null}
                        </div>

                        {siteId ? (
                            <>
                                <div className="arrow_key_bar"></div>
                                <div ref={componentRef}>
                                    <Rota
                                        user={user}
                                        site={siteId}
                                        siteName={siteName}
                                        rotaId={rotaId}
                                        currentDate={currentDate}
                                        fullDates={fullDates}
                                        staffList={tempStaffList}
                                        published={published}
                                        shortDates={shortDates}
                                        reloadData={reloadData}
                                        confirmedDates={confirmedDates}
                                        lastEmployeeId={lastEmployeeId}
                                    />
                                </div>
                            </>
                        ) : null}
                        {siteId ? (
                            <div className="bottom_bar">
                                {/* Removed the check for !previousWeek */}
                                <div className="mid_bar_left">
                                    {!employeeAlert ? (
                                        <section className="add_employee">
                                            <select
                                                onChange={(e) => {
                                                    handleSelectSite(
                                                        e.target.value
                                                    );
                                                }}
                                                id="site_select"
                                            >
                                                <option value="">
                                                    Add Employee from other site
                                                </option>
                                                {addEmployeeSites &&
                                                    addEmployeeSites.map(
                                                        (site, i) => {
                                                            //Removes current uneccessary site - amend accordingly
                                                            if (
                                                                site.eposId ===
                                                                    siteId ||
                                                                site.siteName ===
                                                                    "Maintenance"
                                                            ) {
                                                                return;
                                                            }
                                                            return (
                                                                <option
                                                                    key={i}
                                                                    value={
                                                                        site.companyId
                                                                    }
                                                                >
                                                                    {
                                                                        site.siteName
                                                                    }
                                                                </option>
                                                            );
                                                        }
                                                    )}
                                            </select>

                                            {employeeList.length > 0 ? (
                                                <>
                                                    <select
                                                        onChange={(e) =>
                                                            setAddEmployeeId(
                                                                e.target.value
                                                            )
                                                        }
                                                    >
                                                        <option value="">
                                                            Add Employee
                                                        </option>
                                                        {addEmployeeSites &&
                                                            employeeList.map(
                                                                (
                                                                    employee,
                                                                    i
                                                                ) => {
                                                                    let firstName =
                                                                        decryptData(
                                                                            employee.firstName,
                                                                            process
                                                                                .env
                                                                                .REACT_APP_EMP_KEY
                                                                        );
                                                                    let lastName =
                                                                        decryptData(
                                                                            employee.lastName,
                                                                            process
                                                                                .env
                                                                                .REACT_APP_EMP_KEY
                                                                        );
                                                                    return (
                                                                        <option
                                                                            key={
                                                                                i
                                                                            }
                                                                            value={
                                                                                employee.employeeDetailsId
                                                                            }
                                                                        >
                                                                            {
                                                                                firstName
                                                                            }{" "}
                                                                            {
                                                                                lastName
                                                                            }
                                                                        </option>
                                                                    );
                                                                }
                                                            )}
                                                        {}
                                                    </select>

                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                    >
                                                        <AddIcon
                                                            onClick={
                                                                handleAddEmployee
                                                            }
                                                        />
                                                    </Button>
                                                </>
                                            ) : null}
                                        </section>
                                    ) : (
                                        <Alert
                                            id="employeeId_alert"
                                            severity={employeeSeverity}
                                        >
                                            {employeeMessage}
                                        </Alert>
                                    )}
                                </div>
                                <div className="mid_bar_right"> </div>
                            </div>
                        ) : null}

                        {/* MODALS */}
                        <Modal
                            open={noteModal}
                            aria-labelledby="Note Modal"
                            aria-describedby="Note Modal"
                        >
                            <NotesModal
                                rotaId={rotaId}
                                rotaNotes={rotaNotes}
                                setNoteModal={setNoteModal}
                                reloadData={reloadData}
                                user={user}
                            />
                        </Modal>
                        <Modal
                            open={publishModal}
                            aria-labelledby="Publish Modal"
                            aria-describedby="Publish Modal"
                        >
                            <Publish
                                setPublishModal={setPublishModal}
                                user={user}
                                rotaId={rotaId}
                                published={published}
                                reloadData={reloadData}
                                staffList={staffList}
                                currentDate={currentDate}
                            />
                        </Modal>
                        <Modal
                            open={resetRota}
                            aria-labelledby="Reset Rota Modal"
                            aria-describedby="Reset Rota Modal"
                        >
                            <ResetRota
                                staffList={staffList}
                                handleClose={setResetRota}
                                rotaId={rotaId}
                                user={user}
                                reloadData={reloadData}
                            />
                        </Modal>
                    </>
                ) : (
                    <Loader />
                )}
            </section>
            <h1 className="mobile_message">
                Not available on smaller screens, please view on desktop.{" "}
            </h1>
        </section>
    );
}
