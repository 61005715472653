import { useState, useEffect } from "react";
import {
    getEmployeeDetailsById,
    getPositions,
    updateEmployee,
} from "../../../utils/apiUtils";
import { useAlert } from "../../context/AlertContext";
import { decryptData, encryptData } from "../../../utils/cryptoUtils";
import { Button, Stack } from "@mui/material";
import axios from "axios";
import Modal from "@mui/material/Modal";
import PromotionContract2 from "./manualContract/PromotionContract2";
import moment from "moment";
import ConfirmDelete from "./modal/ConfirmDelete";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// tabs
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import WorkIcon from "@mui/icons-material/Work";
import GavelIcon from "@mui/icons-material/Gavel";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FolderIcon from "@mui/icons-material/Folder";
import ExtensionIcon from "@mui/icons-material/Extension";
import Details from "./employeeTabs/Details";
import RightWork from "./employeeTabs/RightWork";
import Nok from "./employeeTabs/Nok";
import BankDetails from "./employeeTabs/BankDetails";
import Documents from "./employeeTabs/Documents";
import Alert from "@mui/material/Alert";
import UploadDocs from "./modal/UploadDocs";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import SendLetters from "./employeeTabs/SendLetters";
import BookIcon from "@mui/icons-material/Book";
import AuditLog from "./employeeTabs/AuditLog";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import Attendance from "./employeeTabs/Attendance";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

export default function EmployeeDetailsV2(props) {
    const { showAlert } = useAlert();
    const [employeeDetails, setEmployeeDetails] = useState({});
    const [roles, setRoles] = useState([]);
    const [isDirty, setIsDirty] = useState(false);
    const companyName = props.selectedCompany.companyName;
    const [roleName, setRoleName] = useState("");
    const [isLeaver, setIsLeaver] = useState(false);
    const [reload, setReload] = useState(false);
    const [token, setToken] = useState("");
    const [nspDetails, setNspDetails] = useState("");
    const [policiesDetails, setPoliciesDetails] = useState("");
    const [hrUser, setHrUser] = useState("");
    const [developer, setDeveloper] = useState(false);
    //modal
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const handleOpenConfirm = () => setOpenConfirm(true);
    const handleCloseConfirm = () => setOpenConfirm(false);
    const [openDocs, setOpenDocs] = useState(false);
    const handleOpenDocs = () => setOpenDocs(true);
    const handleCloseDocs = () => setOpenDocs(false);

    //employee visa alert
    const [visaAlert, setVisaAlert] = useState(false);
    const [severity, setSeverity] = useState("");
    const [message, setMessage] = useState("");
    const today = moment();

    // tabs
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    //Menu
    const [selectedTab, setSelectedTab] = useState("contract details");
    const [anchorEl, setAnchorEl] = useState(null);
    const open1 = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose1 = () => {
        setAnchorEl(null);
    };

    const excludeFieldsFromDecryption = [
        "employeeDetailsId",
        "title",
        "mainCompanyId",
        "roleId",
        "active",
        "applicantId",
        "contract",
        "contractedHours",
        "poaKey",
        "poiKey",
        "title",
        "signature",
        "startDate",
        "salaryHourly",
        "salaryYearly",
        "ukCitizen",
        "poa",
        "poi",
        "shareExpire",
        "references",
        "contractData",
        "settledStatus",
        "username",
    ];

    useEffect(() => {
        setValue(0);
        let user = localStorage.getItem("user");
        let parseU = JSON.parse(user);
        setToken(parseU.token);
        setHrUser(parseU.user);
        setSelectedTab("contract details")

        //If needing to fix anything comment this off
        // if (parseU.id === 3) {
        //     setDeveloper(true);
        // }

        let localConfig = {
            headers: {
                "Content-Type": undefined,
                "x-auth-token": parseU.token,
            },
        };
        setVisaAlert(false);
        setEmployeeDetails({});
        getEmployeeDetailsById(props.employee, parseU.token)
            .then((details) => {
                if (!details.active) {
                    setIsLeaver(true);
                } else {
                    setIsLeaver(false);
                }
                const decryptedDetails = getDecryptedDetails(details);
                Object.keys(details).forEach((field) => {
                    if (field == "roleId") {
                        getPositions(parseU.token)
                            .then((roles) => {
                                setRoles(roles);
                                for (let i = 0; i < roles.length; i++) {
                                    if (roles[i].roleId == details[field]) {
                                        setRoleName(roles[i].roleName);
                                    }
                                }
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    }
                });
                console.log(decryptedDetails);
                console.log(decryptedDetails.employeeDetailsId);
                setEmployeeDetails(decryptedDetails);
                let expire = moment(decryptedDetails.shareExpire);
                let diff = expire.diff(today, "months");
                if (diff <= 1 && decryptedDetails.settledStatus != true) {
                    setSeverity("error");
                    setMessage(
                        "Visa expiration alert, less than 1 month for expiration"
                    );
                    setVisaAlert(true);
                } else if (
                    diff === 2 &&
                    decryptedDetails.settledStatus != true
                ) {
                    setSeverity("warning");
                    setMessage(
                        "Less than 2 months for the expirations of the visa"
                    );
                    setVisaAlert(true);
                }
            })
            .catch((err) => {
                console.error("Employee details not found");
                showAlert("Employee details not found");
            });

        axios
            .get(
                `${process.env.REACT_APP_API_URL}/employee/documents/${props.employee}`,
                localConfig
            )
            .then((res) => {
                if (res.data.length >= 1) {
                    setPoliciesDetails(res.data[0].policiesData);
                    if (res.data[0].nspFile != null) {
                        setNspDetails(res.data[0].nspData);
                    }
                } else {
                    setNspDetails("");
                    setPoliciesDetails("");
                }
            })
            .catch((err) => {
                console.log(`Could not get docs: ${err}`);
            });
    }, [props, reload]);

    const getDecryptedDetails = (details) => {
        const decryptedDetails = {};

        if (details) {
            Object.keys(details).forEach((field) => {
                if (
                    !details[field] ||
                    excludeFieldsFromDecryption.includes(field)
                ) {
                    decryptedDetails[field] = details[field];
                } else {
                    if (typeof details[field] === "object") {
                        decryptedDetails[field] = getDecryptedDetails(
                            details[field]
                        );
                    } else {
                        decryptedDetails[field] = decryptData(
                            details[field],
                            process.env.REACT_APP_EMP_KEY
                        );
                    }
                }
            });
        }
        return decryptedDetails;
    };

    const encryptDetails = (details, key) => {
        const encryptedDetails = {};
        Object.keys(details).forEach((field) => {
            if (
                !details[field] ||
                excludeFieldsFromDecryption.includes(field)
            ) {
                encryptedDetails[field] = details[field];
            } else {
                if (typeof details[field] === "object") {
                    encryptedDetails[field] = encryptDetails(
                        details[field],
                        key
                    );
                } else {
                    encryptedDetails[field] = encryptData(details[field], key);
                }
            }
        });

        return encryptedDetails;
    };

    const handleDeleteEmployee = async (reason, date) => {
        let data = {
            reason: reason,
            token: token,
            leavingDate: date,
            user: hrUser,
        };

        if (
            reason === "Please select one" ||
            reason === "" ||
            reason === undefined
        ) {
            throw new Error("Failed to delete employee");
        } else {
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_API_URL}/employee/remove/${props.employee}`,
                    data,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "x-auth-token": token,
                        },
                    }
                );

                if (response.status === 200) {
                    handleCloseConfirm();
                    setReload(!reload);
                    props.rel();
                } else {
                    throw new Error("Failed to delete employee");
                }
            } catch (error) {
                console.error("Error deleting employee:", error);
                throw error;
            }
        }
    };

    const handleEdit = (field) => {
        if (document.getElementById(field).style.display == "flex") {
            document.getElementById(field).style.display = "none";
        } else {
            document.getElementById(field).style.display = "flex";
        }
    };

    const handleEditEmployee = (field, edit) => {
        setIsDirty(true);

        if (field === "contractedHours") {
            employeeDetails[field] = parseFloat(edit).toFixed(1);
        } else if (field === "nextOfKin.firstName") {
            employeeDetails.nextOfKin.firstName = edit;
        } else if (field === "nextOfKin.lastName") {
            employeeDetails.nextOfKin.lastName = edit;
        } else if (field === "nextOfKin.phoneNumber") {
            employeeDetails.nextOfKin.phoneNumber = edit;
        } else if (field === "bankingDetails.bankName") {
            employeeDetails.bankingDetails.bankName = edit;
        } else if (field === "bankingDetails.accountNumber") {
            employeeDetails.bankingDetails.accountNumber = edit;
        } else if (field === "bankingDetails.sortCode") {
            employeeDetails.bankingDetails.sortCode = edit;
        } else if (field === "email") {
            employeeDetails.email = edit.toLowerCase();
        } else if (field === "mainCompanyId") {
            employeeDetails[field] = parseInt(edit);
        } else {
            employeeDetails[field] = edit;
        }
    };

    const handleUpdateEmployee = async () => {
        let editFields = [
            "title",
            "firstName",
            "middleName",
            "email",
            "lastName",
            "company",
            "role",
            "contract",
            "pay_hourly",
            "pay_yearly",
            "addressLine1",
            "addressLine2",
            "city",
            "postcode",
            "country",
            "nokName",
            "nokSurname",
            "nokPhone",
            "bank_name",
            "bank_account",
            "bank_sort",
            "shareCode",
            "shareExpire",
            "settledStatus",
        ];
        try {
            const encryptedData = encryptDetails(
                employeeDetails,
                process.env.REACT_APP_EMP_KEY
            );
            console.log("--------UPDATING EMPLOYEE------------");
            await updateEmployee(
                employeeDetails.employeeDetailsId,
                encryptedData,
                token,
                hrUser
            );

            showAlert("Employee updated", "success");
            const selects = document.getElementsByTagName("select");
            const inputs = document.getElementsByTagName("input");
            for (let s = 0; s < selects.length; s++) {
                selects[s].value = "";
                if (selects[s].display == "flex") {
                    selects[s].display = "none";
                }
            }
            for (let i = 0; i < inputs.length; i++) {
                inputs[i].value = "";
                if (inputs[i].display == "flex") {
                    inputs[i].display = "none";
                }
            }
            setIsDirty(!isDirty);
            props.rel();
            setReload(!reload);
        } catch (error) {
            console.error("Error updating employee:", error);
            showAlert("Error updating employee", "error");
        }
    };

    const rel = () => {
        setReload(!reload);
    };

    return (
        <section className="employee_details">
            <div className="employee_header">
                <div>
                    <Button
                        id="basic-button"
                        aria-controls={open1 ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open1 ? "true" : undefined}
                        onClick={handleClick}
                        variant="contained"
                        endIcon={<KeyboardArrowDownIcon />}
                    >
                        Menu
                    </Button>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open1}
                        onClose={handleClose1}
                        MenuListProps={{
                            "aria-labelledby": "basic-button",
                        }}
                    >
                        <MenuItem
                            onClick={() => {
                                setSelectedTab("contract details");
                                handleClose1();
                            }}
                        >
                            <ListItemIcon>
                                <WorkIcon />
                            </ListItemIcon>
                            <ListItemText>Contract Details</ListItemText>
                        </MenuItem>
                        <Divider />
                        <MenuItem
                            onClick={() => {
                                setSelectedTab("right to work");
                                handleClose1();
                            }}
                        >
                            <ListItemIcon>
                                <GavelIcon />
                            </ListItemIcon>
                            <ListItemText>Right To Work</ListItemText>
                        </MenuItem>
                        <Divider />
                        <MenuItem
                            onClick={() => {
                                setSelectedTab("address");
                                handleClose1();
                            }}
                        >
                            <ListItemIcon>
                                <ManageAccountsIcon />
                            </ListItemIcon>
                            <ListItemText>Address & NOK details</ListItemText>
                        </MenuItem>
                        <Divider />
                        <MenuItem
                            onClick={() => {
                                setSelectedTab("bank details");
                                handleClose1();
                            }}
                        >
                            <ListItemIcon>
                                <AccountBalanceIcon />
                            </ListItemIcon>
                            <ListItemText>Bank details</ListItemText>
                        </MenuItem>
                        <Divider />
                        <MenuItem
                            onClick={() => {
                                setSelectedTab("documents");
                                handleClose1();
                            }}
                        >
                            <ListItemIcon>
                                <FolderIcon />
                            </ListItemIcon>
                            <ListItemText>Documents</ListItemText>
                        </MenuItem>
                        <Divider />
                        <MenuItem
                            onClick={() => {
                                setSelectedTab("send letter");
                                handleClose1();
                            }}
                        >
                            <ListItemIcon>
                                <DocumentScannerIcon />
                            </ListItemIcon>
                            <ListItemText>Send Letter</ListItemText>
                        </MenuItem>
                        <Divider />
                        <MenuItem
                            onClick={() => {
                                setSelectedTab("rota");
                                handleClose1();
                            }}
                        >
                            <ListItemIcon>
                                <CalendarMonthIcon />
                            </ListItemIcon>
                            <ListItemText>Availability</ListItemText>
                        </MenuItem>
                        <Divider />
                        <MenuItem
                            onClick={() => {
                                setSelectedTab("audit log");
                                handleClose1();
                            }}
                        >
                            <ListItemIcon>
                                <BookIcon />
                            </ListItemIcon>
                            <ListItemText>Audit Log</ListItemText>
                        </MenuItem>
                    </Menu>
                </div>
                <h1>
                    {employeeDetails.firstName} {employeeDetails.lastName} -
                    EMPLOYEE ID: {employeeDetails.employeeDetailsId} - USERNAME:{" "}
                    {employeeDetails.username}{" "}
                </h1>
            </div>
            {visaAlert ? (
                <Alert className="visa_alert" severity={severity}>
                    {message}
                </Alert>
            ) : null}
            <Box sx={{ width: "100%", height: "38vh" }}>
                {selectedTab === "contract details" ? (
                    <Details
                        employee={employeeDetails}
                        handleEdit={handleEdit}
                        handleEditEmployee={handleEditEmployee}
                        companyName={companyName}
                        roleName={roleName}
                        roles={roles}
                    />
                ) : selectedTab === "right to work" ? (
                    <RightWork
                        employee={employeeDetails}
                        handleEdit={handleEdit}
                        handleEditEmployee={handleEditEmployee}
                        rel={rel}
                    />
                ) : selectedTab === "address" ? (
                    <Nok
                        employee={employeeDetails}
                        handleEdit={handleEdit}
                        handleEditEmployee={handleEditEmployee}
                    />
                ) : selectedTab === "bank details" ? (
                    <BankDetails
                        employee={employeeDetails}
                        handleEdit={handleEdit}
                        handleEditEmployee={handleEditEmployee}
                    />
                ) : selectedTab === "documents" ? (
                    <Documents
                        employee={employeeDetails}
                        nspDetails={nspDetails}
                        policiesDetails={policiesDetails}
                    />
                ) : selectedTab === "send letter" ? (
                    <SendLetters employee={employeeDetails} />
                ): selectedTab === "rota" ? (
                    <Attendance employee={employeeDetails} />
                ) : selectedTab === "audit log" ? (
                    <AuditLog employee={employeeDetails} />
                ) : null}
            </Box>
            {isLeaver ? (
                <div className="leaver">
                    <h2>LEAVER</h2>
                </div>
            ) : (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        width: "100%",
                    }}
                >
                    <Button
                        variant="contained"
                        color="success"
                        className="employee_btn"
                        disabled={!isDirty}
                        onClick={handleUpdateEmployee}
                    >
                        Update
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        className="employee_btn"
                        onClick={handleOpenDocs}
                    >
                        Update Docs
                    </Button>
                    <Button
                        variant="contained"
                        color="warning"
                        className="employee_btn"
                        onClick={handleOpen}
                    >
                        Generate Contract
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        className="employee_btn"
                        onClick={handleOpenConfirm}
                    >
                        Delete
                    </Button>
                </div>
            )}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <PromotionContract2
                    closeModal={handleClose}
                    employee={props.employee}
                />
            </Modal>
            <Modal
                open={openConfirm}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <ConfirmDelete
                    close={handleCloseConfirm}
                    handleDeleteEmployee={handleDeleteEmployee}
                />
            </Modal>
            <Modal
                open={openDocs}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <UploadDocs
                    close={handleCloseDocs}
                    employee={employeeDetails.employeeDetailsId}
                    rel={rel}
                />
            </Modal>
        </section>
    );
}
