import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "../main.scss";
import Header from "../../header/Header";
import Footer from "../../footer/Footer";
import UserContext from "../../context/UserContext";
import axios from "axios";

export default function Aplicant() {
  const navigate = useNavigate();
  const [siteData, setSiteData] = useState([]);
  const [nameSearch, setNameSearch] = useState("");
  const [siteSearch, setSiteSearch] = useState("");
  const [positionSearch, setPositionSearch] = useState("");
  const [statusSearch, setStatusSearch] = useState("");
  const { userData } = useContext(UserContext);


  useEffect(() => {
    axios
      .get("https://nitrous.rt-itservices.co.uk/sites/data")
      .then((res) => {
        setSiteData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },[]);

  return (
    <>
      {/* {userData.token ? ( */}
        <section className="main">
          <Header />
          <h1>ATS</h1>

          <section className="applicant__view">
            {/* applicant filters show */}
          <aside className="applicant_stats">
              <div className="stat">
                <p>New</p>
                <p style={{ color: "#D90D1E" }}>5</p>
              </div>
              <div className="stat">
                <p>Awaiting Phone Screen</p>
                <p style={{ color: "#D90D1E" }}>2</p>
              </div>
              <div className="stat">
                <p>Phone Screened</p>
                <p style={{ color: "#D90D1E" }}>8</p>
              </div>
              <div className="stat">
                <p>Interview Scheduled</p>
                <p style={{ color: "#D90D1E" }}>3</p>
              </div>
            </aside>
            <div className="applicant">
            <h5>Filter by:</h5>
            {/* filters */}
            <div className="applicant__filters">
              <div className="filter">
                <label>Site:</label>
                <select onChange={(e) => setSiteSearch(e.target.value)}>
                  <option>--Select Site--</option>
                  {siteData.map(function (d, i) {
                    return <option>{d.name}</option>;
                  })}
                </select>
              </div>
              <div className="filter">
                <label>Name:</label>
                <input
                  type="text"
                  onChange={(e) => setNameSearch(e.target.value)}
                />
              </div>
              <div className="filter">
                <label>Position:</label>
                <select onChange={(e) => setPositionSearch(e.target.value)}>
                  <option>--Select Position--</option>
                  <option>Baking Assistant</option>
                  <option>Customer Service Assistant</option>
                  <option>Dessert Bar Assistant</option>
                  <option>Dessert Bar Supervisor</option>
                  <option>Petrol Station Assistant</option>
                  <option>Petrol Station Supervisor</option>
                  <option>Sandwich Creator</option>
                  <option>Subway Sandwich Artist</option>
                  <option>Team Leader</option>
                  <option>Subway Supervisor</option>
                  <option>Retail Assistant</option>
                  <option>Retail Supervisor</option>
                  <option>Manager</option>
                </select>
              </div>
              <div className="filter">
                <label>Status:</label>
                <select onChange={(e) => setStatusSearch(e.target.value)}>
                  <option>--Select Status--</option>
                  <option>New</option>
                  <option>Awaiting Phone Screen</option>
                  <option>Phone Screened</option>
                  <option>Interview Scheduled</option>
                </select>
              </div>
            </div>
            {/* applicant table */}
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Site</th>
                  <th>Possition</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr onClick={()=>navigate("/ats/applicant/indv?id=00001")} style={{cursor: "pointer"}}>
                  <th>00001</th>
                  <th>Jane Doe</th>
                  <th>Autoport</th>
                  <th>Baking Assistant</th>
                  <th>New</th>
                </tr>
                <tr>
                  <th>00002</th>
                  <th>John Smith</th>
                  <th>Bearsden</th>
                  <th>CSA</th>
                  <th>Phone Screened</th>
                </tr>
                <tr>
                  <th>00003</th>
                  <th>David Jones</th>
                  <th>Bearsden</th>
                  <th>Supervisor</th>
                  <th>Interview Scheduled</th>
                </tr>
              </tbody>
            </table>
            </div>
          </section>
          <Footer />
        </section>
      {/* ) : (
        <h1 style={{margin: "auto"}}>
          You need to log in, please go to <a href="/">login</a>
        </h1>
      )} */}
    </>
  );
}
