import Box from "@mui/material/Box";
import { Button, Stack, Alert, IconButton } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Typography from "@mui/material/Typography";
import logo from "../../../adminTasks/payroll/letters/modals/RT_Logo.png";
import Divider from "@mui/material/Divider";
import html2pdf from "html2pdf.js";
import moment from "moment";
import { useState, useEffect } from "react";
import { getS3Link } from "../../../../../utils/apiUtils";
import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  maxHeight: "90vh",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "1rem",
  boxShadow: 24,
  p: 4,
  overflowY: "scroll",
};

export default function ProbExtension(props) {
  const url = process.env.REACT_APP_API_URL;
  const today = moment();
  const [disabled, setDisabled] = useState(false);
  const [token, setToken] = useState("");
  const config = { headers: { "x-auth-token": token } };
  const [probLength, setProbLength] = useState("");
  const [concerns, setConcerns] = useState("");
  const [user, setUser] = useState("");
  const [reasons, setReasons] = useState([]);
  const [note, setNote] = useState("");
  const [probationDate, setProbationDate] = useState("")
  const [noDate, setNoDate] = useState(false)
  const [isPast, setIsPast] = useState(false)
  const [hasPassed, setHasPassed] = useState(false)
  //   alert
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  useEffect(() => {
    let user = localStorage.getItem("user");
    let parseU = JSON.parse(user);
    setToken(parseU.token);
    setUser(parseU.user);
    const localConfig = { headers: { "x-auth-token": parseU.token } }
    try {
      axios.get(`${url}/employee/probation-date/${props.employee.employeeDetailsId}`, localConfig)
        .then((res) => {
          if (res.data.passed == true) {
            setHasPassed(true)
          }
          var now = moment();
          if(moment(res.data.probationDate,"DD/MM/YYYY").isBefore(now)){
            setIsPast(true)
          }else{
            setIsPast(false)
          }
          setProbationDate(res.data.probationDate)
        }).catch((err) => {
          console.log(err.response.data.msg)
          setNoDate(true)
        })
    } catch (err) {
      console.log(`Could not get the probation date: ${err}`)
    }
  }, []);

  const getTargetElement = () => document.getElementById("letter");

  const handleSendLetter = async () => {
    if (probLength === "" || probLength === "--Please select one--") {
      setMessage(`Please select length of probation`);
      setSeverity("warning");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
        setDisabled(false);
      }, 3500);
    } else {
      setDisabled(true);

      const data = {
        type: 26,
        employeeDetailsId: props.employee.employeeDetailsId,
        fileName: `${props.employee.employeeDetailsId
          }-Email - Probation Extension-${moment().format(
            "YYYY-MM-DD-HH-mm"
          )}.pdf`,
        user: user
      };
      await getS3Link(data, config)
        .then((res) => {
          console.log(res);
          createPdf(res);
        })
        .catch((err) => {
          console.log(`Could not get the S3 link: ${err.response.data}`);
          setMessage(`Could not upload the document, please try again`);
          setSeverity("warning");
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
            setDisabled(false);
          }, 3500);
        });
    }
  };

  const createPdf = async (details) => {
    const element = getTargetElement();
    let opt = {
      html2canvas: { scale: 2 },
      margin: [1.4, 0.7, 1.7, 0.7], //10.5, 1, 26.5, 0 //Top, Left, Bottom, Right
      jsPDF: { unit: "cm", format: "a4", orientation: "portrait" },
    };

    const pdfNew = await html2pdf()
      .from(element)
      .set(opt)
      .output("arraybuffer");
    console.log("------SENDING LETTER------");

    // Upload to S3
    await axios
      .put(details.url, pdfNew)
      .then((res) => {
        let resend = false;
        const data = {
          documentId: details.documentId,
          user: user,
          resend: resend,
        };
        axios
          .post(`${url}/employee/emailS3Documents`, data, config)
          .then((res) => {
            setMessage(`Letter sent`);
            setSeverity("success");
            setShowAlert(true);
            setTimeout(() => {
              setShowAlert(false);
              props.close();
            }, 3500);
          })
          .catch((err) => {
            console.log(`Could not send the email: ${err}`);
            setMessage(
              `Could not send the email to the employee, please try again`
            );
            setSeverity("error");
            setShowAlert(true);
            setTimeout(() => {
              setShowAlert(false);
            }, 3500);
          });
      })
      .catch((err) => {
        setDisabled(false);
        console.log(`Could not upload to S3: ${err.response.data}`);
        setMessage(`Could not upload the document, please try again`);
        setSeverity("error");
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 3500);
      });
  };

  function handlePlus(index) {
    if (note === "") {
      alert("All values must be filled")
    } else {
      setReasons([...reasons, { note: note }]);
      setNote("");
    }
  }

  function handleMinus(i) {
    const removeReason = reasons.filter((val, index) => index !== i);
    setReasons(removeReason);
  }

  return (
    <Box sx={style}>
      <Typography
        id="modal-modal-title"
        variant="h6"
        component="h2"
        sx={{
          textAlign: "center",
          marginBottom: "20px",
        }}
      >
        Probationary period extension
      </Typography>
      {noDate ?
        <div style={{ margin: "0 auto", width: "75%" }}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ textAlign: "center" }}
          >
            Unable to find probation details for this employee. Please contact IT if this is not correct.
          </Typography>
          <Button variant="contained" color="primary"
            sx={{ textAlign: "center", width: "20%", marginLeft: "40%", marginTop: "2%" }} onClick={props.close}>
            Close
          </Button>
        </div>
        :
        hasPassed ?
          <div style={{ margin: "0 auto", width: "75%" }}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{ textAlign: "center" }}
            >
              This employee has passed their probation. Please contact IT if this is not correct.
            </Typography>
            <Button variant="contained" color="primary"
              sx={{ textAlign: "center", width: "20%", marginLeft: "40%", marginTop: "2%" }} onClick={props.close}>
              Close
            </Button>
          </div> :
          <>
          {isPast ?
            <><Alert severity="warning">Please note that the probation date for this employee is in the past - {probationDate}</Alert></>
            : null
          }
            <Typography variant="body2">Please select length probation:</Typography>
            <select onChange={(e) => setProbLength(e.target.value)}>
              <option>--Please select one--</option>
              <option>three months</option>
              <option>six months</option>
            </select>
            <Typography variant="body2">Please type concerns:</Typography>
            <textarea
              onChange={(e) => setNote(e.target.value)}
              cols={50}
              rows={5}
              spellcheck="true"
              value={note}
            />
            <IconButton>
              <CheckCircleIcon
                fontSize="large"
                sx={{ color: "#2c53a0" }}
                onClick={() => handlePlus(reasons.length)}
              />
            </IconButton>
            <Divider sx={{ margin: "1% auto" }} />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignContent: "center",
                justifyContent: "flex-start",
              }}
              id="letter"
            >
              <Box
                sx={{
                  display: "flex",
                  alignContent: "center",
                  justifyContent: "space-between",
                  margin: "2% auto",
                }}
              >
                <img src={logo} alt="RT Logo" width="20%" />
                <Typography variant="subtitle1">
                  Private &amp; Confidential
                </Typography>
              </Box>
              <Typography variant="h3" sx={{ textAlign: "center" }}>
                {props.company.companyName}
              </Typography>
              <Typography variant="h6" sx={{ textAlign: "center" }}>
                {props.company.addressLine1}, {props.company.city},{" "}
                {props.company.postcode}
              </Typography>
              <Typography>{today.format("dddd, Do MMMM YYYY")}</Typography>
              <Typography variant="body1" sx={{ margin: "2% 0" }}>
                Dear {props.employee.firstName}:
              </Typography>
              <Typography variant="body1" sx={{ margin: "1% 0" }}>
                When you commenced employment with us on{" "}
                {props.employee.startDate != null ? (
                  new Date(props.employee.startDate).toLocaleDateString('en-GB')
                ) : (
                  <>N/A</>
                )}{" "}
                in the capacity of {props.position}, you were informed that your
                employment would be subject to the satisfactory completion of a
                probationary period of{" "}
                {probLength === "" ? (
                  <strong>SELECT PROBATION LENGTH</strong>
                ) : (
                  probLength
                )}
                .
              </Typography>
              <Typography variant="body1" sx={{ margin: "1% 0" }}>
                As previously discussed with your manager, the following concerns have
                been highlighted:
              </Typography>
              {reasons.length > 0 ? (
                <>
                  {reasons.map(function (d, i) {
                    return (
                      <li style={{ marginLeft: "3%" }}>{d.note}</li>
                    );
                  })}
                </>
              ) : (
                <Typography variant="h6">
                  TYPE YOUR CONCERNS ON THE BOX ABOVE
                </Typography>
              )}
              <Typography variant="body1" sx={{ margin: "1% 0" }}>
                After careful consideration it has been decided that your probationary
                period has been extended by one month and will be further reviewed on{" "}
                {probationDate}. This extension is in place to provide you with time to adequately
                continue improving on the points previously raised.
              </Typography>
              <Typography variant="body1" sx={{ margin: "1% 0" }}>
                You may appeal against my decision and should you wish to do so, you
                should write to HR within five days giving the full reasons for your
                appeal.
              </Typography>
              <Typography variant="body1" sx={{ margin: "1% 0" }}>
                Regards,
              </Typography>
              <Typography variant="body1" sx={{ margin: "1% 0" }}>
                {props.company.siteName} Management Team.
              </Typography>
            </Box>
            {showAlert ? (
              <Alert severity={severity}>{message}</Alert>
            ) : (
              <Stack
                direction="row"
                spacing={12}
                alignItems="center"
                justifyContent="center"
              >
                <Button variant="outlined" color="error" onClick={props.close}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  onClick={handleSendLetter}
                  disabled={disabled}
                >
                  Send letter
                </Button>
              </Stack>
            )}
          </>
      }



    </Box>
  );
}
