import { useState, useEffect } from "react";
import { Box, Button } from "@mui/material";
import { decryptData } from "../../../utils/cryptoUtils";
import axios from "axios";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "1rem",
    boxShadow: 24,
    p: 4,
};

export default function NoteModal({
    notificationId,
    handleClose,
    siteId,
    start,
    end,
}) {
    const [data, setData] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        console.log(siteId);
        axios
            .get(
                `${process.env.REACT_APP_API_URL}/notifications/single/${notificationId}`
            )
            .then((res) => {
                let notificationData = res.data;
                var firstName = decryptData(
                    notificationData.firstName,
                    process.env.REACT_APP_EMP_KEY
                );
                var lastName = decryptData(
                    notificationData.lastName,
                    process.env.REACT_APP_EMP_KEY
                );
                notificationData.fullName = `${firstName} ${lastName}`;
                setData(notificationData);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [notificationId]);

    const onMarkAsRead = () => {
        axios
            .post(`${process.env.REACT_APP_API_URL}/notifications/confirmed`, {
                id: notificationId,
            })
            .then((res) => {
                if (res.data.updated) {
                    console.log("Notification marked as read");
                    handleClose();
                }
            })
            .catch((err) => {
                console.error("Error marking as read:", err);
            });
    };

    const onOpenRota = () => {
        if (data.shiftId) {
            console.log("url and state sent");
            //navigate(`/employee-management/rotas/`, { state: { rotaid: data.rotaId } });
            // navigate(`/employee-management/rotas/`, { state: { employeeId: data.employeeDetailsId, siteId: data.eposId} });
            navigate(
                `/employee-management/rotas?site=${siteId}&start=${start}&end=${end}&employee=${data.employeeDetailsId}`
            );
        }
    };

    return (
        <Box sx={style}>
            <h1 style={{ textAlign: "center" }}>
                Notification ID: {notificationId}
            </h1>
            {data.notificationId ? (
                <>
                    <p>
                        <b>Staff:</b> {data.fullName}
                    </p>
                    <p>
                        <b>Shift Id:</b> {data.shiftId}
                    </p>
                    <p>
                        <b>Shift Date:</b> {moment(data.startTime).format("DD/MM/YYYY")}
                    </p>
                    <p>
                        <b>Edit Made:</b> {data.editReason}
                    </p>
                    <p>
                        <b>Edited By:</b> {data.user}
                    </p>
                    <p>
                        <b>Edited On:</b>{" "}
                        {moment(data.timeOfEdit).format("HH:mm - DD/MM/YYYY")}
                    </p>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-around",
                        }}
                    >
                        <Button
                            variant="contained"
                            color="error"
                            onClick={handleClose}
                        >
                            Close
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={onOpenRota}
                        >
                            Open Rota
                        </Button>
                        <Button
                            variant="contained"
                            color="success"
                            onClick={onMarkAsRead}
                        >
                            Mark As Read
                        </Button>
                    </div>
                </>
            ) : null}
        </Box>
    );
}
