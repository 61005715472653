// import SaveAsIcon from '@mui/icons-material/SaveAs';
import { useState, useEffect } from "react";
import moment from "moment";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import axios from "axios";
import { IconButton } from "@mui/material";
import Modal from "@mui/material/Modal";
import ConfirmDelete from "./modal/ConfirmDelete";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

export default function IndvProbation(props) {
  const probation = props.probation;
  const url = process.env.REACT_APP_API_URL;
  const [newDate, setNewDate] = useState("");
  const [isDirty, setIsDirty] = useState(false);
  const [probationDate, setProbationDate] = useState("");
  const [token, setToken] = useState("");
  const config = {headers: {"Content-Type": "application/json", "x-auth-token": token }};
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    let token = localStorage.getItem("user");
    let getToken = JSON.parse(token);
    let final = getToken.token;
    setToken(final);
    setProbationDate(moment(
    props.probation.probationDate,
    "DD/MM/YYYY"
  ).format("YYYY-MM-DD"))
  }, [props]);

  const handleDate = (date) => {
    setNewDate(date);
    setIsDirty(true);
    setProbationDate(date)
  };

  const handleNewProbation = () => {
    let data;
    if (probation._id) {
      data = {
        id: probation._id,
        probationDate: newDate,
      };
    } else {
      data = {
        id: probation.id,
        probationDate: newDate,
      };
    }
    axios
      .post(`${url}/onboard/probationDates/update`, data, config)
      .then((res) => {
        props.rel();
        setIsDirty(false);
      })
      .catch((err) => {
        console.log(err);
        // showAlert("Something went wrong, please try again");
      });
  };

  return (
    <tr>
      <td>{`${probation.firstName} ${probation.lastName}`}</td>
      <td>{probation.site}</td>
      <td>{probation.position}</td>
      <td>{probation.startDate}</td>
      <td>
        <input
          type="date"
          value={probationDate}
          onChange={(e) => handleDate(e.target.value)}
        />
      </td>
      <td>
        <IconButton
          disabled={!isDirty}
          color="success"
          onClick={handleNewProbation}
        >
          <SaveAltIcon />
        </IconButton>
      </td>
      <td>
        <IconButton color="error" onClick={handleOpen}>
          <DeleteForeverIcon />
        </IconButton>
      </td>
      <Modal
        open={open}
        aria-labelledby="Probation Delete modal"
        aria-describedby="Probation Delete modal"
      >
        <ConfirmDelete
          close={handleClose}
          probation={probation}
          rel={props.rel}
        />
      </Modal>
    </tr>
  );
}
