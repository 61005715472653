import { useEffect, useState } from "react"
import axios from "axios"

export default function CompanyDash(props) {

    const [unpublished, setUnpublished] = useState({})
    const [unconfirmed, setUnconfirmed] = useState({})

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/dashboard/rota/shifts/${props.info.companyId}`).then((res) => {
            setUnpublished(res.data.unpublished)
            setUnconfirmed(res.data.unconfirmed)
        }).catch((err) => {
            console.log(err)
        })
    }, [props])


    return (
        <div style={{ margin: "8px", width: "16%", border: "4px solid #2c53a0", borderRadius: "15px", textAlign: "center", padding:"10px" }}>
            <div style={{ backgroundColor: "#2c53a0", borderRadius: "12px", marginTop: "-12%", marginBottom: "-10%" }}>
                <p style={{ fontWeight: "bold", fontSize: "2rem", color: "#fff", padding: "2px" }}>{props.info.siteName}</p>
            </div>
            <p><u>Shifts In Last 30 Days</u></p>
            <div style={{ display: "flex", justifyContent: "space-around", marginTop: "-15px" }}>
                <p>Unpublished: <b>{unpublished.count}</b></p>
                <p><b>|</b></p>
                <p>Unconfirmed: <b>{unconfirmed.count}</b></p>
            </div>
            <div style={{borderTop:"1px solid #2c53a0", marginBottom:"-5%"}}>
                <p>Last Confirmed Rota: <b>--/--/--</b></p>
                <p>Hours On Current Rota: <b>--.--</b></p>
            </div>
        </div>
    )
}