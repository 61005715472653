import { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { getCompanies, getPayRates } from "../../../../utils/apiUtils";
import moment from "moment";
import Alert from "@mui/material/Alert";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

export default function Details(props) {
  const employeeDetails = props.employee;
  const companyName = props.companyName;
  const roleName = props.roleName;
  const roles = props.roles;
  const [clipAlert, setClipAlert] = useState(false);
  const [payRates, setPayRates] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [developer, setDeveloper] = useState(false);

  useEffect(() => {
    let user = localStorage.getItem("user");
    let parseU = JSON.parse(user);
    // comment off to turn developer view off
    if (parseU.id === 3) {
      setDeveloper(true);
    }

    getCompanies(parseU.token)
      .then((data) => {
        setCompanies(data);
      })
      .catch((err) => {
        console.log(`Could not get companies: ${err}`);
      });

    getPayRates(parseU.token)
      .then((pay) => {
        setPayRates(pay);
      })
      .catch((err) => {
        console.log(`Could not get Pay rates: ${err}`);
      });
  }, [props.employee]);

  const copyEmail = (email) => {
    navigator.clipboard.writeText(email);
    setClipAlert(true);
    setTimeout(() => {
      setClipAlert(false);
    }, 1500);
  };

  return (
    <section className="employee_personal">
      {developer ? (
        <>
          <h4>Contract details</h4>
          {clipAlert ? (
            <Alert severity="info" sx={style}>
              Copied to clipboard
            </Alert>
          ) : null}
          <div className="personal_row">
            <div className="personal_column">
              <div className="edit_row">
                <strong>
                  Title: <span>{employeeDetails.title}</span>
                </strong>
              </div>
              <div className="edit_row">
                <strong>
                  Name: <span>{employeeDetails.firstName}</span>
                </strong>
              </div>
              {employeeDetails.middleName != "" ||
              employeeDetails.middleName != null ? (
                <div className="edit_row">
                  <strong>
                    Middlename: <span>{employeeDetails.middleName}</span>
                  </strong>
                </div>
              ) : null}
              <div className="edit_row">
                <strong>
                  Lastname: <span>{employeeDetails.lastName}</span>
                </strong>
              </div>
              <div className="edit_row">
                <strong>
                  Date of birth: <span>**********</span>
                </strong>
              </div>
              <div className="edit_row">
                <strong>
                  Mobile: <span>**********</span>
                </strong>
              </div>
              <div className="edit_row">
                <strong>
                  email: <span>**********</span>
                </strong>
              </div>
            </div>
            <div className="personal_column">
              <div className="edit_row">
                <strong>
                  Company: <span>{companyName}</span>
                </strong>
              </div>
              <div className="edit_row">
                <strong>
                  Role: <span>{roleName}</span>
                </strong>
              </div>
              <div className="edit_row">
                <strong>
                  Contracted hours: <span>**********</span>
                </strong>
              </div>
              <div className="edit_row">
                <strong>
                  NIN: <span>**********</span>
                </strong>
              </div>
              {employeeDetails.salaryHourly === null ? (
                <div className="edit_row">
                  <div className="edit_row">
                    <strong>
                      Salary: <span>**********</span>
                    </strong>
                  </div>
                </div>
              ) : (
                <div className="edit_row">
                  <div className="edit_row">
                    <strong>
                      Hourly Pay: <span>**********</span>
                    </strong>
                  </div>
                </div>
              )}
              <div className="edit_row">
                <strong>
                  Start Date: <span>**********</span>
                </strong>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <h4>Contract details</h4>
          {clipAlert ? (
            <Alert severity="info" sx={style}>
              Copied to clipboard
            </Alert>
          ) : null}
          <div className="personal_row">
            <div className="personal_column">
              <div className="edit_row">
                <strong>
                  Title: <span>{employeeDetails.title}</span>
                </strong>
                <EditIcon
                  fontSize="small"
                  className="edit_icon"
                  onClick={() => props.handleEdit("title")}
                />
                <select
                  id="title"
                  onChange={(e) =>
                    props.handleEditEmployee("title", e.target.value)
                  }
                >
                  <option>--Please select one--</option>
                  <option>Mr</option>
                  <option>Ms</option>
                  <option>Mrs</option>
                  <option>Miss</option>
                  <option>Mx</option>
                </select>
              </div>
              <div className="edit_row">
                <strong>
                  Name: <span>{employeeDetails.firstName}</span>
                </strong>
                <EditIcon
                  fontSize="small"
                  className="edit_icon"
                  onClick={() => props.handleEdit("firstName")}
                />
                <input
                  type="text"
                  maxLength={75}
                  id="firstName"
                  onChange={(e) =>
                    props.handleEditEmployee("firstName", e.target.value)
                  }
                />
              </div>
              {employeeDetails.middleName != "" ||
              employeeDetails.middleName != null ? (
                <div className="edit_row">
                  <strong>
                    Middlename: <span>{employeeDetails.middleName}</span>
                  </strong>
                  <EditIcon
                    fontSize="small"
                    className="edit_icon"
                    onClick={() => props.handleEdit("middleName")}
                  />
                  <input
                    type="text"
                    maxLength={75}
                    id="middleName"
                    onChange={(e) =>
                      props.handleEditEmployee("middleName", e.target.value)
                    }
                  />
                </div>
              ) : null}
              <div className="edit_row">
                <strong>
                  Lastname: <span>{employeeDetails.lastName}</span>
                </strong>
                <EditIcon
                  fontSize="small"
                  className="edit_icon"
                  onClick={() => props.handleEdit("lastName")}
                />
                <input
                  type="text"
                  maxLength={75}
                  id="lastName"
                  onChange={(e) =>
                    props.handleEditEmployee("lastName", e.target.value)
                  }
                />
              </div>
              <div className="edit_row">
                <strong>
                  Date of birth:{" "}
                  <span>
                    {new Date(employeeDetails.dob).toLocaleDateString("en-GB")}
                  </span>
                  <EditIcon
                    fontSize="small"
                    className="edit_icon"
                    onClick={() => props.handleEdit("dob")}
                  />
                  <input
                    type="date"
                    id="dob"
                    onChange={(e) =>
                      props.handleEditEmployee("dob", e.target.value)
                    }
                  />
                </strong>
              </div>
              <div className="edit_row">
                <strong>
                  Mobile: <span>{employeeDetails.mobilePhone}</span>
                </strong>
                <EditIcon
                  fontSize="small"
                  className="edit_icon"
                  onClick={() => props.handleEdit("mobile")}
                />
                <input
                  type="number"
                  maxLength={11}
                  id="mobile"
                  onChange={(e) =>
                    props.handleEditEmployee("mobilePhone", e.target.value)
                  }
                />
              </div>

              <div className="edit_row">
                <strong onClick={() => copyEmail(employeeDetails.email)}>
                  email: <span>{employeeDetails.email}</span>
                </strong>
                <EditIcon
                  fontSize="small"
                  className="edit_icon"
                  onClick={() => props.handleEdit("email")}
                />
                <input
                  type="email"
                  maxLength={75}
                  id="email"
                  onChange={(e) =>
                    props.handleEditEmployee("email", e.target.value)
                  }
                />
              </div>
            </div>
            <div className="personal_column">
              <div className="edit_row">
                <strong>
                  Company: <span>{companyName}</span>
                </strong>
                <EditIcon
                  fontSize="small"
                  className="edit_icon"
                  onClick={() => props.handleEdit("company")}
                />
                <select
                  id="company"
                  onChange={(e) =>
                    props.handleEditEmployee("mainCompanyId", e.target.value)
                  }
                >
                  <option>--Please select one--</option>
                  {companies.map((company) => {
                    return (
                      <option key={company.companyId} value={company.companyId}>
                        {company.companyName}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="edit_row">
                <strong>
                  Role: <span>{roleName}</span>
                </strong>
                <EditIcon
                  fontSize="small"
                  className="edit_icon"
                  onClick={() => props.handleEdit("role")}
                />
                <select
                  id="role"
                  onChange={(e) =>
                    props.handleEditEmployee("roleId", e.target.value)
                  }
                >
                  <option>--Please select one--</option>
                  {roles.map((role) => {
                    return (
                      <option key={role.roleId} value={role.roleId}>
                        {role.roleName}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="edit_row">
                <strong>
                  Contracted hours:{" "}
                  <span>{employeeDetails.contractedHours}</span>
                </strong>
                <EditIcon
                  fontSize="small"
                  className="edit_icon"
                  onClick={() => props.handleEdit("contract")}
                />
                <input
                  type="number"
                  pattern="[0-9]*"
                  maxLength={2}
                  id="contract"
                  onChange={(e) =>
                    props.handleEditEmployee("contractedHours", e.target.value)
                  }
                />
              </div>
              <div className="edit_row">
                <strong
                  onClick={() =>
                    copyEmail(employeeDetails.nationalInsuranceNumber)
                  }
                >
                  NIN: <span>{employeeDetails.nationalInsuranceNumber}</span>
                </strong>
                <EditIcon
                  fontSize="small"
                  className="edit_icon"
                  onClick={() => props.handleEdit("nin")}
                />
                <input
                  type="text"
                  id="nin"
                  onChange={(e) =>
                    props.handleEditEmployee(
                      "nationalInsuranceNumber",
                      e.target.value
                    )
                  }
                />
              </div>
              {employeeDetails.salaryHourly === null ? (
                <div className="edit_row">
                  <strong>
                    Salary: <span>{employeeDetails.salaryYearly}</span>
                  </strong>
                  <EditIcon
                    fontSize="small"
                    className="edit_icon"
                    onClick={() => props.handleEdit("pay_yearly")}
                  />
                  <input
                    type="number"
                    pattern="[0-9]*"
                    id="pay_yearly"
                    onChange={(e) =>
                      props.handleEditEmployee("salaryYearly", e.target.value)
                    }
                  />
                </div>
              ) : (
                <div className="edit_row">
                  <strong>
                    Hourly pay: <span>{employeeDetails.salaryHourly}</span>
                  </strong>
                  <EditIcon
                    fontSize="small"
                    className="edit_icon"
                    onClick={() => props.handleEdit("pay_hourly")}
                  />
                  <select
                    id="pay_hourly"
                    onChange={(e) =>
                      props.handleEditEmployee("salaryHourly", e.target.value)
                    }
                  >
                    <option>--Please select--</option>
                    {payRates.map((pay) => {
                      return (
                        <option key={pay.rateId} value={pay.rate}>
                          {pay.rate}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}
              <div className="edit_row">
                {employeeDetails.startDate == null ? (
                  <>
                    <strong>
                      Start Date: <span>N/A</span>
                    </strong>
                    <EditIcon
                      fontSize="small"
                      className="edit_icon"
                      onClick={() => props.handleEdit("start_date")}
                    />
                    <input
                      type="date"
                      id="start_date"
                      onChange={(e) =>
                        props.handleEditEmployee("startDate", e.target.value)
                      }
                    />
                  </>
                ) : (
                  <>
                    <strong>
                      Start Date:{" "}
                      <span>
                        {moment(employeeDetails.startDate).format("DD/MM/YYYY")}
                      </span>
                    </strong>
                    <EditIcon
                      fontSize="small"
                      className="edit_icon"
                      onClick={() => props.handleEdit("start_date")}
                    />
                    <input
                      type="date"
                      id="start_date"
                      onChange={(e) =>
                        props.handleEditEmployee("startDate", e.target.value)
                      }
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </section>
  );
}
