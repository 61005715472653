import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Stack, Button } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60vw",
  maxHeight: "75vh",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
  overflowY: "scroll",
};

export default function ConfirmDelete(props) {
  return (
    <Box sx={style}>
      <Typography
        id="modal-modal-title"
        variant="h6"
        component="h2"
        sx={{ textAlign: "center" }}
      >
        Please confirm you are going to delete this discrepancy
      </Typography>
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        <Stack
          direction="row"
          spacing={12}
          alignItems="center"
          justifyContent="center"
        >
          <Button variant="outlined" color="error" onClick={props.close}>
            Cancel
          </Button>
          <Button variant="contained" color="success" onClick={props.confirm}>
            Confirm
          </Button>
        </Stack>
      </Typography>
    </Box>
  );
}
