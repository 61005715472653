import React, {useState, useEffect} from "react";
import {Typography, Box, TextField, Button} from "@mui/material";
import Header from "./../../header/Header";
import Footer from "./../../footer/Footer";
import {useAlert} from "../../context/AlertContext";
import {createCompany} from "../../../utils/apiUtils";
import {useNavigate} from "react-router-dom";

export default function CreateCompany() {
  const initialCompanyState = {
    companyName: "",
    siteName: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    county: "",
    postcode: "",
    phoneNumber: "",
  };

  const [newCompany, setNewCompany] = useState(initialCompanyState);
  const {showAlert} = useAlert();
  const navigate = useNavigate();
  const [token, setToken] = useState("")

  useEffect(() => {
    let user = localStorage.getItem("user");
    let parseU = JSON.parse(user);
    let token = parseU.token;
    setToken(token);  
  },[])

  const handleSaveClick = async () => {
    try {
      if (isValidCompany(newCompany)) {
        if (isValidPhoneNumber(newCompany.phoneNumber)) {
          await createCompany(newCompany, token);
          showAlert("Company created", "success");
          setNewCompany(initialCompanyState);
          navigate("/admin/update-company");
        } else {
          showAlert("Phone number should be between 11 and 13 digits");
        }
      } else {
        console.error("Invalid company data");
        showAlert("Invalid company data");
      }
    } catch (error) {
      console.error("Error creating company:", error);
      showAlert("Error creating company");
    }
  };

  const updateField = (field, value) => {
    setNewCompany((prevCompany) => ({
      ...prevCompany,
      [field]: value,
    }));
  };

  const isValidPhoneNumber = (phoneNumber) => {
    const phoneRegex = /^\d{10,14}$/; // Matches a number between 10 and 14 digits
    return phoneRegex.test(phoneNumber);
  };

  const isValidCompany = (company) => {
    return (
      company.companyName.trim() !== "" &&
      company.siteName.trim() !== "" &&
      company.addressLine1.trim() !== "" &&
      company.city.trim() !== "" &&
      company.county.trim() !== "" &&
      company.postcode.trim() !== "" &&
      company.phoneNumber.trim() !== "" &&
      isValidPhoneNumber(company.phoneNumber)
    );
  };

  const renderField = (label, field, fieldType = "text") => {
    return (
      <Box display="flex" alignItems="baseline" gap="10px" key={field}>
        <Typography sx={{fontWeight: "bold"}}>{label}: </Typography>
        <TextField
          size="small"
          type={fieldType}
          value={newCompany[field]}
          onChange={(e) => updateField(field, e.target.value)}
          inputProps={{maxLength: 50}}
        />
      </Box>
    );
  };

  return (
    <>
      <Header />
      <Box
        sx={{
          p: 5,
          width: "60%",
          margin: "0 auto",
          borderRadius: "1rem",
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 2,
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        }}
      >
        <Typography component="h2" variant="h6" sx={{mb: 1}}>
          Create Company
        </Typography>
        {renderField("*Company Name", "companyName")}
        {renderField("*Site Name", "siteName")}
        {renderField("*Address Line 1", "addressLine1")}
        {renderField("Address Line 2", "addressLine2")}
        {renderField("*City", "city")}
        {renderField("*County", "county")}
        {renderField("*Postcode", "postcode")}
        {renderField("*Phone Number", "phoneNumber", "number")}

        <Button
          variant="contained"
          onClick={handleSaveClick}
          disabled={!isValidCompany(newCompany)}
          sx={{mt: 2}}
        >
          Create
        </Button>
      </Box>
      <Footer />
    </>
  );
}
