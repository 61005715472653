import { useEffect, useState } from 'react'
import Header from "../../../header/Header";
import { getCompanies } from '../../../../utils/apiUtils';
import CompanyDash from './CompanyDash';

export default function DashboardHome() {

    const [token, setToken] = useState("")
    const [sites, setSites] = useState([])

    useEffect(() => {
        let user = localStorage.getItem("user");
        let parseU = JSON.parse(user);
        let localToken = parseU.token;
        setToken(localToken);

        getCompanies(localToken)
            .then((sites) => {
                setSites(sites)
            })
            .catch((err) => {
                console.log(`Could not get sites: ${err}`);
            });
    }, [])

    return (
        <main>
            <Header />
            <section style={{ display: "flex", justifyContent: "space-evenly", width: "100vw", flexWrap: "wrap", marginTop:"-0.5%" }}>
                {sites.map(function (d, i) {
                    return (
                        <CompanyDash info={d} />
                    )
                })}
            </section>
        </main>
    )
}