import "./footer.scss";

export default function Footer() {
  return (
    <footer>
      <h6 className="footer__text">
        {" "}
        Developed by{" "}
        <a href="https://nitrosolutions.co.uk" target="_blank" rel="noreferrer">
          Nitro Solutions
        </a><br/>v{process.env.REACT_APP_VERSION}
      </h6>
    </footer>
  );
}
