import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Logo from "../../../logo/Logo.png";
import moment from "moment/moment";
import axios from "axios";
import "./manualContract.scss";
import { useAlert } from "../../../context/AlertContext";
import { useEffect, useState } from "react";
import {
  getEmployeeDetailsById,
  getCompanies,
  getPositions,
} from "../../../../utils/apiUtils";
import { decryptData } from "../../../../utils/cryptoUtils";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  minHeight: "50vh",
  maxHeight: "90vh",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "1rem",
  boxShadow: 24,
  p: 4,
  overFlowY: "scroll",
};

export default function PromotionContract2(props) {
  const [employee, setEmployee] = useState();
  const [companies, setCompanies] = useState([]);
  const [roles, setRoles] = useState([]);
  const [changes, setChanges] = useState([]);
  const [currentRole, setCurrentRole] = useState(1);
  const [currentSal, setCurrentSal] = useState("");
  const [currentHours, setCurrentHours] = useState("");
  const [employeeCompany, setEmployeeCompany] = useState();
  const [name, setName] = useState("");
  const [hrUser, setHrUser] = useState("")
  const [showBtn, setShowBtn] = useState(true);
  const [token, setToken] = useState("");
  const [showError, setShowError] = useState(false)
  const config = { headers: { "Content-Type": "application/json", "x-auth-token": token } };
  const { showAlert } = useAlert();

  useEffect(() => {
    let token = localStorage.getItem("user");
    let getToken = JSON.parse(token);
    let final = getToken.token;
    setToken(final);
    setHrUser(getToken.user)

    let company = 0;
    getEmployeeDetailsById(props.employee, final)
      .then((person) => {
        console.log(person);
        setEmployee(person);
        company = person.mainCompanyId;

        if (person.salaryHourly) {
          setCurrentSal(person.salaryHourly);
        } else {
          setCurrentSal(person.salaryYearly);
        }
        setCurrentHours(person.contract);
        getCompanies(final)
          .then((data) => {
            setCompanies(data);
            for (let i = 0; i < data.length; i++) {
              if (data[i].companyId == company) {
                setEmployeeCompany(data[i].companyName);
                document.getElementById("company__select").selectedIndex = i;
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
        let name = decryptData(person.firstName, process.env.REACT_APP_EMP_KEY);
        let surname = decryptData(
          person.lastName,
          process.env.REACT_APP_EMP_KEY
        );
        setName(`${name} ${surname}`);
        getPositions(final)
          .then((rolesData) => {
            setRoles(rolesData);
            var idx = rolesData.findIndex((r) => r.roleId == person.roleId);
            document.getElementById("role__select").selectedIndex = idx;
            console.log(idx);
            setCurrentRole(rolesData[idx].roleName);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const updateContract = async (key, val) => {
    if (key == "Contract" && val <= 0) {
      setShowError(true)
      setTimeout(()=>{
        setShowError(false)
      },2500)
    } else {

      var idx = changes.findIndex((a) => a.key == key);
      if (idx < 0) {
        if (key == "Start") {
          var newStart = moment(val, "DD-MM-YYYY").format("DD/MM/YYYY");
          setChanges((oldArray) => [...oldArray, { key: key, value: newStart }]);
          var probDays = moment(newStart, "DD/MM/YYYY")
            .add(6, "months")
            .format("DD/MM/YYYY");
          setChanges((oldArray) => [
            ...oldArray,
            { key: "Probation", value: probDays },
          ]);
        } else {
          setChanges((oldArray) => [...oldArray, { key: key, value: val }]);
        }
      } else {
        var newItems = changes;
        if (key == "Company" && val == employeeCompany) {
          newItems.splice(idx, 1);
        } else if (key == "Role" && val == currentRole) {
          newItems.splice(idx, 1);
        } else if (key == "Salary" && val == currentSal) {
          newItems.splice(idx, 1);
        } else if (key == "Contract" && val == currentHours) {
          newItems.splice(idx, 1);
        } else if (key == "Start") {
          var newStart = moment(val, "DD-MM-YYYY").format("DD/MM/YYYY");
          var probDays = moment(newStart, "DD/MM/YYYY")
            .add(6, "months")
            .format("DD/MM/YYYY");
          var probIdx = changes.findIndex((p) => p.key == "Probation");
          newItems[idx].value = newStart;
          newItems[probIdx].value = probDays;
        } else {
          newItems[idx].value = val;
        }
        setChanges([{ key: key, value: val }]);
        setTimeout(() => {
          setChanges(newItems);
        }, 1);
      }
    }
  };

  const finaliseContract = async (type) => {
    var data = {
      employeeDetailsId: employee.employeeDetailsId,
      changes: changes,
      type: type,
      user: hrUser
    };
    setShowBtn(false);
    await axios
      .post(`${process.env.REACT_APP_API_URL}/employee/promotion`, data, config)
      .then((res) => {
        showAlert("New Details Sent To Employee", "success", res);
        setTimeout(() => {
          setShowBtn(true);
          props.closeModal();
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
        setShowBtn(false);
        showAlert("Error Updating Employee Details!");
        setTimeout(() => {
          setShowBtn(true);
        }, 2000);
      });
  };

  return (
    <Box sx={style}>
      <Typography
        id="modal-modal-title"
        variant="h6"
        component="h2"
        sx={{
          textAlign: "center",
          textDecoration: "underline",
          marginBottom: "20px",
        }}
      >
        PROMOTION CONTRACT FOR <b>{name.toUpperCase()}</b>
      </Typography>

      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignContent: "space-between",
        }}
      >
        <div
          style={{ borderRight: "2px solid dodgerblue", paddingRight: "25%" }}
        >
          <h3>CONTRACT DETAILS</h3>
          <div style={{ marginBottom: "5%" }}>
            <span>
              <b>Company: </b>
            </span>
            <select
              id="company__select"
              onChange={(e) => updateContract("Company", e.target.value)}
            >
              {companies.map(function (company, index) {
                return (
                  <option key={index} value={company.companyName}>
                    {company.companyName}
                  </option>
                );
              })}
            </select>
          </div>
          <div style={{ marginBottom: "5%" }}>
            <span>
              <b>Role: </b>
            </span>
            <select
              id="role__select"
              onChange={(e) => updateContract("Role", e.target.value)}
            >
              {roles.map(function (d, i) {
                return (
                  <option key={i} value={d.roleName}>
                    {d.roleName}
                  </option>
                );
              })}
            </select>
          </div>
          <div style={{ marginBottom: "5%" }}>
            <span>
              <b>Salary: </b>
            </span>{" "}
            <input
              type="number"
              placeholder={currentSal}
              onChange={(e) => updateContract("Salary", `£${e.target.value}`)}
            />
          </div>
          <div style={{ marginBottom: "5%" }}>
            <span>
              <b>Start: </b>
            </span>{" "}
            <br />
            <input
              type="date"
              onChange={(e) =>
                updateContract(
                  "Start",
                  moment(e.target.value).format("DD-MM-YYYY")
                )
              }
            />
          </div>
          <div style={{ marginBottom: "5%" }}>
            <span>
              <b>Contracted Hours: </b>
            </span>{" "}
            <input
              type="number"
              min={0}
              placeholder={currentHours}
              onChange={(e) => updateContract("Contract", e.target.value)}
            />
            {showError ?
              <div style={{ backgroundColor: "rgba(255,0,0,0.5)", color: "white", marginTop: "5px", padding: "5px",textAlign:"center",borderRadius:"10px" }}>
                Hours Entered Must Be Greater Than Zero
              </div>
              : <></>}
          </div>
        </div>

        <div style={{ width: "70%" }}>
          {changes.length > 0 ? (
            <div style={{ marginLeft: "10%" }}>
              <h3>REVIEW NEW DETAILS</h3>
              {changes.map(function (d, i) {
                return (
                  <p>
                    <b>{d.key}</b>: {d.value}
                  </p>
                );
              })}
              {showBtn ?
                <>
                  <Button type="submit" variant="contained" color="primary"
                    sx={{
                      mt: 2, mb: 2, mr: 2, position: "absolute",
                      bottom: "10%", right: "30%",
                    }}
                    onClick={() => finaliseContract("promotion")}
                  // disabled={changes.length < 3}
                  >
                    Update
                  </Button>
                  {/* <Button type="submit" variant="contained" color="primary"
                    sx={{
                      mt: 2, mb: 2, mr: 2, position: "absolute",
                      bottom: "10%", right: "5%",
                    }}
                    onClick={() => finaliseContract("update")}
                  >
                    New Contract
                  </Button> */}
              </>: (
                  <></>
                )}
            </div>
          ) : (
            <img style={{ width: "100%", padding: "20%" }} src={Logo} />
          )}
        </div>
      </div>
    </Box>
  );
}
