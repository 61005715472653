import { useState, useEffect } from "react";
import axios from "axios";
import { Alert, Button } from "@mui/material";

export default function Updates(props) {
    const [newGroup, setNewGroup] = useState(0)
    const [child, setChild] = useState({})
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("");
    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
        setChild(props.child)
    }, [props])

    const changeGroup = async () => {
        let data = {
            groupId: newGroup,
            hcId: child.hcId
        }

        if (newGroup == "" || newGroup == "--- Group ---") {
            console.log("Please Select A Group!")
            setMessage("You must select a group before applying the update.")
                setSeverity("warning");
                setShowAlert(true)
                setTimeout(() => {
                    setShowAlert(false)
                    setMessage("")
                    setSeverity("")
                }, 3000)
        } else {
            await axios.post(`${process.env.REACT_APP_API_URL}/groups/update/child`, data).then((resp) => {
                setMessage("Successfully assigned new group for this role.")
                setSeverity("success");
                setShowAlert(true)
                setTimeout(() => {
                    setShowAlert(false)
                    setMessage("")
                    setSeverity("")
                    setChild({})
                    props.reset()
                }, 3000)
            }).catch((err) => {
                setMessage("Something Went Wrong. Please Try Again.")
                setSeverity("error");
                setShowAlert(true)
                setTimeout(() => {
                    setShowAlert(false)
                    setMessage("")
                    setSeverity("")
                }, 3000)
            })
        }
    }

    const deleteRole = async () => {
        await axios.post(`${process.env.REACT_APP_API_URL}/groups/delete/child`, { id: child.hcId }).then(() => {
            setMessage("Successfully removed role from this group.")
            setSeverity("success");
            setShowAlert(true)
            setTimeout(() => {
                setShowAlert(false)
                setMessage("")
                setSeverity("")
                props.reset();
            }, 3000)
        }).catch((err) => {
            setMessage("Something Went Wrong. Please Try Again.")
            setSeverity("error");
            setShowAlert(true)
            setTimeout(() => {
                setShowAlert(false)
                setMessage("")
                setSeverity("")
            }, 3000)
        })
    }

    return (
        <section>
            <div className="heading">
                <h3>Updates</h3>
            </div>
            {child.hcId ?
                <div className="childData">
                    <p><b>Role:</b> {child.roleName}</p>
                    <p><b>Site:</b> {child.siteName}</p>
                    <hr />
                    <p><b><u>Update Group</u></b></p>
                    <select onChange={(e) => setNewGroup(e.target.value)}>
                        <option>--- Group ---</option>
                        {props.groupData.map(function (d, i) {
                            return (
                                <option value={d.groupId}>{d.name}</option>
                            )
                        })}
                    </select>
                    <hr />
                    <Button className="actionBtns" variant="contained" color="success" id="upd_btn" onClick={changeGroup}>Update</Button><br />
                    <Button className="actionBtns" variant="contained" color="error" id="del_btn" onClick={deleteRole}>Delete</Button><br />
                    {showAlert ? (
                        <Alert severity={severity} >{message}</Alert>
                    ) : null}
                </div>
                :
                <>Select Role</>}
        </section>
    )
}