import Footer from "../../../footer/Footer";
import Header from "../../../header/Header";
import { useState, useEffect } from "react";
import "./status.scss";
import axios from "axios";
import { decryptData } from "../../../../utils/cryptoUtils";
import moment from "moment";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import LinkIcon from '@mui/icons-material/Link';
import { useAlert } from "../../../context/AlertContext";
import { IconButton } from "@mui/material";

export default function InterviewStatus() {
  const url = process.env.REACT_APP_API_URL;
  // const url = "http://10.0.0.34:4030";
  const [data, setData] = useState([]);
  const today = new Date();
  const [reload, setReload] = useState(false);
  const [token, setToken] = useState("");
  const [hrUser,setHrUser] = useState("")
  const { showAlert } = useAlert();
  const config = {
    headers: { "Content-Type": "application/json", "x-auth-token": token },
  };

  useEffect(() => {
    let token = localStorage.getItem("user");
    let getToken = JSON.parse(token);
    let final = getToken.token;
    setToken(final);
    setHrUser(getToken.user)

    const localConfig = {
      headers: { "Content-Type": "application/json", "x-auth-token": final },
    };

    let employeeData = [];
    axios
      .get(`${url}/onBoard/current-applicants`, localConfig)
      .then((res) => {
        for (let i = 0; i < res.data.length; i++) {
          var decKey = ``;
          if (res.data[i].promo) {
            decKey = `REACT_APP_EMP_KEY`;
          } else {
            decKey = `REACT_APP_NS_ENC`;
          }
          var fullName = ""
          if (!res.data[i].fullName || res.data[i].fullName == null) {
            var fName = decryptData(res.data[i].firstName, process.env[decKey]);
            var lName = decryptData(res.data[i].lastName, process.env[decKey]);
            fullName = `${fName} ${lName}`
          } else {
            fullName = decryptData(res.data[i].fullName, process.env[decKey])
          }

          employeeData.push({
            fullName: fullName,
            position: res.data[i].position,
            site: res.data[i].companyName,
            status: res.data[i].status,
            startDate: res.data[i].startDate,
            submissionId: res.data[i].submissionId,
            promotionId: res.data[i].promotionId,
            link: res.data[i].link
          });
        }
        setData(employeeData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [reload]);

  const handleNoStarted = async (subId, promoId) => {
    let id = 0;
    var promo=false;
    if(subId){
      id = subId;
    }else{
      id=promoId;
      promo = true;
    }

    await axios
      .post(`${url}/onBoard/no-show`, { submissionId: parseInt(id), promo:promo, user:hrUser }, config)
      .then((res) => {
        setReload(!reload);
      })
      .catch((err) => {
        console.log(`could not marked as not started: ${err}`);
      });
  };

  const copyLink = (id) => {
    navigator.clipboard.writeText(id);
    showAlert("Link copied to clipboard", "success");
  };

  return (
    <main className="interview_status">
      <Header />
      <h1>Onboarding Status</h1>
      <table>
        <thead>
          <tr>
            <td>Full Name</td>
            <td>Position</td>
            <td>Site</td>
            <td>Status</td>
            <td>Link</td>
            <td>Start Date</td>
            <td>Not started</td>
          </tr>
        </thead>
        <tbody>
          {data.map((employee, index) => {
            let color;
            if (moment(employee.startDate, "YYYY-MM-DD") < today) {
              color = "warning";
            }
            return (
              <tr key={index} className={color}>
                <td>
                  {employee.fullName}
                </td>
                <td>{employee.position}</td>
                <td>{employee.site}</td>
                <td>{employee.status}</td>
                <td>
                  <IconButton onClick={() => copyLink(employee.link)}>
                    <LinkIcon />
                  </IconButton>
                </td>
                {employee.startDate != null ? (
                  <td>
                    {moment(employee.startDate, "YYYY-MM-DD").format(
                      "DD-MM-YYYY"
                    )}
                  </td>
                ) : (
                  <td>No Start Date Yet</td>
                )}
                <td>
                  <IconButton
                    onClick={() => handleNoStarted(employee.submissionId,employee.promotionId)}
                  >
                    <DeleteForeverIcon />
                  </IconButton>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <Footer />
    </main>
  );
}
