import Header from "../../../header/Header";
import { useEffect, useState } from "react";
import axios from "axios";
import Loader from "../../../animations/Loader";
import Footer from "../../../footer/Footer";
import moment from "moment";
import { decryptData } from "../../../../utils/cryptoUtils";
import Alert from "@mui/material/Alert";

export default function Leavers() {
  const [showLoader, setShowLoader] = useState(true);
  const [leavers, setLeavers] = useState([]);
  const [clipAlert, setClipAlert] = useState(false);
  const url = process.env.REACT_APP_API_URL;
  // const url = "http://10.0.0.157:4030";

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };

  useEffect(() => {
    let token = localStorage.getItem("user");
    let getToken = JSON.parse(token);
    let final = getToken.token;

    const config = {
      headers: { "Content-Type": "application/json", "x-auth-token": final },
    };

    axios
      .get(`${url}/employee/leavers`, config)
      .then((res) => {
        if (res.data.length > 0) {
          // console.log(res.data)
          for (let i = 0; i < res.data.length; i++) {
            let fName = decryptData(
              res.data[i].firstName,
              process.env.REACT_APP_EMP_KEY
            );
            let lName = decryptData(
              res.data[i].lastName,
              process.env.REACT_APP_EMP_KEY
            );
            res.data[i].name = `${fName} ${lName}`;
            res.data[i].email = decryptData(
              res.data[i].email,
              process.env.REACT_APP_EMP_KEY
            );
          }
          setLeavers(res.data);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const copy = (email) => {
    navigator.clipboard.writeText(email);
    setClipAlert(true);
    setTimeout(() => {
      setClipAlert(false);
    }, 1500);
  };

  return (
    <main className="probations_main">
      <Header />
      {clipAlert ? (
        <Alert severity="info" sx={style}>
          Copied to clipboard
        </Alert>
      ) : null}
      <section className="probations">
        <h1>Leavers</h1>
        {!showLoader ? (
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Company</th>
                <th>Position</th>
                <th>Date Left</th>
                <th>Reason</th>
              </tr>
            </thead>
            <tbody>
              {leavers.length == 0 ? (
                <tr>
                  <td colSpan={4}>No Leavers For Next Payroll</td>
                </tr>
              ) : (
                <>
                  {leavers.map((leaver, index) => {
                    return (
                      <tr>
                        <td>{leaver.name}</td>
                        <td
                          style={{ cursor: "pointer" }}
                          onClick={() => copy(leaver.email)}
                        >
                          {leaver.email}
                        </td>
                        <td>{leaver.companyName}</td>
                        {leaver.roleName ? (
                          <td>{leaver.roleName}</td>
                        ) : (
                          <td>N/A</td>
                        )}
                        <td>
                          {leaver.leavingDate ? (
                            <>
                              {moment(leaver.leavingDate, "YYYY-MM-DD").format(
                                "DD-MM-YYYY"
                              )}
                            </>
                          ) : (
                            <>
                              {moment(leaver.dateLeft, "YYYY-MM-DD").format(
                                "DD-MM-YYYY"
                              )}
                            </>
                          )}
                        </td>
                        <td>{leaver.reason}</td>
                      </tr>
                    );
                  })}
                </>
              )}
            </tbody>
          </table>
        ) : (
          <Loader />
        )}
      </section>
      <Footer />
    </main>
  );
}
