import React, { useState, useEffect } from "react";
import ErrorBoundary from "../../../utils/ErrorBoundary";
import { useAlert } from "../../context/AlertContext";
import {
    getCompanies,
    getAllEmployeesByCompany,
} from "../../../utils/apiUtils";
import { decryptData } from "../../../utils/cryptoUtils";
import EmployeeDetailsV2 from "./EmployeeDetailsV2";

export default function EmployeeManagement() {
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [companyOptions, setCompanyOptions] = useState([]);
    const [employeeList, setEmployeeList] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const { showAlert } = useAlert();
    const [reload, setReload] = useState(false);
    const [token, setToken] = useState("");

    useEffect(() => {
        let token = localStorage.getItem("user");
        let getToken = JSON.parse(token);
        let final = getToken.token;
        setToken(final);

        getCompanies(final)
            .then((data) => {
                setCompanyOptions(data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [selectedEmployee, reload]);

    const rel = () => {
        setReload(true);
    };

    const handleCompanySelect = (id) => {
        setEmployeeList([]);
        setSelectedEmployee(null);
        const selected = companyOptions.find(
            (company) => company.companyId == id
        );
        setSelectedCompany(selected);
        getAllEmployeesByCompany(id, token)
            .then((data) => {
                data.forEach((employee) => {
                    employee.firstName = decryptData(
                        employee.firstName,
                        process.env.REACT_APP_EMP_KEY
                    );
                    employee.lastName = decryptData(
                        employee.lastName,
                        process.env.REACT_APP_EMP_KEY
                    );
                });
                data.sort((a, b) => a.firstName.localeCompare(b.firstName));
                setEmployeeList(data);
            })
            .catch((err) => {
                console.log(err);
                showAlert("Employees could not be fetched");
            });
    };

    const handleEmployeeSelect = (employee) => {
        setSelectedEmployee(employee);
    };

    return (
        <main className="employee_management_main">
            <h1 className="heading-master">Employees</h1>
            <section className="management_lists">
                <section className="company_selection">
                    <strong>Select a company:</strong>
                    <select
                        onChange={(e) => handleCompanySelect(e.target.value)}
                    >
                        <option>--Please select one--</option>
                        {companyOptions.map((company, index) => {
                            return (
                                <option key={index} value={company.companyId}>
                                    {company.companyName}
                                </option>
                            );
                        })}
                    </select>
                    {selectedCompany && employeeList ? (
                        <>
                            {employeeList.map((employee) => {
                                return (
                                    <p
                                        key={employee.employeeDetailsId}
                                        onClick={() =>
                                            handleEmployeeSelect(
                                                employee.employeeDetailsId
                                            )
                                        }
                                        style={{
                                            color: employee.hasLeaverData
                                                ? "RGBA(150, 14, 14, 1)"
                                                : "black",
                                        }}
                                    >
                                        {employee.firstName} {employee.lastName}{" "}
                                        - <small>{employee.roleName}</small>
                                    </p>
                                );
                            })}
                        </>
                    ) : null}
                </section>
                <section className="employee_selection">
                    {selectedEmployee ? (
                        <ErrorBoundary>
                            <EmployeeDetailsV2
                                employee={selectedEmployee}
                                rel={rel}
                                selectedCompany={selectedCompany}
                                handleCompanySelect={handleCompanySelect}
                                archived={false}
                            />
                        </ErrorBoundary>
                    ) : selectedCompany ? (
                        <h1>Please select employee</h1>
                    ) : (
                        <h1>Please select company</h1>
                    )}
                </section>
            </section>
        </main>
    );
}
