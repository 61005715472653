import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import axios from "axios";
import Alert from "@mui/material/Alert";
import { useState,useEffect } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  maxHeight: "90vh",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "1rem",
  boxShadow: 24,
  p: 4,
  overflowY: "scroll",
};

export default function ConfirmDelete(props) {
  const employee = props.probation;
  const url = process.env.REACT_APP_API_URL;
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [token, setToken] = useState("")
  const config = { headers: { "Content-Type": "application/json", "x-auth-token": token } };

  useEffect(()=>{
    let token = localStorage.getItem("user");
    let getToken = JSON.parse(token);
    let final = getToken.token;
    setToken(final)
  }, [props])

  const handleDelete = () => {
    document.getElementById("prob_btn").style.display = "none";
    let id;
    if (employee._id) {
      id = employee._id;
    } else {
      id = employee.id;
    }
    axios
      .post(`${url}/onBoard/probationDates/delete`, { id: id }, config)
      .then((res) => {
        setMessage("Deleted successfully");
        setSeverity("success");
        setShowAlert(true);
        setTimeout(() => {
          // setShowAlert(false),
          // setMessage("")
          // setSeverity("")
          props.close();
          props.rel();
        }, 2500);
      })
      .catch((err) => {
        setMessage("Something went wrong, please try again");
        setSeverity("error");
        setShowAlert(true);
        console.log(err);
        setTimeout(() => {
          // setShowAlert(false),
          // setMessage("")
          // setSeverity("")
          document.getElementById("prob_btn").style.display = "flex";
        }, 2500);
      });
  };

  return (
    <Box sx={style}>
      <Typography
        id="review-contracts"
        variant="h6"
        component="h2"
        sx={{ textAlign: "center" }}
      >
        Are you sure you want to delete{" "}
        <span style={{ color: "#2c53a0", fontWeight: "800" }}>
          {employee.firstName} {employee.lastName}
        </span>
        ? this cannot be undone
      </Typography>
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        <section className="delete_prob_modal" id="prob_btn">
          <Button color="success" variant="outlined" onClick={props.close}>
            Cancel
          </Button>
          <Button color="error" variant="contained" onClick={handleDelete}>
            Delete
          </Button>
        </section>
        {showAlert ? <Alert severity={severity}>{message}</Alert> : null}
      </Typography>
    </Box>
  );
}
