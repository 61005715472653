import UserContext from "../context/UserContext";
import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./main.scss";
import Header from "../header/Header";
import Footer from "../footer/Footer";

export default function ATSHome() {
  const navigate = useNavigate();
  const { userData } = useContext(UserContext);


  return (
    <>
      {/* {userData.token ? ( */}
        <section className="main">
          <Header />
          <section className="home">
            <div className="card" onClick={() => navigate("/ats/applicant")}><h1>Applicant</h1></div>
            <div className="card"><h1>Jobs</h1></div>
          </section>
          <Footer />
        </section>
      {/* ) : (
        <h1 style={{margin: "auto"}}>
          You need to log in, please go to <a href="/">login</a>
        </h1>
      )} */}
    </>
  );
}
