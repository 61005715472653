import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import {
    getCompanies,
    getPositions,
    submitInterview,
} from "../../../../utils/apiUtils";
import { encryptData, decryptData } from "../../../../utils/cryptoUtils";
import { useNavigate } from "react-router-dom";
import Loader from "../../../animations/Loader";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Tooltip } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import moment from "moment";
import axios from "axios";

export default function InterviewSchedule() {
    const navigate = useNavigate();
    const today = moment();
    const [token, setToken] = useState("");
    const [hrUser, setHrUser] = useState("")
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [fullName, setFullName] = useState("")
    const [email, setEmail] = useState("");
    const [day, setDay] = useState("");
    const [hour, setHour] = useState("");
    const [mins, setMins] = useState("");
    const [site, setSite] = useState("");
    const [position, setPosition] = useState("");
    const [cv, setCv] = useState(null);
    const [companies, setCompanies] = useState([]);
    const [positions, setPositions] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("");
    const [showLoader, setShowLoader] = useState(false);
    const [showNote, setShowNote] = useState(false);
    const [note, setNote] = useState("");
    const [showClash, setShowClash] = useState(false)
    const [clashData, setClashData] = useState([])
    const configForm = {
        headers: { "Content-Type": undefined, "x-auth-token": token },
    };

    useEffect(() => {
        let user = localStorage.getItem("user");
        let parseU = JSON.parse(user);
        setToken(parseU.token);
        setHrUser(parseU.user)

        getCompanies(parseU.token)
            .then((data) => {
                setCompanies(data);
            })
            .catch((err) => {
                console.error(err);
            });
        getPositions(parseU.token)
            .then((data) => {
                setPositions(data);
            })
            .catch((err) => {
                console.error(err);
            });
    }, []);

    const handleCV = (cv) => {
        let extension = cv.name.split(".").pop().toLowerCase();
        if (
            extension != "pdf" &&
            extension != "docx" &&
            extension != "doc" &&
            extension != "txt"
        ) {
            document.getElementById("interview_btn").style.display = "none";
            setMessage("Please upload only a pdf");
            setSeverity("warning");
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
                document.getElementById("interview_btn").style.display = "flex";
                document.getElementById("cv").value = "";
            }, 3000);
        } else {
            setCv(cv);
        }
    };

    const submitInterviewData = async (clashConfirm) => {
        document.getElementById("interview_btn").style.display = "none";
        const regex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
        let interviewDay = moment(day);

        if (!email.toLowerCase().match(regex) || email === "") {
            setMessage(
                "Email has the wrong format, please check it and try again"
            );
            setSeverity("warning");
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
                document.getElementById("interview_btn").style.display = "flex";
            }, 3000);
        } else if (
            fullName === "" ||
            day === "" ||
            hour === "HH" ||
            hour === "" ||
            mins === "" ||
            mins === "MM" ||
            site === "" ||
            site === "--Please select one--" ||
            position === "" ||
            position === "--Please select position--" ||
            cv === null
        ) {
            setMessage("Please make sure all fields are filled in");
            setSeverity("warning");
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
                document.getElementById("interview_btn").style.display = "flex";
            }, 3000);
        } else {
            const encryptedName = encryptData(
                name,
                process.env.REACT_APP_NS_ENC
            );
            const encryptedSurname = encryptData(
                surname,
                process.env.REACT_APP_NS_ENC
            );
            const encryptedFullName = encryptData(
                fullName,
                process.env.REACT_APP_NS_ENC
            );
            const encryptedMail = encryptData(
                email.toLowerCase(),
                process.env.REACT_APP_NS_ENC
            );
            let interviewTime = `${day}T${hour}:${mins}`;
            const newInterview = {
                firstName: encryptedName,
                lastName: encryptedSurname,
                fullName: encryptedFullName,
                email: encryptedMail,
                date: interviewTime,
                companyId: site,
                roleId: position,
                note: note,
                user: hrUser
            };

            var clashes = false;
            await axios.post(`${process.env.REACT_APP_API_URL}/interview/clashing`, newInterview).then((res) => {
                if (res.data.clash) {
                    clashes = true;
                    var clashArr = []
                    for (let c = 0; c < res.data.clashes.length; c++) {

                        var fullName = ""
                        if (!res.data.clashes[c].fullName || res.data.clashes[c].fullName == null) {
                            let fName = decryptData(res.data.clashes[c].firstName, process.env.REACT_APP_NS_ENC);
                            let sName = decryptData(res.data.clashes[c].lastName, process.env.REACT_APP_NS_ENC);
                            fullName = `${fName} ${sName}`
                        } else {
                            fullName = decryptData(res.data.clashes[c].lastName, process.env.REACT_APP_NS_ENC);
                        }

                        let obj = {
                            roleName: res.data.clashes[c].roleName,
                            staffName: `${fullName}`
                        }
                        clashArr.push(obj)
                    }
                    setClashData(clashArr)
                    setShowClash(true)
                }
            }).catch((err) => {
                console.log(err)
            })

            if (!clashes || clashConfirm) {
                setShowLoader(true);
                try {
                    await submitInterview(newInterview, cv, configForm);
                    setName("");
                    setSurname("");
                    setFullName("")
                    setEmail("");
                    setDay("");
                    setHour("");
                    setMins("");
                    setSite("");
                    setPosition("");
                    setNote("");
                    setHour("");
                    setMins("");
                    setClashData([])
                    setShowNote(false);
                    setShowClash(false)
                    document.getElementById("cv").value = "";
                    let selects = document.querySelectorAll("select");
                    for (let i = 0; i < selects.length; i++) {
                        selects[i].selectedIndex = 0;
                    }
                    document.getElementById("interview_day").value = "";
                    setShowLoader(false);
                    setTimeout(() => {
                        navigate("/interview-calendar/schedule", {
                            state: { reloadCalendar: true },
                        });
                        document.getElementById("interview_btn").style.display =
                            "flex";
                    }, 1000);
                } catch (error) {
                    if (
                        error.response &&
                        error.response.status === 400 &&
                        error.response.data ===
                        "Interview Submited Clashes with another Interview"
                    ) {
                        setMessage(
                            "Interview cannot be scheduled within 30 minutes of another interview"
                        );
                        setSeverity("warning");
                        setShowAlert(true);
                        setTimeout(() => {
                            setShowAlert(false);
                            setShowLoader(false);
                            document.getElementById("interview_btn").style.display =
                                "flex";
                        }, 3000);
                    } else {
                        console.error("Error submitting the interview:", error);
                        setMessage(
                            "Please make sure all fields are filled in correctly and try again"
                        );
                        setSeverity("error");
                        setShowAlert(true);
                        setTimeout(() => {
                            setShowAlert(false);
                            setShowLoader(false);
                            document.getElementById("interview_btn").style.display =
                                "flex";
                        }, 3000);
                    }
                }
            }

        }
    };

    const cancelClash = () => {
        document.getElementById("interview_btn").style.display = "flex";
        setShowClash(false)
    }

    return (
        <aside className="schedule__form">
            <h2>Schedule new interview</h2>
            {showClash ?
                <div className="clashModal">
                    <h3>Potential Clash Of Interviews</h3>
                    <p>The following interview(s) may clash with this interview - </p>
                    <table className="clashTable">
                        <thead>
                            <tr>
                                <td>Name</td>
                                <td>Position</td>
                            </tr>
                        </thead>
                        <tbody>
                            {clashData.map(function (d, idx) {
                                return (
                                    <tr>
                                        <td>{d.staffName}</td>
                                        <td>{d.roleName}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <div style={{ position: "absolute", bottom: "0.5%" }}>
                        <Button
                            variant="contained"
                            color="error"
                            id="cancel_btn"
                            onClick={() => cancelClash()}
                            sx={{ margin: "65px" }}
                        >
                            Cancel
                        </Button><Button
                            variant="contained"
                            color="success"
                            id="submit_btn"
                            onClick={() => submitInterviewData(true)}
                        >
                            Submit
                        </Button>
                    </div>

                </div>
                : null
            }
            <label>
                Full Name <span>*</span>
            </label>
            <input
                type="text"
                maxLength="50"
                onChange={(e) => setFullName(e.target.value)}
                value={fullName}
            />
            <label>
                Candidate email <span>*</span>
            </label>
            <input
                type="email"
                maxLength="50"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
            />
            <label>
                Interview Date and Time<span>*</span>
            </label>
            <div className="schedule_row">
                <input
                    type="date"
                    onChange={(e) => setDay(e.target.value)}
                    id="interview_day"
                />
                <select onChange={(e) => setHour(e.target.value)}>
                    <option>HH</option>
                    <option>08</option>
                    <option>09</option>
                    <option>10</option>
                    <option>11</option>
                    <option>12</option>
                    <option>13</option>
                    <option>14</option>
                    <option>15</option>
                    <option>16</option>
                    <option>17</option>
                    <option>18</option>
                    <option>19</option>
                    <option>20</option>
                </select>
                <select onChange={(e) => setMins(e.target.value)}>
                    <option>MM</option>
                    <option>00</option>
                    <option>15</option>
                    <option>30</option>
                    <option>45</option>
                </select>
            </div>
            <label>
                Interview Location <span>*</span>
            </label>
            <select onChange={(e) => setSite(e.target.value)} value={site}>
                <option>--Please select one--</option>
                {companies.map(function (company, idx) {
                    return (
                        <option
                            value={company.companyId}
                            key={company.companyId}
                        >
                            {company.siteName}
                        </option>
                    );
                })}
            </select>
            <label>
                Position applied for <span>*</span>
            </label>
            <select
                onChange={(e) => setPosition(e.target.value)}
                value={position}
            >
                <option>--Please select position--</option>
                {positions.map(function (position, ind) {
                    return (
                        <option value={position.roleId} key={position.roleId}>
                            {position.roleName}
                        </option>
                    );
                })}
            </select>
            <label>
                CV <span>*</span>
            </label>
            <input
                type="file"
                name="cv"
                required
                className="cv"
                accept=".pdf"
                id="cv"
                onChange={(e) => {
                    handleCV(e.target.files[0]);
                }}
            />
            {!showNote ? (
                <Tooltip title="Leave a note for the manager">
                    <AddCircleOutlineIcon
                        color="primary"
                        fontSize="large"
                        onClick={() => setShowNote(true)}
                    />
                </Tooltip>
            ) : (
                <section>
                    <label>Leave a note for the manager</label>
                    <textarea onChange={(e) => setNote(e.target.value)} />
                    <Tooltip title="Close">
                        <HighlightOffIcon
                            color="error"
                            fontSize="medium"
                            onClick={() => setShowNote(false)}
                        />
                    </Tooltip>
                </section>
            )}
            <Button
                variant="contained"
                color="success"
                id="interview_btn"
                onClick={() => submitInterviewData()}
            >
                Submit
            </Button>
            {showAlert ? (
                <Alert severity={severity} sx={{ marginTop: "1%" }}>
                    {message}
                </Alert>
            ) : showLoader ? (
                <Loader />
            ) : null}
        </aside>
    );
}
