import { useEffect, useState } from 'react'
import axios from "axios"
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import "./audit.scss"

export default function AuditLog(props) {

    const [logs, setLogs] = useState([])

    useEffect(() => {
        let user = localStorage.getItem("user");
        let parseU = JSON.parse(user);
        let config = { headers: { "Content-Type": undefined, "x-auth-token": parseU.token } };
        var empId = props.employee.employeeDetailsId;
        axios.get(`${process.env.REACT_APP_API_URL}/audit/employee/${empId}`,config).then((res) => {
            setLogs(res.data)
        }).catch((err) => {
            console.log(err)
        })
    }, [props])

    return (
        <div>
            <h4>Audit Log</h4>
            {logs.length > 0 ?
            <div style={{maxHeight:"225px", overflowY:"auto"}}>
                <table className='auditTable'>
                    <thead>
                        <th>Note</th>
                        <th>Timestamp</th>
                        <th>User</th>
                    </thead>
                    <tbody>
                        {logs.map(function (d, i) {
                            return (
                                <tr key={i}>
                                    <td>{d.note}</td>
                                    <td>{d.dateTime}</td>
                                    <td>{d.user}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                </div>
                :
                <div style={{ textAlign: "center" }}>
                    <ImportContactsIcon />
                    <h3>No Logs Found</h3>
                </div>
            }
        </div>
    )
}