import { useEffect, useState } from "react";
import moment from "moment";


export default function IdleTimeOut(props) {
  const events = ["click", "scroll", "load", "keydown"];
  //timeout modal
  let timer = 40000;
  let idleTimeout = 600000;
  // 10 minutes = 600000


  useEffect(() => {
    addEvents();

    return () => {
      removeEvents();
    };
  }, [props]);

  const eventHandler = (eventType) => {
    localStorage.setItem("lastInteractionTime", moment());
    if (timer) {
      props.onActive();
      startTimer();
    }
  };

  const startTimer = () => {
    timer = setTimeout(
      () => {
        let lastInteractionTime = localStorage.getItem("lastInteractionTime");
        const diff = moment.duration(
          moment().diff(moment(lastInteractionTime))
        );
        let timeOutInterval = props.timeOutInterval
          ? props.timeOutInterval
          : idleTimeout;

        if (diff._milliseconds < timeOutInterval) {
          startTimer();
          props.onActive();
        }
        else {
          props.onIdle();
        }
      },
      props.timeOutInterval ? props.timeOutInterval : idleTimeout
    );
  };

  const addEvents = () => {
    events.forEach((eventName) => {
      window.addEventListener(eventName, eventHandler);
    });
    startTimer();
  };
  const removeEvents = () => {
    events.forEach((eventName) => {
      window.removeEventListener(eventName, eventHandler);
    });
  };

  return (
    <></>
  );
}
