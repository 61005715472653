import { useState, useEffect } from "react";
import { Button, Typography, Box } from "@mui/material";
import { Edit } from "@mui/icons-material";
import moment from "moment";
import {
  getCompanies,
  getPayRates,
  getPositions,
} from "../../../../utils/apiUtils";
import { decryptData, encryptData } from "../../../../utils/cryptoUtils";
import axios from "axios";
import "./contract.scss";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60vw",
  maxHeight: "90vh",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "1rem",
  boxShadow: 24,
  p: 2,
  overflowY: "scroll",
};

export default function IndvContract({ modalData, openDialog, close, rel }) {
  const [data, setData] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [positions, setPositions] = useState([]);
  const [sites, setSites] = useState([]);
  const [payrates, setPayrates] = useState([]);
  const currentDate = new Date().toISOString().split("T")[0];
  const [errorStates, setErrorStates] = useState({
    firstName: false,
    middleName: false,
    lastName: false,
    startDate: false,
    contract: false,
  });
  const [token, setToken] = useState("");
  const config = {
    headers: { "Content-Type": "application/json", "x-auth-token": token },
  };

  useEffect(() => {
    let token = localStorage.getItem("user");
    let getToken = JSON.parse(token);
    let final = getToken.token;
    setToken(final);

    async function fetchSitesData(final) {
      try {
        const sitesData = await getCompanies(final);
        setSites(sitesData);
      } catch (error) {
        console.error(error);
      }
    }
    fetchSitesData(final);
    if (!modalData._id) {
      let midName, uk, convicts, convictDesc, share;
      console.log(modalData);
      if (modalData.middleName) {
        midName = decryptData(
          modalData.middleName,
          process.env.REACT_APP_EMP_KEY
        );
      } else {
        midName = "";
      }
      if (modalData.ukCitizen) {
        uk = "Yes";
        share = "";
      } else {
        uk = "No";
        share = decryptData(modalData.shareCode, process.env.REACT_APP_EMP_KEY);
      }
      if (modalData.criminalConvictions) {
        convicts = "Yes";
      } else {
        convicts = "No";
      }
      if (modalData.convictionsDescription) {
        convictDesc = decryptData(
          modalData.convictionsDescription,
          process.env.REACT_APP_EMP_KEY
        );
      } else {
        convictDesc = "";
      }
      setData({
        firstName: decryptData(
          modalData.firstName,
          process.env.REACT_APP_EMP_KEY
        ),
        lastName: decryptData(
          modalData.lastName,
          process.env.REACT_APP_EMP_KEY
        ),
        middleName: midName,
        employeeDetailsId: modalData.employeeDetailsId,
        title: modalData.title,
        startDate: modalData.startDate
          ? moment(modalData.startDate).format("DD/MM/YYYY")
          : null,
        companyName: modalData.companyName,
        mainCompanyId: modalData.mainCompanyId,
        position: modalData.roleName,
        salaryHourly: modalData.salaryHourly,
        salaryYearly: modalData.salaryYearly,
        contractedHours: modalData.contractedHours,
        probationDate: modalData.probationDate,
        noticePeriod: modalData.noticePeriod,
        statusLog: modalData.statusLog,
        applicantId: modalData.applicantId,
        employeeDetailsId: modalData.employeeDetailsId,
        roleId: modalData.roleId,
        submissionId: modalData.submissionId,
        ukCitizen: uk,
        shareCode: share,
        shareCodeExpiry: modalData.shareExpire
          ? moment(modalData.shareExpire, "YYYY-MM-DD").format("DD/MM/YYYY")
          : null,
        convictions: convicts,
        convictionDescription: convictDesc,
        hrApproved: modalData.hrApproved,
      });
    } else {
      setData(modalData);
    }
    getPositions(final)
      .then((data) => {
        setPositions(data);
      })
      .catch((err) => {
        console.error(err);
      });
    getPayRates(final)
      .then((data) => {
        setPayrates(data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const handleEdit = (input) => {
    document.getElementById(input).style.display = "flex";
  };

  //updates the data
  const updateData = (field, update) => {
    const updatedData = {
      ...data,
      [field]: update,
    };

    const updatedErrorStates = { ...errorStates };
    if (field === "firstName") {
      const trimmedUpdate = update.trim();
      if (trimmedUpdate === "") {
        setMessage("Name cannot be empty");
        setShowAlert(true);
        updatedErrorStates[field] = true;
        setErrorStates(updatedErrorStates);
        return;
      } else {
        setShowAlert(false);
        updatedErrorStates[field] = false;
        setErrorStates(updatedErrorStates);
      }
      updatedData[field] = trimmedUpdate;
    } else if (field === "middleName" || field === "lastName") {
      const trimmedUpdate = update.trim();
      if (trimmedUpdate === "") {
        setMessage(field);
        setShowAlert(true);
        updatedErrorStates[field] = true;
        setErrorStates(updatedErrorStates);
        return;
      } else {
        setShowAlert(false);
        updatedErrorStates[field] = false;
        setErrorStates(updatedErrorStates);
        updatedData[field] = trimmedUpdate;
      }
    }

    if (field === "startDate") {
      const formattedDate = moment(update).format("YYYY-MM-DD");
      const formattedProbationDate = moment(update)
        .add(3, "weeks")
        .format("YYYY-MM-DD");

      const updatedData = {
        ...data,
        startDate: formattedDate,
        probationDate: formattedProbationDate,
      };

      setData(updatedData);
    } else if (field === "contract") {
      if (parseInt(update) <= 0) {
        setMessage("Contract number must be greater than 0");
        setShowAlert(true);
        updatedErrorStates[field] = true;
        setErrorStates(updatedErrorStates);
        return;
      } else {
        setShowAlert(false);
        updatedErrorStates[field] = false;
        setErrorStates(updatedErrorStates);
      }
    } else if (field === "site") {
      sites.forEach((site) => {
        if (site.companyId == update) {
          if (updatedData._id) {
            updatedData[field] = site.siteName;
            // updatedData.companyName = site.CompanyName,
            updatedData.address = `${site.addressLine1}, ${site.city}, ${site.postcode}`;
          } else {
            updatedData.mainCompanyId = parseInt(update);
            updatedData.companyName = site.companyName;
          }
        }
        setData(updatedData);
      });
      console.log("updateData -> updatedData:", updatedData);

      setData(updatedData);
    } else if (field === "position") {
      positions.forEach((position) => {
        if (position.roleId == update) {
          if (!updatedData._id) {
            updatedData.roleId = position.roleId;
            updatedData.roleName = position.roleName;
            setData(updatedData);
          } else {
            updatedData.position = position.roleName;
            setData(updatedData);
          }
        }
      });
    } else {
      updatedData[field] = update;
      setData(updatedData);
    }
  };

  const approve = () => {
    document.getElementById("modal__buttons__row").style.display = "none";
    if (
      data.salaryHourly == null &&
      data.salaryYearly == null &&
      data.hourly == null
    ) {
      setMessage("Please make sure there is a salary for the applicant");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
        setMessage("");
        document.getElementById("modal__buttons__row").style.display = "flex";
      }, 3500);
    } else if (data.noticePeriod == null) {
      setMessage("Please select a notice period");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
        setMessage("");
        document.getElementById("modal__buttons__row").style.display = "flex";
      }, 3500);
    } else {
      const employee = data;
      if (!data._id) {
        employee.firstName = encryptData(
          data.firstName,
          process.env.REACT_APP_EMP_KEY
        );
        employee.lastName = encryptData(
          data.lastName,
          process.env.REACT_APP_EMP_KEY
        );
        if (employee.middleName != "") {
          employee.middleName = encryptData(
            data.middleName,
            process.env.REACT_APP_EMP_KEY
          );
        }
        if (employee.shareCode != "") {
          employee.shareCode = encryptData(
            data.shareCode,
            process.env.REACT_APP_EMP_KEY
          );
        }
        if (employee.convictionDescription != "") {
          employee.convictionDescription = encryptData(
            data.convictionDescription,
            process.env.REACT_APP_EMP_KEY
          );
        }
        if (employee.ukCitizen == "No") {
          employee.ukCitizen = false
        } else {
          employee.ukCitizen = true
        }
      }
      
      axios
        .post(`${process.env.REACT_APP_API_URL}/onBoard/update`, employee, config)
        .then((res) => {
          rel();
          close();
        })
        .catch((err) => {
          console.error(err);
          setMessage("Something went wrong, please try again");
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
            setMessage("");
            document.getElementById("modal__buttons__row").style.display =
              "flex";
          }, 3500);
        });
    }
  };

  const emptyField = (confirmed, field) => {
    if (!confirmed) {
      document.getElementById(field).style.display = "none";
    } else {
      const updatedData = {
        ...data,
        [field]: "",
      };
      setData(updatedData)

      const updatedErrorStates = { ...errorStates };
      setShowAlert(false);
      updatedErrorStates[field] = false;
      setErrorStates(updatedErrorStates);
    }
  }

  return (
    <Box sx={style}>
      <Typography
        id="review-contracts"
        variant="h6"
        component="h2"
        sx={{ textAlign: "center" }}
      >
        Review Contract Details
      </Typography>
      <Typography id="modal-modal-description" sx={{ mt: 2 }}></Typography>
      <section className="inside_contract_modal">
        <h2>Details for contract</h2>
        <div className="modal_row">
          <strong>Title:</strong>
          <p>{data.title}</p>
          <Edit onClick={() => handleEdit("title")} />
          <select
            id="title"
            onChange={(e) => updateData("title", e.target.value)}
          >
            <option>Miss</option>
            <option>Mrs</option>
            <option>Ms</option>
            <option>Mr</option>
            <option>Mx</option>
          </select>
        </div>

        <div className="modal_row">
          <strong>Name:</strong>
          <p>{data.firstName}</p>
          <Edit onClick={() => handleEdit("name")} />
          <input
            type="text"
            maxLength="50"
            id="name"
            placeholder="Insert a new name for change..."
            onChange={(e) => updateData("firstName", e.target.value)}
          />
        </div>
        {data.middleName ? (
          <div className="modal_row">
            <strong>Middle name:</strong>
            <p>{data.middleName}</p>
            <Edit onClick={() => handleEdit("middlename")} />
            <input
              type="text"
              maxLength="50"
              id="middlename"
              placeholder="Insert a new middle name for change..."
              onChange={(e) => updateData("middleName", e.target.value)}
            />
          </div>
        ) : (
          <></>
        )}
        <div className="modal_row">
          <strong>Surname:</strong>
          <p>{data.lastName}</p>
          <Edit onClick={() => handleEdit("surname")} />
          <input
            type="text"
            maxLength="50"
            id="surname"
            placeholder="Insert a new surname for change..."
            onChange={(e) => updateData("lastName", e.target.value)}
          />
        </div>
        <div className="modal_row">
          <strong>Site:</strong>
          {data._id ? <p>{data.site}</p> : <p>{data.companyName}</p>}
          <Edit onClick={() => handleEdit("site")} />
          <select
            id="site"
            onChange={(e) => updateData("site", e.target.value)}
          >
            <option>--Select a site--</option>
            {sites.map(function (d, i) {
              return (
                <option value={d.companyId} key={d.companyId}>
                  {d.siteName}
                </option>
              );
            })}
          </select>
        </div>
        <div className="modal_row">
          <strong>Start Date:</strong>
          <p>{data.startDate}</p>
          <Edit onClick={() => handleEdit("date")} />
          <input
            type="date"
            id="date"
            onChange={(e) => updateData("startDate", e.target.value)}
          />
        </div>
        <div className="modal_row">
          <strong>Probation:</strong>
          <p>{new Date(data.probationDate).toLocaleDateString("en-GB")}</p>
          <Edit onClick={() => handleEdit("probation")} />
          <input
            type="date"
            id="probation"
            onChange={(e) => updateData("probationDate", e.target.value)}
          />
        </div>
        <div className="modal_row">
          <strong>Pay:</strong>
          {data.hourly ? (
            <p>{data.hourly}</p>
          ) : data.salaryHourly ? (
            <p>{data.salaryHourly}</p>
          ) : (
            <p>{data.salaryYearly}</p>
          )}
          <Edit onClick={() => handleEdit("hourly")} />
          {data.position === "Retail Manager" ||
            data.position === "Area Manager" ||
            data.position === "Subway Manager" ||
            data.mainCompanyId === 11
            || data.salaryYearly ? (
            <input
              type="number"
              onChange={(e) => updateData("salaryYearly", e.target.value)}
              id="hourly"
            />
          ) : (
            <select
              id="hourly"
              onChange={(e) => updateData("salaryHourly", e.target.value)}
            >
              {payrates.map((rate) => {
                return <option key={rate.rate}>{rate.rate}</option>;
              })}
            </select>
          )}
        </div>
        <div className="modal_row">
          <strong>Contract:</strong>
          {data._id ? <p>{data.contract}</p> : <p>{data.contractedHours}</p>}

          <Edit onClick={() => handleEdit("contract")} />
          <input
            type="number"
            id="contract"
            onChange={(e) => updateData("contractedHours", e.target.value)}
            min={0}
            max={45}
          />
        </div>
        <div className="modal_row">
          <strong>Position:</strong>
          <p>{data.position}</p>

          <Edit onClick={() => handleEdit("position")} />
          <select
            id="position"
            onChange={(e) => updateData("position", e.target.value)}
          >
            {positions.map((position) => {
              return (
                <option value={position.roleId} key={position.roleId}>
                  {position.roleName}
                </option>
              );
            })}
          </select>
        </div>
        <div className="modal_row">
          <strong>Notice:</strong>
          <p>{data.noticePeriod}</p>
          <Edit onClick={() => handleEdit("notice")} />
          <select
            id="notice"
            onChange={(e) => updateData("noticePeriod", e.target.value)}
          >
            <option>--Select--</option>
            <option value="0 weeks">0 Weeks</option>
            <option value="2 weeks">2 weeks</option>
            <option value="4 weeks">4 weeks</option>
            <option value="6 weeks">6 weeks</option>
          </select>
        </div>
        <div className="modal_row">
          <strong>Convictions:</strong>
          <p>{data.convictions}</p>
        </div>
        {data.convictions === "Yes" && (
          <>
            <div className="modal_row">
              <strong>Notes:</strong>
              <p>{data.convictionDescription}</p>
            </div>
          </>
        )}

        <div className="modal_row">
          <strong>UK Citizen:</strong>
          <p>{data.ukCitizen}</p>
        </div>
        {data.ukCitizen === "No" ?
          <>
            <div className="modal_row">
              <strong>Share Code:</strong>
              <p>{data.shareCode}</p>
            </div>
            <div className="modal_row">
              <strong>Visa Expiry:</strong>
              <p>{data.shareCodeExpiry}</p>
            </div>
          </>
          : null}
        {data.statusLog && data.statusLog.length > 0
          ? data.statusLog.map(function (d, i) {
            return (
              <div key={i} className="modal_row">
                <strong>Status:</strong>
                <p>{d.status} - </p>
                <p>Date: {new Date(d.date).toLocaleDateString("en-GB")}</p>
              </div>
            );
          })
          : null}
        <div id="modal__buttons__row">
          <Button variant="outlined" onClick={close} color="error">
            Close
          </Button>
          {data.hrApproved == true ? (
            <Button
              variant="contained"
              color="success"
              onClick={() => approve()}
            >
              Update
            </Button>
          ) : (
            <Button
              variant="contained"
              color="success"
              onClick={() => approve()}
            >
              Approve
            </Button>
          )}
        </div>
        {showAlert ? (
          <div className="modal__alert">
            {message == "middleName" || message == "lastName" ?
              <p>Confirm empty field -
                <button style={{ padding: "7px", marginLeft: "5px" }} onClick={() => emptyField(true, message)}>Yes</button>
                <button style={{ padding: "7px", marginLeft: "10px" }} onClick={() => emptyField(false, message)}>No</button>
              </p>
              : <p>{message}</p>
            }
          </div>
        ) : (
          <></>
        )}
      </section>
    </Box>
  );
}
