import CheckIcon from "@mui/icons-material/Check";
import { IconButton } from "@mui/material";
import axios from "axios";
import {useEffect, useState} from 'react'
import moment from "moment";

export default function IndvAccount(props) {
  const [hrUser,setHrUser] = useState("")
  const employee = props.data;
  const token = props.token
  const config = {
    headers: { "Content-Type": "application/json", "x-auth-token": props.token },
  };

  useEffect(()=>{
    let userD = localStorage.getItem("user");
    let userData = JSON.parse(userD);
    setHrUser(userData.user)
  },[])


  const confirmAccounts = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/banking/update`,
        { id: employee.employeeDetailsId, user:hrUser },
        config
      )
      .then((res) => {
        console.log(res.data);
        props.rel();
      })
      .catch((err) => {
        console.log(`could not refresh: ${err}`);
      });
  };

  return (
    <tr key={employee.employeeDetailsId}>
      <td>
        {employee.firstName} {employee.middleName} {employee.lastName}
      </td>
      <td>{employee.companyName}</td>
      <td>{employee.bankName}</td>
      <td>{employee.sortCode}</td>
      <td>{employee.accountNumber}</td>
      <td>{moment(employee.startDate).format("DD/MM/YYYY")}</td>
      <td>
        <IconButton onClick={confirmAccounts}>
          <CheckIcon color="primary" />
        </IconButton>
      </td>
    </tr>
  );
}
