import { useState, useEffect } from "react";
import AttachEmailIcon from "@mui/icons-material/AttachEmail";
import { IconButton } from "@mui/material";
import Modal from "@mui/material/Modal";
import ProbFail from "./letterModals/ProbFail";
import { getCompanies, getPositions } from "../../../../utils/apiUtils";
import ProbPass from "./letterModals/ProbPass";
import ProbExtension from "./letterModals/ProbExtension";
import ResgAccept from "./letterModals/ResgAccept";
import NoNoticeAccept from "./letterModals/NoNoticeAccept";
import SalaryIncrease from "./letterModals/SalaryIncrease";

export default function SendLetters(props) {
  const employee = props.employee;
  const [company, setCompany] = useState({});
  const [position, setPosition] = useState("");
  //modal
  const [openProbFail, setOpenProbFail] = useState(false);
  const handleOpenProbFail = () => setOpenProbFail(true);
  const handleCloseProbFail = () => setOpenProbFail(false);
  const [openProbPass, setOpenProbPass] = useState(false);
  const handleOpenProbPass = () => setOpenProbPass(true);
  const handleCloseProbPass = () => setOpenProbPass(false);
  const [openProbExt, setOpenProbExt] = useState(false);
  const handleOpenProbExt = () => setOpenProbExt(true);
  const handleCloseProbExt = () => setOpenProbExt(false);
  const [openResg, setOpenResg] = useState(false);
  const handleOpenResg = () => setOpenResg(true);
  const handleCloseResg = () => setOpenResg(false);
  const [openNoNotice, setOpenNoNotice] = useState(false);
  const handleOpenNoNotice = () => setOpenNoNotice(true);
  const handleCloseNoNotice = () => setOpenNoNotice(false);
  const [openSalaryIncrease, setOpenSalaryIncrease] = useState(false);
  const handleOpenSalaryIncrease = () => setOpenSalaryIncrease(true);
  const handleCloseSalaryIncrease = () => setOpenSalaryIncrease(false);

  useEffect(() => {
    let token = localStorage.getItem("user");
    let getToken = JSON.parse(token);
    let finalToken = getToken.token;
    try {
      getCompanies(finalToken).then((sites) => {
        for (let s = 0; s < sites.length; s++) {
          if (sites[s].companyId == props.employee.mainCompanyId) {
            setCompany(sites[s]);
          }
        }
      });
    } catch (err) {
      console.log(`could not get the sites: ${err}`);
    }
    try {
      getPositions(finalToken).then((positions) => {
        for (let p = 0; p < positions.length; p++) {
          if (positions[p].roleId === props.employee.roleId) {
            setPosition(positions[p].roleName);
          }
        }
      });
    } catch (err) {
      console.log(`Could not get positions: ${err}`);
    }
  }, [props]);

  return (
    <section className="employee_personal">
      <h4>Send Letters to employee</h4>
      <section className="personal_row">
        <div className="personal_column_center">
          <IconButton onClick={handleOpenProbFail}>
            <AttachEmailIcon color="primary" />
          </IconButton>
          <small>Probationary fail</small>
        </div>
        <div className="personal_column_center">
          <IconButton onClick={handleOpenProbPass}>
            <AttachEmailIcon color="primary" />
          </IconButton>
          <small>Probationary passed</small>
        </div>
        <div className="personal_column_center">
          <IconButton onClick={handleOpenProbExt}>
            <AttachEmailIcon color="primary" />
          </IconButton>
          <small>Probationary extension</small>
        </div>
        <div className="personal_column_center">
          <IconButton onClick={handleOpenResg}>
            <AttachEmailIcon color="primary" />
          </IconButton>
          <small>Resignation acceptance</small>
        </div>
        <div className="personal_column_center">
          <IconButton onClick={handleOpenNoNotice}>
            <AttachEmailIcon color="primary" />
          </IconButton>
          <small>No notice resignation acceptance</small>
        </div>
        <div className="personal_column_center">
          <IconButton onClick={handleOpenSalaryIncrease}>
            <AttachEmailIcon color="primary" />
          </IconButton>
          <small>Salary Increase</small>
        </div>
      </section>
      <Modal
        open={openProbFail}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ProbFail
          employee={employee}
          close={handleCloseProbFail}
          company={company}
        />
      </Modal>
      <Modal
        open={openProbPass}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ProbPass
          employee={employee}
          close={handleCloseProbPass}
          company={company}
          position={position}
        />
      </Modal>
      <Modal
        open={openProbExt}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ProbExtension
          employee={employee}
          close={handleCloseProbExt}
          company={company}
          position={position}
        />
      </Modal>
      <Modal
        open={openResg}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ResgAccept
          employee={employee}
          close={handleCloseResg}
          company={company}
          position={position}
        />
      </Modal>
      <Modal
        open={openNoNotice}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <NoNoticeAccept
          employee={employee}
          close={handleCloseNoNotice}
          company={company}
          position={position}
        />
      </Modal>
      <Modal
        open={openSalaryIncrease}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <SalaryIncrease
          employee={employee}
          close={handleCloseSalaryIncrease}
          company={company}
          position={position}
        />
      </Modal>
    </section>
  );
}
