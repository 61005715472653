//use this route protection when general user + managers + devs will access
//check AdminProtected if you are looking for more less users

import { Navigate } from "react-router-dom";

const UserProtected =  ({children}) => {
    let checkId = localStorage.getItem("user")
    let parsed = JSON.parse(checkId)
    let id = parsed.id
    if (id != 2 && id != 1 && id != 3){
        return <Navigate to="/not-allowed" replace />
    }
    return children
}

export {UserProtected}