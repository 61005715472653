import React, {createContext, useContext, useState} from "react";
import {Alert} from "@mui/material";

const AlertContext = createContext();

export const AlertProvider = ({children}) => {
  const [alertInfo, setAlertInfo] = useState({message: "", severity: "error"});
  const [openAlert, setOpenAlert] = useState(false);

  const closeAlert = () => {
    setOpenAlert(false);
  };

  const showAlert = (message, severity = "error") => {
    setAlertInfo({message, severity});
    setOpenAlert(true);
    setTimeout(() => {
      closeAlert();
    }, 3000);
    clearTimeout();
  };

  return (
    <AlertContext.Provider value={{showAlert, closeAlert}}>
      {children}
      {openAlert && (
        <Alert
          onClose={closeAlert}
          severity={alertInfo.severity}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translateX(-50%, -50%)",
            zIndex: 100,
          }}
        >
          {alertInfo.message}
        </Alert>
      )}
    </AlertContext.Provider>
  );
};

export const useAlert = () => {
  return useContext(AlertContext);
};
