import {useEffect,useState} from 'react'

export default function IndvPayRate(props) {
  const [rate,setRate]=useState({})
  const [bgColor,setBgColour] = useState("")

  useEffect(()=>{
    setRate(props.rate)
    if(props.rate.deleted){
      setBgColour("orange")
    }else{
      setBgColour("")
    }
  },[props])

  return (
    <tr style={{backgroundColor:bgColor}}>
      <td>{rate.label}</td>
      <td>{rate.rate}</td>
      <td>
        <input
          type="number"
          pattern="[0-9]*"
          onChange={(e) => props.handleChange(e.target.value, props.index)}
        />
      </td>
      <td onClick={()=>props.deleteRate(props.index)}>❌</td>
    </tr>
  );
}
