import { useState, useEffect } from "react";
import axios from "axios";
import { decryptData } from "../../../../utils/cryptoUtils";

export default function NewManagerTable() {
  const url=process.env.REACT_APP_API_URL
  const [token, setToken] = useState("");
  const [managerList, setManagerList] = useState([]);

  useEffect(() => {

    let token = localStorage.getItem("user");
    let getToken = JSON.parse(token);
    let final = getToken.token;
    setToken(final);

    const localConfig = {
      headers: { "Content-Type": "application/json", "x-auth-token": token },
    };
  

    axios
      .get(`${url}/onBoard/pending/managers`, localConfig)
      .then((res) => {
        let list = res.data;
        for (let m = 0; m < list.length; m++) {
          list[m].firstName = decryptData(
            list[m].firstName,
            process.env.REACT_APP_EMP_KEY
          );
          list[m].lastName = decryptData(
            list[m].lastName,
            process.env.REACT_APP_EMP_KEY
          );
          list[m].email = decryptData(
            list[m].email,
            process.env.REACT_APP_EMP_KEY
          );
        }
        setManagerList(list);
      })
      .catch((err) => {
        console.log(`Could not get all pensing managers: ${err}`);
      });
  }, []);

  return (
    <section className="new_manager_table">
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Lastname</th>
            <th>Company</th>
            <th>Personal email</th>
          </tr>
        </thead>
        <tbody>
          {managerList.length < 1 ? (
            <tr>
              <td colSpan={4}>No managers awaiting create emails</td>
            </tr>
          ) : (
            managerList.map((manager) => {
              return (
                <tr key={manager.applicantId}>
                  <td>{manager.firstName}</td>
                  <td>{manager.lastName}</td>
                  <td>{manager.companyName}</td>
                  <td>{manager.email}</td>
                </tr>
              );
            })
          )}
        </tbody>
      </table>
    </section>
  );
}
