import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { useState } from "react";
import Alert from "@mui/material/Alert";
import InputLabel from "@mui/material/InputLabel";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40vw",
  maxHeight: "35vh",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "1rem",
  boxShadow: 24,
  p: 4,
  overFlowY: "scroll",
};

export default function ConfirmDelete(props) {
  const [showAlert, setShowAlert] = useState(false);
  const [reason, setReason] = useState("");
  const [message, setMessage] = useState("");
  const [date, setDate] = useState("");

  const handleConfirm = () => {
    if (reason === "Please select one" || reason === "" || reason === undefined) {
      setMessage("Please select a reason for deleting");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    } else if (date === "") {
      setMessage("Please select leaving date");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    } else {
      props.handleDeleteEmployee(reason, date);
    }
  };
  return (
    <Box sx={style}>
      <Typography
        id="modal-modal-title"
        variant="h6"
        component="h2"
        sx={{ textAlign: "center" }}
      >
        <InputLabel>PLEASE SELECT A REASON</InputLabel>
        <select onChange={(e) => setReason(e.target.value)}>
          <option>Please select one</option>
          <option>Resigned</option>
          <option>Gross misconduct</option>
          <option>Dismissal</option>
          <option>Failed Probation</option>
          <option>AWOL</option>
          <option>Did not start</option>
        </select>
      </Typography>
      <Typography
        id="modal-modal-title"
        variant="h6"
        component="h2"
        sx={{ textAlign: "center" }}
      >
        <InputLabel>PLEASE SELECT DATE FOR LEAVING</InputLabel>
        <input type="date" onChange={(e) => setDate(e.target.value)} />
      </Typography>

      {showAlert ? <Alert severity="warning">{message}</Alert> : null}
      <Stack
        direction="row"
        spacing={12}
        alignItems="center"
        justifyContent="center"
      >
        <Button onClick={props.close} variant="outlined" color="error">
          Cancel
        </Button>
        <Button onClick={handleConfirm} variant="contained" color="success">
          Delete
        </Button>
      </Stack>
    </Box>
  );
}
