// use this route for all users level of access
//This route should be use only at <Home /> or ATS module routes

import { Navigate } from "react-router-dom";

const AllUsers =  ({children}) => {
    let checkId = localStorage.getItem("user")
    let parsed = JSON.parse(checkId)
    let id = parsed.id
    if (!id){
        return <Navigate to="/not-allowed" replace />
    }
    return children
}

export {AllUsers}