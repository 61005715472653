import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ConcernPdf from "./ConcernPdf";
import { Stack, Button } from "@mui/material";
import { useState, useEffect } from "react";
import html2pdf from "html2pdf.js";
import axios from 'axios'

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  maxHeight: "75vh",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
  overflowY: "scroll",
};

export default function ConcernLetter(props) {
  const getTargetElement = () => document.getElementById("discrepancy-letter");
  const charges = props.charges;
  const [disabled, setDisabled] = useState(false);
  const url = "https://nitropos-backend.nitrosolutions.co.uk";
  const [token, setToken] = useState("");
  const sentTime = props.sentTime;
  const [user, setUser] = useState("")

  useEffect(() => {
    let user = localStorage.getItem("user");
    let parseU = JSON.parse(user);
    setToken(parseU.token);
    setUser(parseU.user)
    setDisabled(props.sentTime != "" ? true : false);
  }, []);

  const handleSave = async () => {
    setDisabled(true)
    let postUrl, key;

    const data = {
      date: props.month,
      staffId: props.epos,
      token: token,
      type: "Letter of Concern",
    };
    await axios
      .post(`${url}/discrepancies/S3UploadLink`, data)
      .then(async (res) => {
        console.log(`upload: ${res.data}`)
        postUrl = res.data.url;
        key = res.data.key;
      })
      .catch(async (err) => {
        console.log(`Could not get the url: ${err}`);
      });

    // ASSIGN KEY AS FILENAME
    const element = getTargetElement();
    let opt = {
      html2canvas: { scale: 2 },
      margin: [1.4, 0.7, 1.7, 0.7], //10.5, 1, 26.5, 0 //Top, Left, Bottom, Right
      jsPDF: { unit: "cm", format: "a4", orientation: "portrait" },
      filename: key,
    };

    const pdfNew = await html2pdf()
      .from(element)
      .set(opt)
      .output("arraybuffer");
    console.log("------SENDING LETTER------");

    // UPLOAD PDF DOC
    //const headers = {"Content-Type": "pdf" }
    console.log(`url: ${postUrl}`)
    await axios
      .put(postUrl, pdfNew)
      .then(async (res) => {
        const data = {
          key: key,
          token: token,
          user: user,
        }

        await axios
          .post(`${url}/discrepancies/sendLetter`, data)
          .then((res) => {
            console.log("sent");
          })
          .catch((err) => {
            console.log(`Could not upload the document: ${err}`);
            throw err;
          });
      })
      .catch((err) => {
        console.log("failed here")
        console.log(`Could not upload the document: ${err}`);
        setDisabled(false)
      });

    props.close()
    props.rel()
  };

  return (
    <Box sx={style}>
      <Typography
        id="modal-modal-title"
        variant="h6"
        component="h2"
        sx={{ textAlign: "center", borderBottom: "1px solid #000" }}
      >
        Letter of concern
      </Typography>
      <Typography>
        <div id="discrepancy-letter">
          <ConcernPdf
            name={props.name}
            total={props.total}
            month={props.month}
          />
        </div>
        <Stack
          direction="row"
          spacing={12}
          alignItems="center"
          justifyContent="center"
        >
          <Button variant="outlined" color="error" onClick={props.close}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={handleSave}
            disabled={disabled}
          >
            Send letter
          </Button>
        </Stack>
      </Typography>
    </Box>
  );
}
