import { getCompanies } from "../../../../../utils/apiUtils";
import Header from "../../../../header/Header";
import "../payroll.scss";
import { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import Loader from "../../../../animations/Loader";
import { IconButton, Alert } from "@mui/material";
import { decryptData } from "../../../../../utils/cryptoUtils";
import UserDiscreps from "./UserDiscreps";

export default function DiscrepHome() {
  const url = "https://nitropos-backend.nitrosolutions.co.uk";
  const [token, setToken] = useState("");
  const [siteList, setSiteList] = useState([]);
  const [site, setSite] = useState("");
  const [month, setMonth] = useState("");
  const [finalised, setFinalised] = useState(false);
  const [discrepancies, setDiscrepancies] = useState([]);
  const [userDiscrepancies, setUserDiscrepancies] = useState([]);
  const [staffName, setStaffName] = useState("");
  const [total, setTotal] = useState("");
  const [staffId, setStaffId] = useState("")
  const [letterSent, setLetterSent] = useState(false)
  const [search, setSearch] = useState(false)

  //alerts
  const [loader, setLoader] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");

  useEffect(() => {
    let user = localStorage.getItem("user");
    let parseU = JSON.parse(user);
    let localToken = parseU.token;
    setToken(localToken);

    getCompanies(localToken)
      .then((sites) => {
        setSiteList(sites);
      })
      .catch((err) => {
        console.log(`Could not get sites: ${err}`);
      });
  }, []);

  const handleMonth = () => {
    setDiscrepancies([]);
    setUserDiscrepancies([]);
    if (site === "" || site === "--Please select a site--" || month === "") {
      setMessage(`Site and month has to be selected, try again`);
      setSeverity("warning");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3500);
    } else if (loader === true){
      setSearch(true)
      setTimeout(() => {
        setSearch(false)
      },3000)
    } else {
      setLoader(true);
      try {
        let data = {
          date: month,
          site: site,
          token: token,
        };
        axios
          .post(`${url}/discrepancies/getDiscrepancies`, data)
          .then((res) => {
            setLoader(false);
            console.log(res.data.discrepancies)
            const monthDiscreps = res.data.discrepancies;
            setFinalised(res.data.finalised);
            if (monthDiscreps.length === 0) {
              setMessage(`No discrepancies to show`);
              setSeverity("success");
              setShowAlert(true);
              setTimeout(() => {
                setShowAlert(false);
              }, 3500);
            } else {
              for (let d = 0; d < monthDiscreps.length; d++) {
                setDiscrepancies((discrepancies) => [
                  ...discrepancies,
                  {
                    eposStaffId: monthDiscreps[d].eposStaffId,
                    mainCompanyId: monthDiscreps[d].mainCompanyId,
                    name: decryptData(
                      monthDiscreps[d].name,
                      process.env.REACT_APP_EMP_KEY
                    ),
                    sum: monthDiscreps[d].totalOwed,
                    letterSent: monthDiscreps[d].letterSent,
                    totalDiscrep: monthDiscreps[d].totalDiscrep,
                    allCharged: monthDiscreps[d].allCharged
                  },
                ]);
              }
            }
          })
          .catch((err) => {
            console.log(`Could not get discrepancies: ${err}`);
          });
      } catch (err) {
        setLoader(false);
        console.log(`Could not get discrepancies: ${err}`);
        setMessage(`Could not get discrepancies: ${err}`);
        setSeverity("error");
        showAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 3500);
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleMonth();
    }
  };

  const reloadMonth = async () => {
    handleMonth()
    handleDiscrepancy(staffId, staffName, total)
  }

  // const rel = () => {
  //   handleDiscrepancy(staffId, staffName, total)
  // }

  const handleDiscrepancy = (staffId, staffName, total, letterSent) => {
    setStaffName("")
    setUserDiscrepancies([])
    setTotal("")
    setStaffId("")
    setLetterSent(false)
    setStaffName(staffName)
    setTotal(total)
    setStaffId(staffId)
    setLetterSent(letterSent)
    try {
      let data = {
        date: month,
        staffId: staffId,
        token: token,
      };
      axios
        .post(`${url}/discrepancies/getUserDiscrepancies`, data)
        .then((res) => {
          console.log(res.data)
          setUserDiscrepancies(res.data);
        })
        .catch((err) => {
          console.log(`Could not get the discrepancy: ${err}`);
        });
    } catch (err) {
      console.log(`Could not get the discrepancy: ${err}`);
      setMessage(`Could not get selected discrepancy: ${err}`);
      setSeverity("error");
      showAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3500);
    }
  };

  return (
    <main className="discrepancies_main">
      <Header />
      <h1>discrepancy report for payroll</h1>
      <section className="row_select">
        <select onChange={(e) => setSite(e.target.value)}>
          <option>--Please select a site--</option>
          {siteList.map((site) => {
            return <option key={site.companyId}>{site.siteName}</option>;
          })}
        </select>
        <input
          type="month"
          min="2023-01"
          onChange={(e) => setMonth(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <IconButton onClick={handleMonth} disabled={search}>
          <SearchIcon color="primary" fontSize="large" />
        </IconButton>
      </section>
      {finalised ? <h2>This month has been finalised</h2> : null}
      {showAlert ? (
        <Alert severity={severity} variant="filled">{message}</Alert>
      ) : loader ? (
        <Loader />
      ) : null}
      {discrepancies.length > 0 ? (
        <section className="discrepancies">
          <div className="list">
            <h4>Discrepancies</h4>
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Left To be Charged</th>
                  <th>Total Discrep</th>
                </tr>
              </thead>
              <tbody>
                {discrepancies.map((discrep) => {
                  let bgColor = "";
                  let color = "";

                  if (discrep.allCharged === true){
                    bgColor = "#03a64a";
                    color = "#fff";
                  }
                  return (
                    <tr
                      key={discrep.eposStaffId}
                      onClick={() => handleDiscrepancy(discrep.eposStaffId, discrep.name, discrep.sum, discrep.letterSent)}
                      style={{backgroundColor: bgColor, color: color}}
                    >
                      <td>{discrep.name}</td>
                      <td>{discrep.sum}</td>
                      <td>{discrep.totalDiscrep}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {userDiscrepancies.length > 0 ? (
            <UserDiscreps discrepancies={userDiscrepancies} name={staffName} month={month} total={total} reloadMonth={reloadMonth} letterSent={letterSent}/>
          ) : (
            <h1 style={{width: "70%", textAlign: "center"}}>Please select a discrepancy</h1>
          )}
        </section>
      ) : null}
    </main>
  );
}
