import Box from "@mui/material/Box";
import { Button, Stack, Alert } from "@mui/material";
import Typography from "@mui/material/Typography";
import logo from "../../../adminTasks/payroll/letters/modals/RT_Logo.png";
import Divider from "@mui/material/Divider";
import html2pdf from "html2pdf.js";
import moment from "moment";
import { useState, useEffect } from "react";
import { getS3Link } from "../../../../../utils/apiUtils";
import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  maxHeight: "90vh",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "1rem",
  boxShadow: 24,
  p: 4,
  overflowY: "scroll",
};

export default function SalaryIncrease(props) {
  const url = process.env.REACT_APP_API_URL;
  const today = moment();
  const [disabled, setDisabled] = useState(false);
  const [token, setToken] = useState("");
  const config = { headers: { "x-auth-token": token } };
  const [noticeLength, setNoticeLength] = useState("");
  const [lastDay, setLastDay] = useState("");
  const [lastLegal, setLastLegal] = useState("");
  const [lastPay, setLastPay] = useState("");
  const [user, setUser] = useState("");
  const [originalDate, setOriginalDate] = useState("")
  const [newDate, setNewDate] = useState("")
  const [originalSalary, setOriginalSalary] = useState("")
  const [newSalary, setNewSalary] = useState("")
  const [payTypeWas,setPayTypeWas]=useState("")
  const [payTypeNow,setPayTypeNow]=useState("")
  //   alert
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    let user = localStorage.getItem("user");
    let parseU = JSON.parse(user);
    setToken(parseU.token);
    setUser(parseU.user);
  }, []);

  const getTargetElement = () => document.getElementById("letter");

  const handleSendLetter = async () => {
    if (
      originalDate === "" ||
      newDate === "" ||
      originalSalary === "" ||
      newSalary === ""||
      payTypeNow === "" ||
      payTypeWas === ""
    ) {
      setMessage(`Please ensure all fields are complete`);
      setSeverity("warning");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
        setDisabled(false);
      }, 3500);
    } else {
      setDisabled(true);

      const data = {
        type: 37,
        employeeDetailsId: props.employee.employeeDetailsId,
        fileName: `${props.employee.employeeDetailsId
          }-Email - Salary Increase-${moment().format("YYYY-MM-DD-HH-mm")}.pdf`,
          user:user
      };
      await getS3Link(data, config)
        .then((res) => {
          createPdf(res);
        })
        .catch((err) => {
          console.log(`Could not get the S3 link: ${err.response.data}`);
          setMessage(`Could not upload the document, please try again`);
          setSeverity("warning");
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
            setDisabled(false);
          }, 3500);
        });
    }
  };

  const createPdf = async (details) => {
    const element = getTargetElement();
    let opt = {
      html2canvas: { scale: 2 },
      margin: [1.4, 0.7, 1.7, 0.7], //10.5, 1, 26.5, 0 //Top, Left, Bottom, Right
      jsPDF: { unit: "cm", format: "a4", orientation: "portrait" },
    };

    const pdfNew = await html2pdf()
      .from(element)
      .set(opt)
      .output("arraybuffer");
    console.log("------SENDING LETTER------");
    // Upload to S3
    await axios
      .put(details.url, pdfNew)
      .then((res) => {
        let resend = false;
        const data = {
          documentId: details.documentId,
          user: user,
          resend: resend,
        };
        axios
          .post(`${url}/employee/emailS3Documents`, data, config)
          .then((res) => {
            setMessage(`Letter sent`);
            setSeverity("success");
            setShowAlert(true);
            setTimeout(() => {
              setShowAlert(false);
              props.close();
            }, 3500);
          })
          .catch((err) => {
            console.log(`Could not send the email: ${err}`);
            setMessage(
              `Could not send the email to the employee, please try again`
            );
            setSeverity("error");
            setShowAlert(true);
            setTimeout(() => {
              setShowAlert(false);
            }, 3500);
          });
      })
      .catch((err) => {
        setDisabled(false);
        console.log(`Could not upload to S3: ${err}`);
        setMessage(`Could not upload the document, please try again`);
        setSeverity("error");
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 3500);
      });
  };

  return (
    <Box sx={style}>
      <Typography
        id="modal-modal-title"
        variant="h6"
        component="h2"
        sx={{
          textAlign: "center",
          marginBottom: "20px",
        }}
      >
        Salary Increase
      </Typography>
      <h4>Original Details</h4>
      <Typography variant="body2">
        Date:
      </Typography>
      <input type="date" onChange={(e) => setOriginalDate(e.target.value)} />
      <Typography variant="body2">Salary:</Typography>
      £<input type="text" onChange={(e) => setOriginalSalary(e.target.value)} />
      <select onChange={(e)=>setPayTypeWas(e.target.value)}>
        <option>--- Select Pay Type ---</option>
        <option>per annum</option>
        <option>per hour</option>
      </select>
      <h4>New Details</h4>
      <Typography variant="body2">Date:</Typography>
      <input type="date" onChange={(e) => setNewDate(e.target.value)} />
      <Typography variant="body2">Salary:</Typography>
      £<input type="text" onChange={(e) => setNewSalary(e.target.value)} />
      <select onChange={(e)=>setPayTypeNow(e.target.value)}>
        <option>--- Select Pay Type ---</option>
        <option>per annum</option>
        <option>per hour</option>
      </select>

      <Divider sx={{ margin: "1% auto" }} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          justifyContent: "flex-start",
        }}
        id="letter"
      >
        <Box
          sx={{
            display: "flex",
            alignContent: "center",
            justifyContent: "space-between",
            margin: "2% auto",
          }}
        >
          <img src={logo} alt="RT Logo" width="20%" />
          <Typography variant="subtitle1">
            Private &amp; Confidential
          </Typography>
        </Box>
        <Typography variant="h3" sx={{ textAlign: "center" }}>
          {props.company.companyName}
        </Typography>
        <Typography variant="h6" sx={{ textAlign: "center" }}>
          {props.company.addressLine1}, {props.company.city},{" "}
          {props.company.postcode}
        </Typography>
        <Typography>{today.format("dddd, Do MMMM YYYY")}</Typography>
        <Typography variant="body1" sx={{ margin: "2% 0" }}>
          Dear {props.employee.firstName},
        </Typography>
        <Typography variant="body1" sx={{ margin: "1% 0" }}>
          As indicated in your individual Statement of Main Terms of Employment your salary as of
          {originalDate === "" ? (
            <strong> [ SELECT ORIGINAL DATE ] </strong>
          ) : (
            <> {new Date(originalDate).toLocaleDateString("en-GB")} </>
          )}
          was
          {originalSalary === "" ? (
            <strong> [ SELECT ORIGINAL SALARY ] </strong>
          ) : (
            <> £{originalSalary} </>
          )}
          {payTypeWas === "" ? (
            <strong> [ SELECT ORIGINAL PAY TYPE ] </strong>
          ) : (
            <> {payTypeWas} </>
          )}
        </Typography>
        <Typography variant="body1" sx={{ margin: "1% 0" }}>
          From
          {newDate === "" ? (
            <strong> [ SELECT NEW DATE ] </strong>
          ) : (
            <> {new Date(newDate).toLocaleDateString("en-GB")} </>
          )}
          your salary will be
          {newSalary === "" ? (
            <strong> [ SELECT NEW SALARY ] </strong>
          ) : (
            <> £{newSalary} </>
          )}
          {payTypeNow === "" ? (
            <strong> [ SELECT NEW PAY TYPE ] </strong>
          ) : (
            <> {payTypeNow} </>
          )} payable monthly in arrears by BACs as detailed on your pay statement.
        </Typography>
        <Typography variant="body1" sx={{ margin: "1% 0" }}>
          In your first year of employment your salary will be proportionate to the amount of time left in the year.
          Any additional hours worked will be paid at your normal hourly rate.
        </Typography>
        <Typography variant="body1" sx={{ margin: "1% 0" }}>
          This salary increase letter, together with the Employee Handbook and your individual Statement
          of Main Terms of Employment forms part of your Contract of Employment.
        </Typography>
        <Typography  variant="body1" sx={{ margin: "1% 0" }}>
          Please note that this salary increase is confidential information of the company. 
          Any discussions of your salary with anybody other than HR will be considered a breach of the agreement by you.
        </Typography>
        <Typography variant="body1" sx={{ margin: "1% 0" }}>
          Yours sincerely,
        </Typography>
        <Typography variant="body1" sx={{ margin: "1% 0" }}>
          HR Team.
        </Typography>
      </Box>

      {showAlert ? (
        <Alert severity={severity}>{message}</Alert>
      ) : (
        <Stack
          direction="row"
          spacing={12}
          alignItems="center"
          justifyContent="center"
        >
          <Button variant="outlined" color="error" onClick={props.close}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={handleSendLetter}
            disabled={disabled}
          >
            Send letter
          </Button>
        </Stack>
      )}
    </Box>
  );
}
