import { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DescriptionIcon from "@mui/icons-material/Description";
import { Button, Modal, InputLabel } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { getS3Link } from "../../../../utils/apiUtils";
import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  maxHeight: "85vh",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
  overflowY: "scroll",
};

export default function RightWork(props) {
  const employeeDetails = props.employee;
  const poi = props.employee.poi;
  const poa = props.employee.poa;
  const [disable, setDisable] = useState(false);
  const [settled, setSettled] = useState(null);
  const [doc, setDoc] = useState(null);
  const [visaDocs, setVisaDocs] = useState([]);
  const [token, setToken] = useState("");
  const [hrUser, setHrUser] = useState("")
  const config = { headers: { "x-auth-token": token } };
  const url = process.env.REACT_APP_API_URL;

  // Modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    let user = localStorage.getItem("user");
    let parseU = JSON.parse(user);
    let localToken = parseU.token;
    setToken(localToken);
    setHrUser(parseU.user)
    const localConfig = { headers: { "x-auth-token": localToken } };

    if (props.employee.settledStatus != null) {
      setDisable(props.employee.settledStatus);
      setSettled(props.employee.settledStatus);
    }

    //gets visa docs
    axios
      .post(
        `${url}/employee/employeeVisaDocuments`,
        { employeeDetailsId: props.employee.employeeDetailsId },
        localConfig
      )
      .then((res) => {
        setVisaDocs(res.data);
      })
      .catch((err) => {
        console.log(`Could not get documents: ${err.response.data}`);
      });
  }, [props.employee]);

  const handlePoi = () => {
    let image = new Image();
    image.src = `data:image/jpg;base64,${poi}`;
    image.download = `${employeeDetails.firstName}_${employeeDetails.lastName}_poId.jpeg`;
    let w = window.open(
      "",
      `${employeeDetails.firstName}_${employeeDetails.lastName}_poId.jpeg`
    );
    w.document.write(image.outerHTML);
  };

  const handlePoa = () => {
    let image = new Image();
    image.src = `data:image/jpg;base64,${poa}`;
    let w = window.open("", "_blank");
    w.document.write(image.outerHTML);
  };

  const handleSettled = (status) => {
    setSettled(status);
    setDisable(status);
    props.handleEditEmployee("settledStatus", true);
  };

  const handlePdf = (pdf) => {
    let extension = pdf.name.split(".").pop();

    if (extension === "exe") {
      //TODO alert here
      console.log("not accepted");
    } else {
      setDoc(pdf);
      console.log(pdf.name.split(".").pop());
    }
  };

  const handleUploadDocs = async () => {
    const data = {
      type: 5,
      employeeDetailsId: employeeDetails.employeeDetailsId,
      fileName: doc.name,
      user: hrUser
    };
    // console.log(data);
    await getS3Link(data, config)
      .then((res) => {
        uploadS3(res.url);
      })
      .catch((err) => {
        console.log(`Could not get the S3 link: ${err.response.data}`);
      });
  };

  const uploadS3 = (link) => {
    axios
      .put(link, doc)
      .then((res) => {
        handleClose();
        props.rel();
      })
      .catch((err) => {
        console.log(`Could not upload to S3: ${err.response.data}`);
      });
  };

  const handleViewVisaDoc = (link) => {};

  return (
    <section className="employee_personal">
      <h4>Right to work</h4>
      <div className="personal_row">
        <div clasName="personal_column">
          <strong>
            UK citizen:{" "}
            <span>{employeeDetails.ukCitizen ? <>YES</> : <>NO</>}</span>
          </strong>
          {!employeeDetails.ukCitizen ? (
            <div clasName="personal_column">
              <div className="edit_row">
                <strong>
                  Share code/Visa: <span>{employeeDetails.shareCode}</span>
                </strong>
                <EditIcon
                  fontSize="small"
                  className="edit_icon"
                  onClick={() => props.handleEdit("share_code")}
                />
                <input
                  type="text"
                  id="share_code"
                  onChange={(e) =>
                    props.handleEditEmployee("shareCode", e.target.value)
                  }
                />
              </div>
              <div className="edit_row">
                <strong>
                  Expire:{" "}
                  <span>
                    {new Date(employeeDetails.shareExpire).toLocaleDateString(
                      "en-GB"
                    )}
                  </span>
                </strong>
                <EditIcon
                  fontSize="small"
                  className="edit_icon"
                  onClick={() => props.handleEdit("shareExpire")}
                />
                <input
                  type="date"
                  id="shareExpire"
                  onChange={(e) =>
                    props.handleEditEmployee("shareExpire", e.target.value)
                  }
                />
              </div>
              <FormControl component="fieldset">
                <FormLabel component="legend">Settled Status?</FormLabel>
                <FormGroup
                  aria-label="position"
                  row
                  onChange={(e) => handleSettled(e.target.value)}
                >
                  <FormControlLabel
                    value={true}
                    control={<Checkbox />}
                    label="Yes"
                    labelPlacement="bottom"
                    checked={settled}
                  />
                </FormGroup>
              </FormControl>
              <div>
                <Button
                  disabled={disable}
                  variant="contained"
                  onClick={handleOpen}
                >
                  Update Documents
                </Button>
              </div>
            </div>
          ) : null}
        </div>
        <div className="personal_column">
          {(poi != null && poa != null) || visaDocs.length > 0 ? (
            <div className="id_row">
              {poi != null ? (
                <div className="id_column" onClick={handlePoa}>
                  <DescriptionIcon color="primary" />
                  <span>Proof of address</span>
                </div>
              ) : null}
              {poa != null ? (
                <div className="id_column" onClick={handlePoi}>
                  <DescriptionIcon color="primary" />
                  <small>Proof of ID</small>
                </div>
              ) : null}
              {visaDocs.map((document) => {
                return (
                  <a
                    href={document.link}
                    target="_blank"
                    className="id_column"
                    key={document.documentId}
                    style={{ textDecoration: "none" }}
                  >
                    <DescriptionIcon color="primary" />
                    <small style={{color: "#333"}}>{document.key}</small>
                  </a>
                );
              })}
            </div>
          ) : (
            <h4>No Documents to show</h4>
          )}
        </div>
      </div>

      <Modal open={open}>
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ textAlign: "center" }}
          >
            <InputLabel>Upload Visa Documents</InputLabel>
            <input type="file" onChange={(e) => handlePdf(e.target.files[0])} />
          </Typography>
          <Stack
            direction="row"
            spacing={12}
            alignItems="center"
            justifyContent="center"
          >
            <Button variant="outlined" color="error" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="success"
              onClick={handleUploadDocs}
            >
              Upload
            </Button>
          </Stack>
        </Box>
      </Modal>
    </section>
  );
}
