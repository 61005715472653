import { useState } from "react";
import moment from "moment";
import { Modal } from "@mui/material";
import NoteModal from "./NoteModal";

export default function NotificationRow({ d }) {
    const [openModal, setOpenModal] = useState(false);
    const [notificationId, setNotificationId] = useState(0);

    const handleOpen = () => setOpenModal(true);
    const handleClose = () => {
        setOpenModal(false);
    };
    const viewNotification = async (id) => {
        setNotificationId(id);
        handleOpen();
    };
    console.log(d);
    return (
        <>
            <tr onClick={() => viewNotification(d.notificationId)}>
                <td>{d.notificationId}</td>
                <td>{d.editReason}</td>
                <td>{d.siteName}</td>
                <td>{moment(d.timeOfEdit).format("HH:mm - DD/MM/YYYY")}</td>
            </tr>
            <Modal open={openModal} onClose={handleClose}>
                <NoteModal
                    notificationId={notificationId}
                    handleClose={handleClose}
                    siteId={d.siteId}
                    start={d.startDate}
                    end={d.endDate}
                    employee={d.employeeDetailsId}
                />
            </Modal>
        </>
    );
}
