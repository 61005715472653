import logo from "./RT_Logo.png";
import "../../payroll.scss";
import moment from "moment";

export default function ConcernPdf(props) {
  const today = moment();
  const month = moment(props.month, "yyyy-MM").format("MMMM yyyy")

  return (
    <section className="pdf_content">
      <section className="header">
        <img src={logo} alt="logo"/>
        <h4>Private &amp; Confidential</h4>
      </section>
      <section className="letter_body">
        <p>{today.format("dddd, Do MMMM YYYY")}</p>
        <p>Dear {props.name},</p>
        <p>
          I am writing to highlight my concerns regarding your discrepancies for
          the month of {month}. In particular, the month of {month}, you had a
          discrepancy total of <strong>£{props.total}</strong>.
        </p>
        <p>
          We would like to highlight that you should be careful when handling
          cash and should be strictly following all cash procedures on every
          shift. I expect to see at least a minimum of a 50% reduction in
          Discrepancies next month.
        </p>
        <p>
          On this particular occasion I have decided not to proceed with formal
          disciplinary action. However, this letter is to be treated as
          confirmation that I have discussed my concerns with you and that you
          are expected to make every effort to address the shortcomings that
          have been identified.
        </p>
        <p>
          This letter is not intended to be a formal warning and does not form
          part of the company’s disciplinary procedure; however, it will be kept
          in your personnel file and thus takes the form of what I consider to
          be a reasonable written management instruction.
        </p>
        <p>
          Should there be any repeat of this conduct, or indeed any misconduct
          in general you may be subject to formal disciplinary action.
        </p>
        <p>
          If you have any queries regarding the content of this letter, please
          do not hesitate to contact the HR team.
        </p>
        <p>Regards</p>
        <strong>HR Team</strong>
        <span style={{ color: "#878787", fontSize: "10pt" }}>
          HR Department
          <br />
          RaceTrack Pitstop
        </span>
        <div
          style={{
            maxWidth: "300px",
            fontSize: "0.9em",
            whiteSpace: "nowrap",
            borderLeft: "2px solid #BF1A1A",
            marginLeft: "0",
            paddingLeft: "20px",
          }}
        >
          <div>
            <div>
              <span>w: </span>
              <a
                style={{ color: "#BF1A1A" }}
                href="https://racetrackpitstop.co.uk"
                target="_blank"
                rel="noreferrer"
              >
                racetrackpitstop.co.uk
              </a>
            </div>
            <div>
              <span>e: </span>
              <a
                style={{ color: "#BF1A1A" }}
                href="mailto:hr@racetrackpitstop.co.uk"
              >
                hr@racetrackpitstop.co.uk
              </a>
            </div>
            <div>
              <span style={{ marginRight: "8px" }}>
                <span>p: </span>
                <a style={{ color: "#BF1A1A" }} href="tel:0141 319 8451">
                  0141 319 8451
                </a>
              </span>
            </div>
            <div style={{ wordWrap: "break-word" }}>
              <span>a: </span>
              <a
                style={{ wordWrap: "break-word", color: "#BF1A1A" }}
                href="https://maps.google.com/?q=St.+James+Church+30+Underwood+Road+Paisley+PA3+1TL"
                target="_blank"
                rel="noreferrer"
              >
                St. James Church, 30 Underwood Road, Paisley, PA3 1TL
              </a>
            </div>
            <div></div>
          </div>
        </div>
      </section>
    </section>
  );
}
