import { useState, useEffect } from "react";
import axios from "axios";
import PersonOffIcon from '@mui/icons-material/PersonOff';
import { Alert, Button, Modal, Box, Stack, Typography } from "@mui/material";
import UnassignedRole from "./UnassignedRole";

export default function Unassigned(props) {

    const [unassigned, setUnassigned] = useState([])
    const [showUnassigned, setShowUnassigned] = useState(false)
    const [reload, setReload] = useState(false)
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("");
    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/groups/unassigned`).then((resp) => {
            setUnassigned(resp.data)
        }).catch((err) => {
            setMessage("Something Went Wrong. Please Try Again.")
            setSeverity("error");
            setShowAlert(true)
            setTimeout(() => {
                setShowAlert(false)
                setMessage("")
                setSeverity("")
            }, 3000)
        })
    }, [props, reload])

    const reset = async () => {
        setShowUnassigned(false)
        props.reset();
        setReload(!reload)
    }

    return (
        <>
            <Button className="unassignedIcon" onClick={() => setShowUnassigned(!showUnassigned)} variant="contained" id="usr_btn" ><PersonOffIcon /></Button>
            <Modal
                open={showUnassigned}
                aria-labelledby="stock-addition"
                aria-describedby="add-stock-to-product"
                onClose={reset}
            >
                <Box sx={props.style}>
                    <div>
                        <div style={{ position: "sticky", top: "0", marginBottom:"2%" }}>
                            <Typography style={{ textAlign: "center", fontWeight: "bold" }} id="stock-addition" variant="h6" component="h2">
                                Unassigned Roles
                            </Typography>
                            <Stack spacing={12} direction="row" sx={{ position:"absolute", top:"0", right:"2%" }}>
                                <Button variant="contained" color="error" onClick={reset}>X</Button>
                            </Stack>
                        </div>

                        <div style={{minHeight:"50vh",maxHeight:"50vh",overflowY:"scroll"}}>
                            {unassigned.length > 0 ?
                                <>{unassigned.map(function (d, i) {
                                    return (
                                        <UnassignedRole role={d} groupData={props.groupData} reset={reset} />
                                    )
                                })}</>
                                :
                                <h2>There Are Currently No Unassigned Roles</h2>
                            }
                        </div>

                        {showAlert ? (
                            <Alert severity={severity} >{message}</Alert>
                        ) : null}

                    </div>

                </Box>
            </Modal>
        </>
    )
}