import Box from "@mui/material/Box";
import { Button, Stack, Alert } from "@mui/material";
import Typography from "@mui/material/Typography";
import logo from "../../../adminTasks/payroll/letters/modals/RT_Logo.png";
import Divider from "@mui/material/Divider";
import html2pdf from "html2pdf.js";
import moment from "moment";
import { useState, useEffect } from "react";
import { getS3Link } from "../../../../../utils/apiUtils";
import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  maxHeight: "90vh",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "1rem",
  boxShadow: 24,
  p: 4,
  overflowY: "scroll",
};

export default function ResgAccept(props) {
  const url = process.env.REACT_APP_API_URL;
  const today = moment();
  const [disabled, setDisabled] = useState(false);
  const [token, setToken] = useState("");
  const config = { headers: { "x-auth-token": token } };
  const [noticeLength, setNoticeLength] = useState("");
  const [lastDay, setLastDay] = useState("");
  const [lastPay, setLastPay] = useState("");
  const [possessions, setPossessions] = useState("");
  const [user, setUser] = useState("");
  //   alert
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    let user = localStorage.getItem("user");
    let parseU = JSON.parse(user);
    setToken(parseU.token);
    setUser(parseU.user);
  }, []);

  const handleLastDay = (day) => {
    setLastDay(day);
    let items = moment(day).add(5, "days");
    setPossessions(items.format("DD/MM/YYYY"));
  };

  const getTargetElement = () => document.getElementById("letter");

  const handleSendLetter = async () => {
    if (
      noticeLength === "" ||
      noticeLength === "--Please select one--" ||
      lastDay === "" ||
      lastPay === ""
    ) {
      setMessage(`Please select all needed fields`);
      setSeverity("warning");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
        setDisabled(false);
      }, 3500);
    } else {
      setDisabled(true);

      const data = {
        type: 29,
        employeeDetailsId: props.employee.employeeDetailsId,
        fileName: `${
          props.employee.employeeDetailsId
        }-Email - Accepting Resignation-${moment().format(
          "YYYY-MM-DD-HH-mm"
        )}.pdf`,
        user:user
      };
      await getS3Link(data, config)
        .then((res) => {
          console.log(res);
          createPdf(res);
        })
        .catch((err) => {
          console.log(`Could not get the S3 link: ${err.response.data}`);
          setMessage(`Could not upload the document, please try again`);
          setSeverity("warning");
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
            setDisabled(false);
          }, 3500);
        });
    }
  };

  const createPdf = async (details) => {
    const element = getTargetElement();
    let opt = {
      html2canvas: { scale: 2 },
      margin: [1.4, 0.7, 1.7, 0.7], //10.5, 1, 26.5, 0 //Top, Left, Bottom, Right
      jsPDF: { unit: "cm", format: "a4", orientation: "portrait" },
    };

    const pdfNew = await html2pdf()
      .from(element)
      .set(opt)
      .output("arraybuffer");
    console.log("------SENDING LETTER------");

    // Upload to S3
    await axios
      .put(details.url, pdfNew)
      .then((res) => {
        // TODO: Sent email with the attachment
        let resend = false;
        const data = {
          documentId: details.documentId,
          user: user,
          resend: resend,
        };
        axios
          .post(`${url}/employee/emailS3Documents`, data, config)
          .then((res) => {
            setMessage(`Letter sent`);
            setSeverity("success");
            setShowAlert(true);
            setTimeout(() => {
              setShowAlert(false);
              props.close();
            }, 3500);
          })
          .catch((err) => {
            console.log(`Could not send the email: ${err}`);
            setMessage(
              `Could not send the email to the employee, please try again`
            );
            setSeverity("error");
            setShowAlert(true);
            setTimeout(() => {
              setShowAlert(false);
            }, 3500);
          });
      })
      .catch((err) => {
        setDisabled(false);
        console.log(`Could not upload to S3: ${err.response.data}`);
        setMessage(`Could not upload the document, please try again`);
        setSeverity("error");
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 3500);
      });
  };

  return (
    <Box sx={style}>
      <Typography
        id="modal-modal-title"
        variant="h6"
        component="h2"
        sx={{
          textAlign: "center",
          marginBottom: "20px",
        }}
      >
        Resignation acceptance
      </Typography>
      <Typography variant="body2">Please select length notice:</Typography>
      <select onChange={(e) => setNoticeLength(e.target.value)}>
        <option>--Please select one--</option>
        <option>one</option>
        <option>two</option>
        <option>three</option>
        <option>four</option>
        <option>six</option>
      </select>
      <Typography variant="body2">Please select last day:</Typography>
      <input type="date" onChange={(e) => handleLastDay(e.target.value)} />
      <Typography variant="body2">Please select last pay day:</Typography>
      <input type="date" onChange={(e) => setLastPay(e.target.value)} />

      <Divider sx={{ margin: "1% auto" }} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          justifyContent: "flex-start",
        }}
        id="letter"
      >
        <Box
          sx={{
            display: "flex",
            alignContent: "center",
            justifyContent: "space-between",
            margin: "2% auto",
          }}
        >
          <img src={logo} alt="RT Logo" width="20%" />
          <Typography variant="subtitle1">
            Private &amp; Confidential
          </Typography>
        </Box>
        <Typography variant="h3" sx={{ textAlign: "center" }}>
          {props.company.companyName}
        </Typography>
        <Typography variant="h6" sx={{ textAlign: "center" }}>
          {props.company.addressLine1}, {props.company.city},{" "}
          {props.company.postcode}
        </Typography>
        <Typography>{today.format("dddd, Do MMMM YYYY")}</Typography>
        <Typography variant="body1" sx={{ margin: "2% 0" }}>
          Dear {props.employee.firstName}:
        </Typography>
        <Typography variant="body1" sx={{ margin: "1% 0" }}>
          Further to your resignation letter, I am writing to confirm that the
          company acknowledges receipt of this and accepts your resignation.
        </Typography>
        <Typography variant="body1" sx={{ margin: "1% 0" }}>
          As per the company’s policy and employment agreement, you are required
          to serve a{" "}
          {noticeLength === "" || noticeLength === "--Please select one--" ? (
            <strong>SELECT NOTICE LENGTH</strong>
          ) : (
            noticeLength
          )}{" "}
          week notice period and your final day of employment will be{" "}
          {lastDay === "" ? (
            <strong>PLEASE SELECT LAST DAY</strong>
          ) : (
            new Date(lastDay).toLocaleDateString("en-GB")
          )}
          .
        </Typography>
        <Typography variant="body1" sx={{ margin: "1% 0" }}>
          Any monies owed will be paid to you on{" "}
          {lastPay === "" ? (
            <strong>PLEASE SELECT LAST PAY DAY</strong>
          ) : (
            new Date(lastPay).toLocaleDateString("en-GB")
          )}{" "}
          and your P45 will be sent to your email address in due course after
          the date above.
        </Typography>
        <Typography variant="body1" sx={{ margin: "1% 0" }}>
          Please return all company property including keys and uniform which
          are in your possession by{" "}
          {lastDay === "" ? (
            <strong>PLEASE SELECT LAST DAY</strong>
          ) : (
            possessions
          )}
          .
        </Typography>
        <Typography variant="body1" sx={{ margin: "1% 0" }}>
          We wish you all the best for your future endeavours.
        </Typography>
        <Typography variant="body1" sx={{ margin: "1% 0" }}>
          Yours sincerely,
        </Typography>
        <Typography variant="body1" sx={{ margin: "1% 0" }}>
          HR Team.
        </Typography>
      </Box>

      {showAlert ? (
        <Alert severity={severity}>{message}</Alert>
      ) : (
        <Stack
          direction="row"
          spacing={12}
          alignItems="center"
          justifyContent="center"
        >
          <Button variant="outlined" color="error" onClick={props.close}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={handleSendLetter}
            disabled={disabled}
          >
            Send letter
          </Button>
        </Stack>
      )}
    </Box>
  );
}
