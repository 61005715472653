import Footer from "../../footer/Footer";
import Header from "../../header/Header";
import "./onboarding.scss";
import { useNavigate } from "react-router-dom";

export default function OnboardingHome() {
  const navigate = useNavigate();
  return (
    <main className="onboarding__home">
      <Header />
      <section className="main_onboarding">
        <h1>Onboarding Home</h1>
        <section className="onboarding_cards">
          <div
            className="onboarding_card"
            onClick={() => navigate("/interview-calendar")}
          >
            <h1>Interview Calendar</h1>
          </div>
          <div
            className="onboarding_card"
            onClick={() => navigate("/interview-calendar/schedule")}
          >
            <h1>Interview Schedule</h1>
          </div>
          <div
            className="onboarding_card"
            onClick={() => navigate("/onboarding/status")}
          >
            <h1>Onboarding Status</h1>
          </div>
          <div
            className="onboarding_card"
            onClick={() => navigate("/contracts")}
          >
            <h1>Contracts to Approve</h1>
          </div>{" "}
          <div
            className="onboarding_card"
            onClick={() => navigate("/onboarding/new-manager")}
          >
            <h1>New Manager</h1>
          </div>

          <div
            className="onboarding_card"
            onClick={() => navigate("/onboarding/manual-contract")}
          >
            <h1>NSP Generation</h1>
          </div>
        </section>
      </section>
      <Footer />
    </main>
  );
}
