import {useState, useEffect} from "react";
import {Navigate, useNavigate} from "react-router-dom";
import "./main.scss";
import Header from "../header/Header";
import Footer from "../footer/Footer";

export default function Home() {
  const navigate = useNavigate();

  useEffect(() => {
    let userD = localStorage.getItem("user");
    let user = JSON.parse(userD);
    if (!user) {
      return <Navigate to="/" />;
    }
  });

  return (
    <section className="main">
      <Header />
      <section className="home">
        {/* <div className="card" onClick={navigate("/ats")}>
          <h1>ATS</h1>
        </div> */}
        <div className="card" onClick={() => navigate("/admin")}>
          <h1>Admin Tasks</h1>
        </div>
        <div className="card" onClick={() => navigate("/onboarding")}>
          <h1>Onboarding</h1>
        </div>
        <div className="card" onClick={() => navigate("/employee-management")}>
          <h1>Employee Dash</h1>
        </div>
      </section>
      <Footer />
    </section>
  );
}
