import { useState, useEffect } from "react";
import { IconButton, Alert } from "@mui/material";
import SpeakerNotesOffIcon from "@mui/icons-material/SpeakerNotesOff";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import { chargeDiscrepancies } from "../../../../../utils/apiUtils";
import CheckIcon from "@mui/icons-material/Check";
import Modal from "@mui/material/Modal";
import NotesModal from "./modals/NotesModal";
import PaymentsModal from "./modals/PaymentsModal";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ConfirmZero from "./modals/ConfirmZero";
import axios from "axios";
import ConfirmDelete from "./modals/ConfirmDelete";

const alertStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60vw",
};

export default function IndvDiscrep(props) {
  const url = "https://nitropos-backend.nitrosolutions.co.uk";
  const discrepancy = props.discrepancy;
  const charges = props.discrepancy.previousCharges;
  const [toPay, setToPay] = useState("");
  const [paid, setPaid] = useState(false);
  const [confirmZero, setConfirmZero] = useState(false);
  const [amount, setAmount] = useState(0)
  //alerts
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  //   modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openPay, setOpenPay] = useState(false);
  const handleOpenPay = () => setOpenPay(true);
  const handleClosePay = () => setOpenPay(false);
  const [openZero, setOpenZero] = useState(false);
  const handleOpenZero = () => setOpenZero(true);
  const handleCloseZero = () => setOpenZero(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const handleOpenConfirm = () => setOpenConfirm(true);
  const handleCloseConfirm = () => setOpenConfirm(false);


  useEffect(() => {
    if(props.discrepancy.currentCharge != null){
      setPaid(true);
    }
  }, []);

  const processChange = debounce(() => handlePayment())

  // to slow the function calling up the timeout
  function debounce(func, timeout = 400){
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
  }

  const handlePayment = () => {
    let totalPaid = 0;
    for (let p = 0; p < charges.length; p++){
      totalPaid += parseFloat(charges[p].amount)
    }
    let discrepancyTotal = totalPaid + parseFloat(amount)

    if (parseFloat(discrepancyTotal) > discrepancy.total){
      setMessage("You cannot charge the employee more than owed");
      setSeverity("warning");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
        document.getElementById("charge").value = "";
        setAmount(0)
      }, 3000);

    } else if (parseFloat(amount) < 0) {
      setMessage("You cannot charge less than £0");
      setSeverity("warning");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
        document.getElementById("charge").value = "";
        setAmount(0)
      }, 3000);
    } else {
      setToPay(amount);
    }
  };

  const confirmDiscrep = async () => {
    if (toPay === "") {
      setMessage(`No amount to charge`);
      setSeverity("error");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    } else if (!confirmZero && parseFloat(toPay) === 0) {
      handleOpenZero();
    } else {
      let array = [
        {
          detailsId: discrepancy.detailsId,
          month: props.month,
          amount: toPay,
          user: props.user,
        },
      ];
      if (discrepancy.currentCharge) {
        array[0].chargeId = discrepancy.currentCharge.chargeId;
      }
      await chargeDiscrepancies(array)
        .then((res) => {
          // console.log(res.data);
          setMessage(`Discrepancy updated`);
          setSeverity("success");
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
            props.rel();
          }, 3000);
        })
        .catch((err) => {
          console.log(`Could not update the discrepancy: ${err}`);
          setMessage(`Could not update the discrepancy: ${err}`);
          setSeverity("error");
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
          }, 3000);
        });
    }
  };

  const handleEdit = () => {
    setPaid(!paid);
  };

  const handleConfirm = async () => {
    setConfirmZero(!confirmZero);
    handleCloseZero();
    await confirmDiscrep();
  };

  const deleteDiscrep = () => {
    console.log("deleting");
    axios
      .post(`${url}/discrepancies/close`, { detailsId: discrepancy.detailsId })
      .then((res) => {
        console.log(res);
        props.rel();
      })
      .catch((err) => {
        console.log(`Could not delete the discrepancy: ${err}`);
        setMessage(`Could not delete the discrepancy: ${err}`);
        setSeverity("error");
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
      });
  };

  return (
    <>
      <tr key={discrepancy.eposStaffId}>
        <td>{discrepancy.reason}</td>
        <th>
          {discrepancy.investigationNotes.length > 0 ? (
            <SpeakerNotesIcon
              color="primary"
              onClick={handleOpen}
              sx={{ cursor: "pointer" }}
            />
          ) : (
            <SpeakerNotesOffIcon />
          )}
        </th>
        <td>{discrepancy.total}</td>
        {parseFloat(discrepancy.amountPaid) > 0 ? (
          <td onClick={handleOpenPay} style={{ cursor: "pointer" }}>
            {discrepancy.amountPaid}
          </td>
        ) : (
          <td>{discrepancy.amountPaid}</td>
        )}
        <td>{discrepancy.totalLeft}</td>
        {!paid ? (
          <td>
            <input
              type="number"
              id="charge"
              onChange={(e) => setAmount(e.target.value)}
              onKeyUp={processChange}
            />
          </td>
        ) : (
          <td>£{discrepancy.currentCharge.amount}</td>
        )}
        <td>
          {new Date(discrepancy.transactionDate).toLocaleDateString("en-GB")}
        </td>
        {!paid ? (
          <td>
            <IconButton onClick={confirmDiscrep} disabled={props.disable}>
              <CheckIcon color="success" />
            </IconButton>
          </td>
        ) : (
          <td>
            <IconButton onClick={handleEdit} disabled={props.disable}>
              <EditIcon color="primary" />
            </IconButton>
          </td>
        )}
        <td>
          <IconButton onClick={handleOpenConfirm} disabled={props.disable}>
            <DeleteForeverIcon color="error" />
          </IconButton>
        </td>
      </tr>
      {showAlert ? (
        <Alert severity={severity} style={alertStyle} variant="filled">
          {message}
        </Alert>
      ) : null}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <NotesModal notes={discrepancy.investigationNotes} />
      </Modal>
      <Modal
        open={openPay}
        onClose={handleClosePay}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <PaymentsModal charges={charges} />
      </Modal>
      <Modal
        open={openZero}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ConfirmZero close={handleCloseZero} confirm={handleConfirm} />
      </Modal>

      <Modal
        open={openConfirm}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ConfirmDelete close={handleCloseConfirm} confirm={deleteDiscrep} />
      </Modal>

    </>
  );
}
