import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import Stack from "@mui/material/Stack";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "1rem",
  boxShadow: 24,
  p: 4,
};

export default function CancelInterview(props) {
  const handleCancel = () => {
    props.close();
    props.handleCancelInterview();
  };
  
  return (
    <Box sx={style}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Are you sure you want to cancel?
      </Typography>
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        <Stack spacing={10} direction="row">
          <Button variant="contained" color="error" onClick={props.close}>
            No
          </Button>
          <Button variant="contained" color="success" onClick={handleCancel}>
            Yes
          </Button>
        </Stack>
      </Typography>
    </Box>
  );
}
