import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import axios from 'axios'
import { encryptData } from "../../../../utils/cryptoUtils";

export default function Attendance(props) {
    const [availability, setAvailability] = useState("")

    useEffect(() => {
        setAvailability(props.employee.availability)
    }, [props])

    const update = async () => {
        var data = {
            employeeDetailsId: props.employee.employeeDetailsId,
            availability: encryptData(availability, process.env.REACT_APP_EMP_KEY)
        }
        await axios.post(`${process.env.REACT_APP_API_URL}/employee/availability/update`, data).then((resp) => {
            alert("COMPLETE")
        }).catch((err) => {
            console.log(err)
        })
    }

    return (
        <div>
            <h1>Availability</h1>
            <div style={{ display: "flex", justifyContent: "space-evenly", textAlign: "center", height: "28vh" }}>
                <div style={{ border: "2px solid navy", borderRadius: "10px", width: "30%" }}>
                    <h2>Current</h2>
                    <hr />
                    <textarea cols={40} rows={8} value={availability} onChange={(e) => setAvailability(e.target.value)}></textarea>
                    <Button variant="contained" color="success" onClick={update}>Update</Button>
                </div>
                <div style={{ position: "absolute", backgroundColor: "rgba(0,0,0,0.5)", zIndex: 2,top: "28%", left: "44.5%",width:"46%", height:"44%" }}></div>
                <div style={{ border: "2px solid navy", borderRadius: "10px", width: "50%" }}>
                    <h2>Weekly</h2>
                    <hr />
                    <h2 style={{ position: "absolute", top: "45%", left: "55%", zIndex: "100", transform: "rotate(10deg)", fontSize: "2rem", letterSpacing: "10px" }}>COMING SOON</h2>
                    <div style={{ display: "flex", justifyContent: "space-evenly", paddingTop: "5%" }}>
                        <div>
                            <label>MON</label><br />
                            <input type="time" disabled={true} />
                            <input type="time" disabled={true} />
                        </div>
                        <div>
                            <label>TUE</label><br />
                            <input type="time" disabled={true} />
                            <input type="time" disabled={true} />
                        </div>
                        <div>
                            <label>WED</label><br />
                            <input type="time" disabled={true} />
                            <input type="time" disabled={true} />
                        </div>
                        <div>
                            <label>THU</label><br />
                            <input type="time" disabled={true} />
                            <input type="time" disabled={true} />
                        </div>
                        <div>
                            <label>FRI</label><br />
                            <input type="time" disabled={true} />
                            <input type="time" disabled={true} />
                        </div>
                        <div>
                            <label>SUN</label><br />
                            <input type="time" disabled={true} />
                            <input type="time" disabled={true} />
                        </div>
                        <div>
                            <label>SUN</label><br />
                            <input type="time" disabled={true} />
                            <input type="time" disabled={true} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}