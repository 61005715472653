import { useEffect, useState } from "react";
import DescriptionIcon from "@mui/icons-material/Description";
import axios from "axios";
import { Tooltip } from "@mui/material";

export default function Documents(props) {
  const employeeDetails = props.employee;
  const nspDetails = props.nspDetails;
  const policiesDetails = props.policiesDetails;
  const url = process.env.REACT_APP_API_URL;
  const [letters, setLetters] = useState([]);

  useEffect(() => {
    let user = localStorage.getItem("user");
    let parseU = JSON.parse(user);
    let localToken = parseU.token;
    const config = { headers: { "x-auth-token": localToken } };

    //get letters from s3
    axios
      .post(
        `${url}/employee/letterSearch`,
        { employeeDetailsId: props.employee.employeeDetailsId },
        config
      )
      .then((res) => {
        setLetters(res.data);
      })
      .catch((err) => {
        console.log(`Could not get the letters: ${err}`);
      });
  }, [props.employee]);

  const handleContract = () => {
    let windowName = `${employeeDetails.firstName}_${employeeDetails.lastName}_cv`;
    let pdfWindow = window.open("", windowName, "_blank");
    pdfWindow.document.write(
      "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
        encodeURI(employeeDetails.contractData) +
        "'></iframe>"
    );
  };

  const handleNsp = () => {
    let windowName = `${employeeDetails.firstName}_${employeeDetails.lastName}_cv`;
    let pdfWindow = window.open("", "_blank", windowName);
    pdfWindow.document.write(
      "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
        encodeURI(nspDetails) +
        "'></iframe>"
    );
  };

  const handlePolicies = () => {
    let windowName = `${employeeDetails.firstName}_${employeeDetails.lastName}_cv`;
    let pdfWindow = window.open("", "_blank", windowName);
    pdfWindow.document.write(
      "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
        encodeURI(policiesDetails) +
        "'></iframe>"
    );
  };

  const seeLetters = (link) => {
    let windowName = `${employeeDetails.firstName}_${employeeDetails.lastName}_letter`;
    let pdfWindow = window.open("", "_blank", windowName);
    pdfWindow.document.write(
      `<embed src=${link} type="application/pdf" width="100%" height="100%" ></embed> `
    );
  };

  return (
    <section className="employee_personal">
      <h4>Documents</h4>
      <div className="personal_row">
        <div className="personal_column">
          <div className="id_row">
            {nspDetails != "" ? (
              <div className="id_column" onClick={handleNsp}>
                <DescriptionIcon color="error" />
                <small>NSP</small>
              </div>
            ) : (
              <strong>No NSP details to show</strong>
            )}
            {policiesDetails != "" ? (
              <div className="id_column" onClick={handlePolicies}>
                <DescriptionIcon color="error" />
                <small>Policies</small>
              </div>
            ) : (
              <strong>No policies to show</strong>
            )}
            {employeeDetails.contractData ? (
              <div className="id_column" onClick={handleContract}>
                <DescriptionIcon color="primary" />
                <small>Contract</small>
              </div>
            ) : (
              <strong>No Contract to show</strong>
            )}
          </div>
        </div>
      </div>
      {letters.length > 0 ? (
        <section className="letters_table">
          <h4>letters</h4>
          <table id="letters_table">
            <tbody>
              {letters.map((letter, index) => {
                let letterArray = letter.letters;
                return (
                  <tr key={index}>
                    <td>{letter.name}</td>
                    {letterArray.map((indvLetter) => {
                      return (
                        <td>
                          <Tooltip title={indvLetter.key}>
                            <DescriptionIcon
                              color="primary"
                              onClick={() => seeLetters(indvLetter.link)}
                            />
                          </Tooltip>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </section>
      ) : null}
    </section>
  );
}
