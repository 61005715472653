import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import "../onboarding.scss";
import moment from "moment";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import EditIcon from "@mui/icons-material/Edit";
import {
  getCompanies,
  getInterviewDetails,
  updateInterview,
  deleteInterview,
} from "../../../../utils/apiUtils";
import { decryptData, encryptData } from "../../../../utils/cryptoUtils";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CancelInterview from "./modals/CancelInterview";

export default function RescheduleInterview() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [interviewDetails, setInterviewDetails] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [hrUser, setHrUser] = useState("")
  const [email, setEmail] = useState("");
  const [site, setSite] = useState("");
  const [time, setTime] = useState("");
  const [date, setDate] = useState("");
  const [hour, setHour] = useState("");
  const [mins, setMins] = useState("");
  const [editedEmail, setEditedEmail] = useState("");
  const [editedSite, setEditedSite] = useState("");
  const [editedTime, setEditedTime] = useState("");
  const [openEditingEmail, setOpenEditingEmail] = useState(false);
  const [openEditingSite, setOpenEditingSite] = useState(false);
  const [openEditingTime, setOpenEditingTime] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [reload, setReload] = useState(false);
  const [saved, setSaved] = useState(false);
  const [token, setToken] = useState("");

  //cancel modal
  const [openCancel, setOpenCancel] = useState(false);
  const handleOpenCancel = () => setOpenCancel(true);
  const handleCloseCancel = () => setOpenCancel(false);

  const findSiteNameById = (companyId) => {
    const company = companies.find(
      (company) => company.companyId === companyId
    );
    return company ? company.siteName : "";
  };

  const config = {
    headers: { "Content-Type": "application/json", "x-auth-token": token },
  };

  useEffect(() => {
    let token = localStorage.getItem("user");
    let getToken = JSON.parse(token);
    let final = getToken.token;
    setToken(final);
    setHrUser(getToken.user)

    setSaved(false);
    getInterviewDetails(id)
      .then((data) => {
        console.log(data);
        if (data) {
          if (data.interviewComplete === true) {
            setSaved(true);
          }
          var fullName = "";
          if (!data.fullName || data.fullName == null) {
            var firstName = decryptData(data.firstName, process.env.REACT_APP_NS_ENC)
            var lastName = decryptData(data.lastName, process.env.REACT_APP_NS_ENC)
            fullName = `${firstName} ${lastName}`
          } else {
            fullName = decryptData(data.fullName, process.env.REACT_APP_NS_ENC)
          }

          const decryptedData = {
            ...data,
            firstName: decryptData(data.firstName, process.env.REACT_APP_NS_ENC),
            lastName: decryptData(data.lastName, process.env.REACT_APP_NS_ENC),
            fullName: fullName,
            email: decryptData(data.email, process.env.REACT_APP_NS_ENC),
            date: moment(data.date).format("YYYY-MM-DD HH:mm"),
            note: data.note,
            sucess: data.success,
            reason: data.rejectionReason,
            questions: data.questions,
          };
          setEmail(decryptedData.email);
          setSite(findSiteNameById(decryptedData.companyId));
          setTime(decryptedData.date);
          setInterviewDetails(decryptedData);
        }
      })
      .catch((err) => {
        console.error(err);
      });

    if (companies.length === 0) {
      getCompanies(final)
        .then((data) => {
          setCompanies(data);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [id, companies.length, reload]);

  const handleCancelInterview = async () => {
    try {
      await deleteInterview(interviewDetails.applicantId, token);
      setMessage("Interview successfully canceled!");
      setSeverity("success");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
      setReload((prevReload) => !prevReload);
      navigate("/interview-calendar/schedule");
    } catch (error) {
      console.error("Error canceling interview:", error);
      setMessage("An error occurred while canceling the interview.");
      setSeverity("error");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  };
  setTimeout(() => {
    setShowAlert(false);
  }, 3000);

  const handleEditEmail = (mail) => {
    interviewDetails.email = mail.toLowerCase();
  };

  const handleReschedule = async (updatedFields) => {
    let regex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    const encryptedFirstName = encryptData(
      interviewDetails.firstName,
      process.env.REACT_APP_NS_ENC
    );
    const encryptedLastName = encryptData(
      interviewDetails.lastName,
      process.env.REACT_APP_NS_ENC
    );
    const encryptedFullName = encryptData(
      interviewDetails.fullName,
      process.env.REACT_APP_NS_ENC
    );
    const encryptedEmail = encryptData(
      interviewDetails.email,
      process.env.REACT_APP_NS_ENC
    );
    const companyId = companies.find((company) => {
      return (
        company.companyId === parseInt(updatedFields.site) ||
        company.siteName === updatedFields.site
      );
    }).companyId;

    if (Object.values(updatedFields).some((value) => value.trim() === "")) {
      setMessage("Please make sure all fields are filled in correctly.");
      setSeverity("warning");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    } else if (!interviewDetails.email.toLowerCase().match(regex)) {
      setMessage("Email has the wrong format, please check it and try again");
      setSeverity("warning");
      setShowAlert(true);
      setTimeout(() => {
        setMessage("");
        setSeverity("");
        setShowAlert(false);
      }, 3000);
    } else {
      let interviewTime;
      if (date === "") {
        interviewTime = time;
      } else {
        interviewTime = `${date}T${hour}:${mins}`;
      }
      setEmail(interviewDetails.email);
      const updatedSiteName = companies.find((company) => {
        return (
          company.companyId === parseInt(updatedFields.site) ||
          company.siteName === updatedFields.site
        );
      }).siteName;

      setSite(updatedSiteName);

      const encryptedData = {
        id: Number(id),
        firstName: encryptedFirstName,
        lastName: encryptedLastName,
        fullName: encryptedFullName,
        email: encryptedEmail,
        date: interviewTime,
        companyId: companyId,
        user:hrUser
      };

      try {
        const response = await updateInterview(encryptedData, token);
        if (response) {
          setEmail(updatedFields.email);
          setTime(updatedFields.time);
          setSite(findSiteNameById(parseInt(updatedFields.site)));
          setMessage("Interview successfully rescheduled!");
          setSeverity("success");
          setShowAlert(true);
          setOpenEditingEmail(false);
          setOpenEditingSite(false);
          setOpenEditingTime(false);
          setTimeout(() => {
            setShowAlert(false);
            setReload(!reload);
          }, 3000);
          navigate("/interview-calendar");
          setReload((prevReload) => !prevReload);
        } else {
          setMessage("An error occurred while rescheduling the interview.");
          setSeverity("error");
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
          }, 3000);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.status === 400 &&
          error.response.data === "Interview Collides with another Intervew"
        ) {
          setMessage(
            "Interview cannot be scheduled within 30 minutes of another interview"
          );
          setSeverity("warning");
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
            document.getElementById("interview_btn").style.display = "flex";
          }, 3000);
          navigate("/interview-calendar");
        } else {
          console.error("Error rescheduling interview:", error);
          setMessage("An error occurred while rescheduling the interview.");
          setSeverity("error");
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
          }, 3000);
        }
      }
    }
  };

  const handleEditSite = (site) => {
    if (site == interviewDetails.companyId) {
      setMessage("You cannot select the same site for re-schedule");
      setSeverity("warning");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    } else setEditedSite(site);
  };

  const handleEditTime = (newTime) => {
    if (newTime == time) {
      setMessage("You cannot select the same time for re-schedule");
      setSeverity("warning");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    } else {
      setDate(newTime);
    }
  };

  const handleEditNote = (note) => {
    interviewDetails.note = note;
  };

  const handleView = () => {
    window.open(
      `${process.env.REACT_APP_INTERVIEW_LINK}/interview/${id}`,
      "_blank"
    );
  };

  return (
    <div className="schedule__form">
      {interviewDetails ? (
        <>
          {saved ? (
            <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
              <h1 style={{ textAlign: "center" }}>
                This interview has taken place
              </h1>
              <div className="edit-container">
                <Typography sx={{ fontWeight: "bold" }}> Full Name:</Typography>
                <Typography>
                  {interviewDetails && interviewDetails.fullName}
                </Typography>{" "}
              </div>
              <div className="edit-container">
                <Typography sx={{ fontWeight: "bold" }}>Site:</Typography>{" "}
                <Typography>{site}</Typography>
              </div>
              <div className="edit-container">
                <Typography sx={{ fontWeight: "bold" }}>
                  Date and time:
                </Typography>
                <Typography> {time}</Typography>
              </div>
              <div className="edit-container">
                <Typography sx={{ fontWeight: "bold" }}>
                  Interview success:
                </Typography>
                <Typography>
                  {interviewDetails.success ? <>Yes</> : <>No</>}
                </Typography>
              </div>
              {!interviewDetails.sucess ? (
                <div className="edit-container">
                  <Typography sx={{ fontWeight: "bold" }}>
                    Reason for rejection:
                  </Typography>
                  <Typography> {interviewDetails.reason}</Typography>
                </div>
              ) : null}

              {interviewDetails.questions != null ? (
                <Button variant="outlined" onClick={handleView}>
                  Review Interview
                </Button>
              ) : null}
            </Box>
          ) : (
            <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
              <h1>Reschedule Interview</h1>
              <div className="edit-container">
                <Typography sx={{ fontWeight: "bold" }}> Full Name:</Typography>
                <Typography>
                  {interviewDetails && interviewDetails.fullName}
                </Typography>{" "}
              </div>
              <div className="edit-container">
                <Typography sx={{ fontWeight: "bold" }}>Email: </Typography>
                <EditIcon
                  sx={{ ml: 1 }}
                  color="primary"
                  onClick={() => setOpenEditingEmail(!openEditingEmail)}
                />{" "}
                <Typography>{email}</Typography>{" "}
              </div>
              {openEditingEmail && (
                <div>
                  <input
                    type="email"
                    onChange={(e) => handleEditEmail(e.target.value)}
                  />
                </div>
              )}
              <div className="edit-container">
                <Typography sx={{ fontWeight: "bold" }}>Site:</Typography>{" "}
                <Typography>{site}</Typography>
                <EditIcon
                  sx={{ ml: 1 }}
                  color="primary"
                  onClick={() => setOpenEditingSite(!openEditingSite)}
                />
              </div>
              {openEditingSite && (
                <div>
                  <select
                    value={editedSite || site}
                    onChange={(e) => handleEditSite(e.target.value)}
                  >
                    <option value="">--Please select one--</option>
                    {companies.map(function (company, idx) {
                      return (
                        <option
                          value={company.companyId}
                          key={company.companyId}
                        >
                          {company.companyName}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}
              <div className="edit-container">
                <Typography sx={{ fontWeight: "bold" }}> Position:</Typography>
                <Typography>
                  {interviewDetails && interviewDetails.roleName}
                </Typography>{" "}
              </div>
              <div className="edit-container">
                <Typography sx={{ fontWeight: "bold" }}>Time:</Typography>
                <Typography> {time}</Typography>
                <EditIcon
                  sx={{ ml: 1 }}
                  color="primary"
                  onClick={() => setOpenEditingTime(!openEditingTime)}
                />
              </div>
              {openEditingTime && (
                <div className="schedule_row">
                  <input
                    type="date"
                    onChange={(e) => handleEditTime(e.target.value)}
                    id="interview_day"
                  />
                  <select onChange={(e) => setHour(e.target.value)}>
                    <option>HH</option>
                    <option>08</option>
                    <option>09</option>
                    <option>10</option>
                    <option>11</option>
                    <option>12</option>
                    <option>13</option>
                    <option>14</option>
                    <option>15</option>
                    <option>16</option>
                    <option>17</option>
                    <option>18</option>
                    <option>19</option>
                    <option>20</option>
                  </select>
                  <select onChange={(e) => setMins(e.target.value)}>
                    <option>MM</option>
                    <option>00</option>
                    <option>15</option>
                    <option>30</option>
                    <option>45</option>
                  </select>
                </div>
              )}
              {interviewDetails.note ? (
                <div className="edit-container">
                  <Typography sx={{ fontWeight: "bold" }}> Note:</Typography>
                  <Typography>{interviewDetails.note}</Typography>
                </div>
              ) : (
                <section>
                  <label>Leave a note for the manager</label>
                  <textarea onChange={(e) => handleEditNote(e.target.value)} />
                </section>
              )}
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  const updatedFields = {
                    email: editedEmail.trim() !== "" ? editedEmail : email,
                    time: editedTime.trim() !== "" ? editedTime : time,
                    site: editedSite.trim() !== "" ? editedSite : site,
                  };
                  handleReschedule(updatedFields);
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Box>
                    <Button
                      variant="contained"
                      color="success"
                      id="interview_btn"
                      type="submit"
                      sx={{ mt: 2, mr: 2 }}
                      disabled={
                        editedEmail === "" && editedSite === "" && time === ""
                      }
                    >
                      Reschedule
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      id="interview_btn"
                      type="button"
                      onClick={handleOpenCancel}
                      sx={{ mt: 2 }}
                    >
                      Cancel
                    </Button>
                  </Box>
                  <Button
                    color="primary"
                    id="interview_btn"
                    type="button"
                    onClick={() => navigate("/interview-calendar/schedule")}
                    sx={{ mt: 2 }}
                  >
                    Schedule new interview
                  </Button>
                </Box>
                {showAlert ? (
                  <Alert severity={severity} sx={{ mt: "1%" }}>
                    {message}
                  </Alert>
                ) : (
                  <></>
                )}
              </form>
            </Box>
          )}
        </>
      ) : (
        <p>Loading interview details...</p>
      )}

      <Modal
        open={openCancel}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CancelInterview
          close={handleCloseCancel}
          handleCancelInterview={handleCancelInterview}
        />
      </Modal>
    </div>
  );
}
