import { useState, useMemo, useEffect } from "react";
import EmailIcon from "@mui/icons-material/Email";
import ReportOffIcon from "@mui/icons-material/ReportOff";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import { IconButton, Modal } from "@mui/material";
import DiscrepancyLetter from "./modals/DiscrepancyLetter";
import ConcernLetter from "./modals/ConcernLetter";
import axios from "axios";
import Tooltip from "@mui/material/Tooltip";
import moment from "moment";

export default function IndvLetter(props) {
    const discrepancy = props.discrepancy;
    const url = "https://nitropos-backend.nitrosolutions.co.uk";
    const [background, setBackground] = useState("");
    const [discrep, setDiscrep] = useState(null);
    const [discrepSent, setDiscrepSent] = useState("");
    const [loc, setLoc] = useState(null);
    const [locSent, setLocSent] = useState("");

    const total = useMemo(() => {
        let localTotal = 0;
        const discreps = props.discrepancy.discrepancies;
        // for loop through the discrepancy charges
        for (let d = 0; d < discreps.length; d++) {
            if (discreps[d].currentCharge != null) {
                localTotal += parseFloat(discreps[d].currentCharge.amount);
            }
        }
        return localTotal;
    }, [props.discrepancy.currentCharges]);

    const letters = props.discrepancy.letters;

    //   modals
    const [openLetter, setOpenLetter] = useState(false);
    const handleOpenLetter = () => setOpenLetter(true);
    const handleCloseLetter = () => {
        setOpenLetter(false);
        props.rel();
    };
    const [openConcern, setOpenConcern] = useState(false);
    const handleOpenConcern = () => setOpenConcern(true);
    const handleCloseConcern = () => setOpenConcern(false);

    useEffect(() => {
        // console.log(props.discrepancy.letters)
        const localDiscreps = props.discrepancy.discrepancies;
        const correspondance = props.discrepancy.letters;

        for (let d = 0; d < localDiscreps.length; d++) {
            if (
                localDiscreps[d].currentCharge === null &&
                localDiscreps[d].total == localDiscreps[d].totalLeft
            ) {
                setBackground("#d90d1e");
            }
        }

        for (let l = 0; l < correspondance.length; l++) {
            if (correspondance[l].sentTime !== null) {
                if (correspondance[l].type === "Discrepancy") {
                    setDiscrep(true);
                    setDiscrepSent(correspondance[l].sentTime);
                }
                if (correspondance[l].type === "Letter of Concern") {
                    setLoc(true);
                    setLocSent(correspondance[l].sentTime);
                }
            }
        }
    }, []);

    const handleSendConcern = () => {
        // on then props.rel()
    };

    return (
        <>
            <tr
                key={discrepancy.eposStaffId}
                style={{ backgroundColor: background }}
            >
                <td>{discrepancy.name}</td>
                <td>£{total.toFixed(2)}</td>
                <td>
                    <Tooltip
                        title={
                            discrep != null
                                ? `Email Sent @ ${moment(discrepSent).format(
                                    "DD-MM-YYYY HH:mm"
                                )}`
                                : "Not Sent"
                        }
                    >
                        <IconButton onClick={handleOpenLetter}>
                            <EmailIcon
                                color={discrep != null ? "success" : "primary"}
                            />
                        </IconButton>
                    </Tooltip>
                </td>
                {total < 20 ? (
                    <td>
                        <ReportOffIcon />
                    </td>
                ) : (
                    <Tooltip
                        title={
                            loc != null
                                ? `Email Sent @ ${moment(locSent).format(
                                    "DD-MM-YYYY HH:mm"
                                )}`
                                : "Not Sent"
                        }
                    >
                        <IconButton onClick={handleOpenConcern}>
                            <NewReleasesIcon
                                color={loc != null ? "success" : "warning"}
                            />
                        </IconButton>
                    </Tooltip>
                )}
            </tr>
            <Modal
                open={openLetter}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <DiscrepancyLetter
                    close={handleCloseLetter}
                    name={discrepancy.name}
                    total={total.toFixed(2)}
                    charges={props.discrepancy.discrepancies}
                    rel={props.rel}
                    epos={discrepancy.eposStaffId}
                    month={props.month}
                    sentTime={discrepSent}
                />
            </Modal>
            <Modal
                open={openConcern}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <ConcernLetter
                    close={handleCloseConcern}
                    name={discrepancy.name}
                    total={total.toFixed(2)}
                    month={props.month}
                    epos={discrepancy.eposStaffId}
                    sentTime={locSent}
                    rel={props.rel}
                />
            </Modal>
        </>
    );
}
