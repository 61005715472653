import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import moment from "moment";
import { getLeaveTypes } from "../../../../../utils/apiUtils";
import axios from "axios";
import Divider from "@mui/joy/Divider";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60vw",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "1rem",
  boxShadow: 24,
  p: 4,
  maxHeight: "90vh",
  overflowY: "scroll",
};

export default function ModifyLeave(props) {
  const site = props.site
  const [reason, setReason] = useState("");
  const [leaveTypes, setLeaveTypes] = useState([]);
  const [selectedLeaveType, setSelectedLeaveType] = useState(0);
  const [date, setDate] = useState("");
  const [lastDay, setLastDay] = useState("");
  const [totalDays, setTotalDays] = useState("");
  const [disable, setDisable] = useState(false);
  const [user, setUser] = useState("");
  const [token, setToken] = useState("");
  const [holidayId, setHolidayId] = useState(0);
  const [halfDay, setHalfDay] = useState(false);
  const url = process.env.REACT_APP_API_URL;
  const [holArray, setHolArray] = useState([]);
  const [cancelArray, setCancelArray] = useState([]);
  const config = { headers: { "x-auth-token": token } };

  //alert
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");

  useEffect(() => {
    // console.log(props.holidays);
    let user = localStorage.getItem("user");
    let parseU = JSON.parse(user);
    setUser(parseU.user);
    let localToken = parseU.token;
    setToken(localToken);
    const config = {
      headers: { "x-auth-token": localToken },
    };

    setDate(
      `${props.year}-${moment().month(props.month).format("MM")}-${moment(
        props.day,
        "D"
      ).format("DD")}`
    );

    if (props.leave != "") {
      let date = `${props.year}-${moment()
        .month(props.month)
        .format("MM")}-${moment(props.day, "D").format("DD")}`;

      let hols = props.holidays;
      for (let h = 0; h < hols.length; h++) {
        if (
          moment(date).isBetween(hols[h].startDate, hols[h].endDate) ||
          hols[h].startDate == date ||
          hols[h].endDate == date
        ) {
          setHolidayId(hols[h].holidayId);
          console.log(hols[h].holidayId);

          let firstDay = moment(hols[h].startDate);
          let lastDay = moment(hols[h].endDate);

          if (hols[h].startDate === hols[h].endDate) {
            console.log("only one day");
          } else {
            let dates = [];

            dates.push(hols[h].startDate);

            while (firstDay.add(1, "days").diff(lastDay) < 0) {
              let newDay = firstDay.clone().toDate();
              dates.push(moment(newDay).format("YYYY-MM-DD"));
            }

            dates.push(hols[h].endDate);
            setHolArray(dates);
          }
        }
      }
    }
    try {
      getLeaveTypes(config)
        .then((leaves) => {
          setLeaveTypes(leaves);
        })
        .catch((err) => {
          console.log(`Could not get leave types: ${err}`);
        });
    } catch (err) {
      console.log(`Could not get leave types: ${err}`);
    }
  }, []);

  const handledays = (last) => {
    let firstDay = moment(date);
    let lastDay = moment(last);
    let totalDays;
    if (site != 0 && site != 24195){
      totalDays = lastDay.diff(firstDay, "days") + 1;
    } else{
      // if HO or Maintenance ignores weekends on total taken days
      let dates = [];
      dates.push(firstDay)
      while (firstDay.add(1, "days").diff(lastDay) < 0) {
        let newDay = firstDay.clone().toDate();
        dates.push(newDay);
      }
      dates.push(lastDay)
      let count = dates.length;
      for (let d = 0; d < dates.length; d++){
        if (moment(dates[d]).day() === 0 || moment(dates[d]).day() === 6){
          count -= 1;
        } 
      }
      totalDays = count;
    }
    setLastDay(last);
    setTotalDays(totalDays);
  };

  const handleHalfDay = (half) => {
    let firstDay = moment(date);
    let last = moment(lastDay);
    let totalDays = last.diff(firstDay, "days");
    let total = totalDays + 1;
    if (half === "false") {
      setHalfDay(false);
    } else if (half === "true") {
      setHalfDay(true);
      total -= 0.5;
    }
    setTotalDays(total);
  };

  const addLeave = () => {
    if (selectedLeaveType === 0) {
      setMessage("Please select leave type");
      setSeverity("warning");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 2500);
    } else if (lastDay === "") {
      setMessage("Please select last day");
      setSeverity("warning");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 2500);
    } else if (reason === "") {
      setMessage("Please leave a reason for adding this leave");
      setSeverity("warning");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 2500);
    } else {
      setDisable(true);
      const data = {
        user: user,
        employeeDetailsId: props.employee,
        leaveType: selectedLeaveType,
        startDate: date,
        endDate: lastDay,
        days: totalDays,
        reason: reason,
      };
      axios
        .post(`${url}/holiday/submit/manual/new`, data, config)
        .then((res) => {
          setMessage("Holiday submitted");
          setSeverity("success");
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
            setDisable(false);
            props.close();
            props.rel();
          }, 3000);
        })
        .catch((err) => {
          console.log(`Could not add a holiday: ${err}`);
          setMessage(`Could not submit the request: ${err}`);
          setSeverity("error");
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
            setDisable(false);
          }, 3000);
        });
    }
  };

  const cancelLeave = () => {
    if (holidayId === 0) {
      setMessage("Could not find the holiday, please close and open again");
      setSeverity("warning");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 2500);
    } else {
      const data = {
        user: user,
        holidayId: holidayId,
      };
      axios
        .post(`${url}/holiday/admin/cancel/new`, data, config)
        .then((res) => {
          setMessage("Holiday cancelled");
          setSeverity("success");
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
            setDisable(false);
            props.close();
            props.rel();
          }, 3000);
        })
        .catch((err) => {
          console.log(`Could not cancel the holiday: ${err}`);
          setMessage(`Could not cancel the holiday: ${err}`);
          setSeverity("error");
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
            setDisable(false);
          }, 3000);
        });
    }
  };

  const handleCancelArray = (day) => {
    let localArray = cancelArray;

    let index = localArray.findIndex((i) => i === day);

    if (index < 0) {
      localArray.push(day);
    } else {
      localArray.splice(index, 1);
    }

    setCancelArray(localArray);
  };

  const cancelIndvHol = () => {
    setDisable(true);
    if (cancelArray.length === 0) {
      setMessage("Please select some days");
      setSeverity("warning");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
        setDisable(false);
      }, 3000);
    } else {
      const data = {
        holidayId: holidayId,
        cancelArray: cancelArray,
        user: user,
      };
      console.log(data);
      axios
        .post(`${url}/holiday/admin/cancel/day`, data, config)
        .then((res) => {
          setMessage("Holiday cancelled");
          setSeverity("success");
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
            setDisable(false);
            props.close();
            props.rel();
          }, 3000);
        })
        .catch((err) => {
          console.log(`Could not cancel the holiday: ${err}`);
          setMessage(`Could not cancel the holiday: ${err}`);
          setSeverity("error");
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
            setDisable(false);
          }, 3000);
        });
    }
  };

  return (
    <Box sx={style}>
      {props.leave != "" ? (
        <>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ textAlign: "center" }}
          >
            Cancel <strong>{props.leave}</strong> for:{" "}
            <strong>{props.staff}</strong>
          </Typography>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ textAlign: "center" }}
          >
            Date:
            <strong>{new Date(date).toLocaleDateString("en-GB")}</strong>
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              mt: 2,
              gap: 2,
              justifyContent: "center",
            }}
          >
            {holArray.length > 0 ? (
              <Box>
                <Typography sx={{ textAlign: "center" }} variant="h6">
                  This holiday has more than one day
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "flex-start",
                  }}
                >
                  <Box
                    sx={{ display: "flex", flexDirection: "column", gap: 1 }}
                  >
                    <Button
                      color="success"
                      variant="contained"
                      onClick={cancelLeave}
                      disabled={disable}
                    >
                      Cancel all
                    </Button>
                  </Box>
                  <Divider orientation="vertical" />
                  <Box>
                    <Typography variant="h6">Select days</Typography>
                    <FormGroup>
                      {holArray.map((hol, index) => {
                        return (
                          <FormControlLabel
                            control={<Checkbox />}
                            label={new Date(hol).toLocaleDateString("en-GB")}
                            key={index + 10}
                            value={hol}
                            onChange={(e) => handleCancelArray(e.target.value)}
                          />
                        );
                      })}
                    </FormGroup>
                    <Button
                      variant="contained"
                      color="success"
                      onClick={cancelIndvHol}
                      disabled={disable}
                    >
                      Cancel Days
                    </Button>
                  </Box>
                </Box>
                {showAlert ? (
                  <Alert severity={severity} sx={{ margin: "1% auto" }}>
                    {message}
                  </Alert>
                ) : (
                  <Button
                    color="error"
                    variant="outlined"
                    onClick={props.close}
                    sx={{ margin: "1% auto" }}
                  >
                    Close
                  </Button>
                )}
              </Box>
            ) : (
              <>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                  <Typography variant="h4">
                    Confirm you want to cancel the above holiday
                  </Typography>
                </Box>
                {showAlert ? (
                  <Alert severity={severity}>{message}</Alert>
                ) : (
                  <Box
                    sx={{ display: "flex", justifyContent: "center", gap: 2 }}
                  >
                    <Button
                      color="error"
                      variant="outlined"
                      onClick={props.close}
                    >
                      Close
                    </Button>
                    <Button
                      color="success"
                      variant="contained"
                      onClick={cancelLeave}
                    >
                      Confirm
                    </Button>
                  </Box>
                )}
              </>
            )}
          </Box>
        </>
      ) : (
        <>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ textAlign: "center" }}
          >
            Add leave for: <strong>{props.staff}</strong>
          </Typography>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ textAlign: "center" }}
          >
            First date:{" "}
            <strong>{new Date(date).toLocaleDateString("en-GB")}</strong>
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              mt: 2,
              gap: 2,
              justifyContent: "center",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              <label>Select leave:</label>
              <select onChange={(e) => setSelectedLeaveType(e.target.value)}>
                <option>--Please select one--</option>
                {leaveTypes.map((leave, index) => {
                  return (
                    <option key={index} value={leave.leaveId}>
                      {leave.name}
                    </option>
                  );
                })}
              </select>
              <label>Select last day:</label>
              <input
                type="date"
                min={date}
                onChange={(e) => handledays(e.target.value)}
                id="date_select"
              />
              <label>Half day?</label>
              <select onChange={(e) => handleHalfDay(e.target.value)}>
                <option>--Please Select One--</option>
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </select>
              <label>Leave reason:</label>
              <textarea
                cols={15}
                rows={5}
                maxLength="200"
                onChange={(e) => setReason(e.target.value)}
              />
              {totalDays != "" ? (
                <>
                  <label>
                    Last day:{" "}
                    <strong>
                      {new Date(lastDay).toLocaleDateString("en-GB")}
                    </strong>
                  </label>
                  <label>
                    Total days: <strong>{totalDays}</strong>
                  </label>
                </>
              ) : null}
            </Box>
            {showAlert ? (
              <Alert severity={severity}>{message}</Alert>
            ) : (
              <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
                <Button color="error" variant="outlined" onClick={props.close}>
                  cancel
                </Button>
                <Button
                  color="success"
                  variant="contained"
                  disabled={disable}
                  onClick={addLeave}
                >
                  confirm
                </Button>
              </Box>
            )}
          </Box>
        </>
      )}
    </Box>
  );
}
