//React
import { useState, useEffect } from "react";
import axios from "axios";

import { decryptData } from "../rotasV2/cryptoUtils";
//Components
import Loader from "../../../animations/Loader";
import IndvWeek from "./IndvWeek";
//MUI
import { Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Alert } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

//Utils
import { getCompanies } from "../../../../utils/apiUtils";
//Styling
import "./payroll.scss";

export default function Payroll() {
    const url = "https://nitropos-backend.nitrosolutions.co.uk";
    // const hrUrl = "http://10.0.0.34:4030";
    const hrUrl = process.env.REACT_APP_API_URL;
    const [employees, setEmployees] = useState([]);
    const [fixedEmployees, setFixedEmployees] = useState([]);
    const [sites, setSites] = useState([]);
    const [selectedSite, setSelectedSite] = useState(0);
    const [selectedMonth, setSelectedMonth] = useState("");
    const [searchedEmployee, setSearchedEmployee] = useState("");
    const [weeks, setWeeks] = useState([]);
    const [expandedAccordions, setExpandedAccordions] = useState([]);
    const [fixedIndexes, setFixedIndexes] = useState([]);
    //Loader & Alert
    const [loader, setLoader] = useState(false);
    const [alert, setAlert] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("");
    const [disable, setDisable] = useState(false);

    useEffect(() => {
        async function fetchSites() {
            getCompanies().then((res) => {
                setSites(res);
            });
        }
        fetchSites();
    }, []);

    const handleAlert = (message, severity, duration = 4000) => {
        setDisable(true);
        setMessage(message);
        setSeverity(severity);
        setAlert(true);
        setTimeout(() => {
            setAlert(false);
            setSeverity("");
            setMessage("");
            setDisable(false);
        }, duration);
    };

    const handleSearchEmployee = (name) => {
        let lowerCaseName = name.toLowerCase();

        if (name === "") {
            setEmployees(fixedEmployees);
        } else {
            let filteredEmployees = fixedEmployees.filter((employee, i) => {
                let employeeFullName =
                    employee.firstName.toLowerCase() +
                    " " +
                    employee.lastName.toLowerCase();
                let lowerCaseEmployee = employee.lastName.toLowerCase();
                let lowerCaseEmployeeFirst = employee.firstName.toLowerCase();
                return (
                    lowerCaseEmployee.includes(lowerCaseName) ||
                    lowerCaseEmployeeFirst.includes(lowerCaseName) ||
                    employeeFullName.includes(lowerCaseName)
                );
            });

            setEmployees(filteredEmployees);
        }
    };

    const handleSearchPayroll = () => {
        let data = {
            companyId: selectedSite,
            date: selectedMonth + "-01",
        };

        if (selectedSite === 0) {
            handleAlert("You must select a site", "warning", 4000);
        } else if (!selectedMonth) {
            handleAlert("You must select a month", "warning", 4000);
        } else {
            setLoader(true);
            axios
                .post(`${hrUrl}/payroll/month`, data)
                .then((res) => {
                    setWeeks(res.data.weeks);
                    let decryptEmployees = res.data.shifts.map(
                        (employee, i) => {
                            return {
                                ...employee,
                                firstName: decryptData(
                                    employee.firstName,
                                    process.env.REACT_APP_EMP_KEY
                                ),
                                lastName: decryptData(
                                    employee.lastName,
                                    process.env.REACT_APP_EMP_KEY
                                ),
                            };
                        }
                    );
                    //Sort the employees in last name alphabetical order
                    let sortedEmployees = decryptEmployees.sort((a, b) => {
                        if (a.lastName < b.lastName) {
                            return -1;
                        }
                        if (a.lastName > b.lastName) {
                            return 1;
                        }
                        return 0;
                    });

                    setEmployees(sortedEmployees);
                    setFixedEmployees(sortedEmployees);
                    let employeeIndexes = [];
                    for (let i = 0; i < sortedEmployees.length; i++) {
                        employeeIndexes.push(i);
                    }
                    setExpandedAccordions(employeeIndexes);
                    setFixedIndexes(employeeIndexes);
                    setLoader(false);
                })
                .catch((err) => {
                    setLoader(false);
                    console.log(err);
                    handleAlert(
                        "Error - Something went wrong, please try again",
                        "error",
                        4000
                    );
                });
        }
    };

    //
    const accordionClicked = (index) => {
        if (expandedAccordions.includes(index))
            setExpandedAccordions(
                expandedAccordions.filter((number) => number !== index)
            );
        else setExpandedAccordions([...expandedAccordions, index]);
    };

    const collapseAll = () => {
        setExpandedAccordions([]);
    };

    const expandAll = () => {
        setExpandedAccordions(fixedIndexes);
    };
    return (
        <section className="payroll">
            <h1 className="heading-master">Payroll</h1>
            <div className="search_area">
                <div className="search">
                    <label>Select a site</label>
                    <select onChange={(e) => setSelectedSite(e.target.value)}>
                        <option value={0}>Select a site</option>
                        {sites.length > 0 &&
                            sites.map((site, i) => {
                                return (
                                    <option key={i} value={site.companyId}>
                                        {site.siteName}
                                    </option>
                                );
                            })}
                    </select>
                </div>
                <div className="search">
                    <label>Select a month</label>
                    <input
                        type="month"
                        onChange={(e) => setSelectedMonth(e.target.value)}
                    />
                </div>
                <div className="search">
                    <Button
                        variant="contained"
                        onClick={handleSearchPayroll}
                        disabled={disable}
                    >
                        <SearchIcon />
                    </Button>
                </div>
            </div>
            <div className="alert_space">
                {alert ? (
                    <Alert severity={severity} className="alert">
                        {message}
                    </Alert>
                ) : null}
            </div>
            {!loader ? (
                fixedEmployees && fixedEmployees.length > 0 ? (
                    <>
                        <section className="search_employees">
                            <div>
                                <label>Search Employee Name</label>
                                <input
                                    onChange={(e) =>
                                        handleSearchEmployee(e.target.value)
                                    }
                                />
                            </div>
                            <div>
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        expandedAccordions.length > 0
                                            ? collapseAll()
                                            : expandAll();
                                    }}
                                >
                                    {expandedAccordions.length > 0
                                        ? "Collapse All"
                                        : "Expand All"}
                                </Button>
                            </div>
                        </section>
                        <section className="employee_list">
                            {employees.length > 0 ? (
                                employees.map((employee, i) => {
                                    return (
                                        <Accordion
                                            key={i}
                                            className="accordian"
                                            onChange={() => accordionClicked(i)}
                                            expanded={expandedAccordions.includes(
                                                i
                                            )}
                                        >
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1-content"
                                                id="panel1-header"
                                                className="accordian_header"
                                            >
                                                {employee.lastName},{" "}
                                                {employee.firstName} -{" "}
                                                {employee.employeeDetailsId}
                                            </AccordionSummary>
                                            <AccordionDetails className="accordian_details">
                                                <section className="header_details">
                                                    <h3>
                                                        Contracted Weekly Hours:{" "}
                                                        <span>
                                                            {
                                                                employee.contractedHours
                                                            }
                                                            Hrs
                                                        </span>
                                                    </h3>
                                                    <h3>
                                                        Total Shift Hours:{" "}
                                                        <span>
                                                            {
                                                                employee.totalShift
                                                            }
                                                            Hrs
                                                        </span>
                                                    </h3>
                                                    <h3>
                                                        Total Actual Hours:{" "}
                                                        <span>
                                                            {
                                                                employee.totalActual
                                                            }
                                                            Hrs
                                                        </span>
                                                    </h3>
                                                </section>
                                                <table className="weeks_table">
                                                    <thead>
                                                        <tr>
                                                            <th></th>
                                                            <th>
                                                                Scheduled Hours
                                                            </th>
                                                            <th>
                                                                Actual Hours
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {weeks.length > 0
                                                            ? weeks.map(
                                                                  (week, i) => {
                                                                      return (
                                                                          <IndvWeek
                                                                              key={
                                                                                  i
                                                                              }
                                                                              week={
                                                                                  week
                                                                              }
                                                                              employee={
                                                                                  employee
                                                                              }
                                                                          />
                                                                      );
                                                                  }
                                                              )
                                                            : null}
                                                    </tbody>
                                                </table>
                                            </AccordionDetails>
                                        </Accordion>
                                    );
                                })
                            ) : (
                                <h1>No Employees Found</h1>
                            )}
                        </section>
                    </>
                ) : (
                    <h1>No Employees Found</h1>
                )
            ) : (
                <Loader />
            )}
        </section>
    );
}
