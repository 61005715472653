import { useState, useEffect } from "react";

export default function Roles(props){

    const [childData, setChildData] = useState([])

    useEffect(()=>{
        setChildData(props.childData)
    },[props])

    return(
        <div className="splitSection">
        <div className="heading">
            <h3>Roles &amp; Sites</h3>
        </div>
        <div className="data">
            {childData.length > 0 ?
                <>
                    {
                        childData.map(function (d, i) {
                            var bgColor; props.child && props.child.hcId == d.hcId ? bgColor = "#64c4e0" : bgColor = ""
                            return (
                                <div className="clickableData" style={{ backgroundColor: bgColor }} onClick={() => props.selectChild(i)}>
                                    <p key={i} >{d.roleName} @ {d.siteName}</p>
                                </div>
                            )
                        })
                    }
                </>
                :
                <p>Select Group</p>
            }
        </div>
    </div>
    )
}