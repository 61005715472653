import {useState, useEffect, useContext} from "react";
import {useNavigate, Outlet} from "react-router-dom";
import "../main.scss";
import Header from "../../header/Header";
import Footer from "../../footer/Footer";
import axios from "axios";
import UserContext from "../../context/UserContext";
import Button from "@mui/material/Button";
import ArticleIcon from "@mui/icons-material/Article";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {styled} from "@mui/material/styles";
import UndoIcon from "@mui/icons-material/Undo";

const notesStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: "1rem",
  height: "40vh",
  overflowY: "scroll",
};

const ColorButton = styled(Button)(({theme}) => ({
  color: "#fff",
  backgroundColor: "#2c53a0",
  "&:hover": {
    backgroundColor: "#fff",
    color: "#2c53a0",
    border: "1px solid #2c53a0",
  },
}));

export default function IndvApplicant() {
  const navigate = useNavigate();
  const [applicantData, setApplicantData] = useState([]);
  const {userData} = useContext(UserContext);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const id = urlParams.get("id");

    //do call to db with ID
  }, []);

  const submitNote = () => {
    alert("Note added");
    handleClose();
  };

  return (
    <>
      {/* {userData.token ? ( */}
      <section className="main">
        <Outlet />
        <Header />
        <section className="applicant__dash">
          <aside className="dash__left">
            <div className="left__details">
              <h5>
                Name:<span> Jane Doe</span>
              </h5>
              <h5>
                Position Applied: <span>CSA</span>
              </h5>
              <h5>
                Site Applied for: <span>Bearsden</span>
              </h5>
              <h5>
                e-mail: <span>john.doe@gmail.com</span>
              </h5>
              <h5>
                Phone: <span>0123456781</span>
              </h5>
            </div>
            <div className="left__details">
              <ColorButton
                variant="contained"
                endIcon={<ArticleIcon />}
                sx={{width: "60%"}}
              >
                See CV
              </ColorButton>
              {/* button will change with the progress */}
              <button className="progress__btn">Reviewed</button>
              {/* button to drop candidate and any point */}
              <button className="dropped__btn">IDIOT</button>
              <ColorButton
                variant="contained"
                endIcon={<UndoIcon />}
                sx={{width: "60%"}}
                onClick={() => navigate("/ats/applicant/")}
              >
                Go Back
              </ColorButton>
            </div>
          </aside>
          <section className="dash__main">
            <div className="progress__bar">
              <div className="progress__container">
                <div className="progress" id="progress"></div>
                <div className="circle active">Applied</div>
                <div className="circle waiting">Reviewed</div>
                <div className="circle ">Phone Screened</div>
                <div className="circle ">Interview Scheduled</div>
              </div>
            </div>
            <div className="dash__notes">
              <h2>Notes</h2>
              <div className="notes">
                <ul>
                  <li>
                    <span>Audit Log:</span> Open By Devs
                  </li>
                  <li>
                    <span>Audit Log:</span> CV Checked By Devs
                  </li>
                  <li>
                    <span>Note added:</span> Decent candidate, needs phone
                    screened
                  </li>
                </ul>
              </div>
              <Tooltip title="Add Note" className="add__note">
                <NoteAddIcon
                  color="primary"
                  fontSize="large"
                  onClick={handleOpen}
                />
              </Tooltip>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={notesStyle}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    ADD A NOTE
                  </Typography>
                  <Typography id="modal-modal-description" sx={{mt: 2}}>
                    <>
                      <textarea
                        cols="118"
                        rows="10"
                        placeholder="Please add your note here..."
                      />
                      <button
                        className="modal__btn"
                        onClick={() => submitNote()}
                      >
                        Send Note
                      </button>
                    </>
                  </Typography>
                </Box>
              </Modal>
            </div>
          </section>
        </section>
        <Footer />
      </section>
      {/* ) : (
        <h1 style={{ margin: "auto" }}>
          You need to log in, please go to <a href="/">login</a>
        </h1>
      )} */}
    </>
  );
}
