import { Outlet, useNavigate, useParams, useLocation } from "react-router-dom";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { useState, useEffect, useCallback } from "react";
import "../onboarding.scss";
import "../../../../../node_modules/react-big-calendar/lib/css/react-big-calendar.css";
import { fetchAllInterviews, getCompanies } from "../../../../utils/apiUtils";
import { decryptData } from "../../../../utils/cryptoUtils";
import CryptoJS from "crypto-js";
import ErrorBoundary from "../../../../utils/ErrorBoundary";
import Footer from "./../../../footer/Footer";
import Header from "./../../../header/Header";
import Box from "@mui/material/Box";
import axios from "axios";
import { SpeedDial, SpeedDialAction } from "@mui/material";
import SquareIcon from "@mui/icons-material/Square";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

//const localizer = momentLocalizer(moment);
const localizer = momentLocalizer(moment)
const url = process.env.REACT_APP_API_URL;


export default function InterviewCalendar({ selectedInterview }) {
  const { id } = useParams();
  const navigate = useNavigate();
  const key = process.env.REACT_APP_NS_ENC;
  const [sites, setSites] = useState([]);
  const [siteName, setSiteName] = useState("All Sites");
  const [interviews, setInterviews] = useState([]);
  const location = useLocation();
  const [reload, setReload] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [token, setToken] = useState("");
  const config = {
    headers: { "Content-Type": "application/json", "x-auth-token": token },
  };



  useEffect(() => {
    let token = localStorage.getItem("user");
    let getToken = JSON.parse(token);
    let final = getToken.token;
    setToken(final);

    if (location.state && location.state.reloadCalendar) {
      setReload((prevReload) => !prevReload);
    }
  }, [location.state]);

  useEffect(() => {
    let token = localStorage.getItem("user");
    let getToken = JSON.parse(token);
    let final = getToken.token;

    const fetchSites = async (final) => {
      try {
        const sitesData = await getCompanies(final);
        setSites(sitesData);
      } catch (err) {
        console.error(err);
      }
    };

    fetchSites(final);
  }, []);

  useEffect(() => {

    if (firstLoad) {
      fetchInterviewData("All Sites");
      setFirstLoad(false);
    } else {
      fetchInterviewData(siteName);
    }
  }, [sites, siteName, id, selectedInterview, reload]);

  useEffect(() => {
    if (selectedInterview) {
      const selectedStartTime = moment(selectedInterview);
      const selectedEndTime = moment(selectedStartTime).add(30, "minutes");
      const selectedEvent = {
        id: "selected-interview",
        title: "Selected Interview",
        start: selectedStartTime.toDate(),
        end: selectedEndTime.toDate(),
      };
      const updatedInterviews = interviews.filter(
        (event) => event.id !== "selected-interview"
      );
      setInterviews([...updatedInterviews, selectedEvent]);
    }
  }, [selectedInterview, interviews]);

  const fetchInterviewData = async (selectedSite) => {
    try {
      if (sites.length > 0) {
        if (selectedSite === "All Sites" || selectedSite === "all") {
          // Fetch interviews for all sites of the manager
          const interviewData = await fetchAllInterviews(token);
          const updatedInterviews = interviewData.map((data) => {
            var fullName = "";
            if (!data.fullName || data.fullName == null) {
              const name = CryptoJS.AES.decrypt(data.firstName, key).toString(CryptoJS.enc.Utf8);
              const surname = CryptoJS.AES.decrypt(data.lastName, key).toString(CryptoJS.enc.Utf8);
              fullName = `${name} ${surname}`
            } else {
              fullName = CryptoJS.AES.decrypt(data.fullName, key).toString(CryptoJS.enc.Utf8);
            }

            const startTime = moment(data.date);
            const endTime = moment(startTime).add(30, "minutes");
            const site = data.siteName;
            return {
              id: data.applicantId,
              fullName,
              title: `${fullName} - ${site} - ${moment(startTime).format("HH:mm")}`,
              start: startTime.toDate(),
              end: endTime.toDate(),
              site: site,
              colour: data.colour
            };
          });
          setInterviews(updatedInterviews);
        } else {
          const siteCompany = sites.find(
            (company) => company.siteName === selectedSite
          );

          if (siteCompany) {
            const companyId = siteCompany.companyId;
            const res = await axios.get(
              `${url}/interview/all/${companyId}`,
              config
            );

            const interviewData = res.data;

            const updatedInterviews = interviewData.map((data) => {
              var fullName = ""
              if (!data.fullName || data.fullName == null) {
                const decryptedFirstName = decryptData(data.firstName, key);
                const decryptedLastName = decryptData(data.lastName, key);
                fullName = `${decryptedFirstName} ${decryptedLastName}`
              } else {
                fullName = decryptData(data.fullName, key);
              }

              const startTime = moment(data.date);
              const endTime = moment(startTime).add(30, "minutes");

              const site = data.siteName;


              return {
                id: data.applicantId,
                title: `${fullName} - ${site} - ${moment(startTime).format("HH:mm")}`,
                start: startTime.toDate(),
                end: endTime.toDate(),
                colour: data.colour
              };
            });
            setInterviews(updatedInterviews);
          }
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleSiteChange = async (event) => {
    const selectedSiteName = event.target.value;
    setSiteName(selectedSiteName);

    if (selectedSiteName === "All Sites") {
      await fetchInterviewData("All Sites");
    } else {
      await fetchInterviewData(selectedSiteName);
    }
  };

  const handleInterview = useCallback((interview) => {
    setTimeout(() => {
      navigate(`/interview-calendar/${interview.id}`);
    }, 250);
  }, []);

  const onDoubleClickEvent = useCallback((interview) => {
    // console.log(interview);
    window.open(
      `${process.env.REACT_APP_INTERVIEW_LINK}/interview/${interview.id}`,
      "_blank"
    );
  }, []);

  return (
    <main className="interview_calendar_main">
      <Header />{" "}
      <Box sx={{ mb: 3 }}>
        {sites.length > 1 && (
          <div className="main_interviews_select">
            <label htmlFor="site-select">Select Site: </label>
            <select
              id="site-select"
              value={siteName}
              onChange={handleSiteChange}
            >
              <option value="all">All Sites</option>
              {sites.map((site) => (
                <option value={site.siteName} key={site.siteName}>
                  {site.siteName}
                </option>
              ))}
            </select>
          </div>
        )}
      </Box>
      <section className="main_interviews">
        <Calendar
          culture='en-GB'
          localizer={localizer}
          eventPropGetter={(event) => {
            const backgroundColor = event.colour;
            return { style: { backgroundColor } }
          }}
          events={interviews}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 600, width: 900 }}
          onSelectEvent={handleInterview}
          onDoubleClickEvent={onDoubleClickEvent}
        />
        <ErrorBoundary>
          <Outlet />
        </ErrorBoundary>
      </section>

      <Box
        sx={{
          height: "50px",
          transform: "translateZ(0px)",
          flexGrow: 1,
          marginTop: "-3%",
          marginRight: "-33%"
        }}
      >
        <SpeedDial
          ariaLabel="Interview Calendar Key"
          sx={{
            position: "absolute",
            bottom: 0,
            right: 0,
          }}
          icon={<HelpOutlineIcon />}
          direction={"right"}
        >
          <SpeedDialAction
            sx={{ marginTop: "30px" }}
            key={"Unsuccessful"}
            icon={
              <SquareIcon
                sx={{
                  borderRadius: "50px",
                  color: "red",
                }}
              />
            }
            tooltipTitle={"Unsuccessful"}
          />
          <SpeedDialAction
            sx={{ marginTop: "30px" }}
            key={"NoShow"}
            icon={
              <SquareIcon
                sx={{
                  borderRadius: "50px",
                  color: "orange",
                }}
              />
            }
            tooltipTitle={"No Show"}
          />
          <SpeedDialAction
            sx={{ marginTop: "30px" }}
            key={"Successful"}
            icon={
              <SquareIcon
                sx={{
                  color: "green",
                  borderRadius: "50px",
                }}
              />
            }
            tooltipTitle={"Successful"}
          />
          <SpeedDialAction
            sx={{ marginTop: "30px" }}
            key={"Saved"}
            icon={
              <SquareIcon
                sx={{
                  borderRadius: "50px",
                  color: "pink",
                }}
              />
            }
            tooltipTitle={"Saved"}
          />
          <SpeedDialAction
            sx={{ marginTop: "30px" }}
            key={"Pending"}
            icon={
              <SquareIcon
                sx={{
                  borderRadius: "50px",
                  color: "dodgerblue",
                }}
              />
            }
            tooltipTitle={"Pending"}
          />
        </SpeedDial>
      </Box>
      <Footer />
    </main>
  );
}
