import Box from "@mui/material/Box";
import { Button, Stack, Alert } from "@mui/material";
import Typography from "@mui/material/Typography";
import logo from "../../../adminTasks/payroll/letters/modals/RT_Logo.png";
import Divider from "@mui/material/Divider";
import html2pdf from "html2pdf.js";
import moment from "moment";
import { useState, useEffect } from "react";
import { getS3Link } from "../../../../../utils/apiUtils";
import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  maxHeight: "90vh",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "1rem",
  boxShadow: 24,
  p: 4,
  overflowY: "scroll",
};

export default function ProbPass(props) {
  const url = process.env.REACT_APP_API_URL;
  const today = moment();
  const [disabled, setDisabled] = useState(false);
  const [token, setToken] = useState("");
  const config = { headers: { "x-auth-token": token } };
  const [probLength, setProbLength] = useState("");
  const [user, setUser] = useState("");
  //   alert
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    let user = localStorage.getItem("user");
    let parseU = JSON.parse(user);
    setToken(parseU.token);
    setUser(parseU.user);
  }, []);

  const getTargetElement = () => document.getElementById("letter");

  const handleSendLetter = async () => {
    if (probLength === "" || probLength === "--Please select one--") {
      setMessage(`Please select length of probation`);
      setSeverity("warning");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
        setDisabled(false);
      }, 3500);
    } else {
      setDisabled(true);

      const data = {
        type: 27,
        employeeDetailsId: props.employee.employeeDetailsId,
        fileName: `${
          props.employee.employeeDetailsId
        }-Email - Probation Pass-${moment().format("YYYY-MM-DD-HH-mm")}.pdf`,
        user:user
      };
      await getS3Link(data, config)
        .then((res) => {
          console.log(res);
          createPdf(res);
        })
        .catch((err) => {
          console.log(`Could not get the S3 link: ${err.response.data}`);
          setMessage(`Could not upload the document, please try again`);
          setSeverity("warning");
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
            setDisabled(false);
          }, 3500);
        });
    }
  };

  const createPdf = async (details) => {
    const element = getTargetElement();
    let opt = {
      html2canvas: { scale: 2 },
      margin: [1.4, 0.7, 1.7, 0.7], //10.5, 1, 26.5, 0 //Top, Left, Bottom, Right
      jsPDF: { unit: "cm", format: "a4", orientation: "portrait" },
    };

    const pdfNew = await html2pdf()
      .from(element)
      .set(opt)
      .output("arraybuffer");
    console.log("------SENDING LETTER------");

    // Upload to S3
    await axios
      .put(details.url, pdfNew)
      .then((res) => {
        let resend = false;
        const data = {
          documentId: details.documentId,
          user: user,
          resend: resend,
        };
        axios
          .post(`${url}/employee/emailS3Documents`, data, config)
          .then((res) => {
            setMessage(`Letter sent`);
            setSeverity("success");
            setShowAlert(true);
            setTimeout(() => {
              setShowAlert(false);
              props.close();
            }, 3500);
          })
          .catch((err) => {
            console.log(`Could not send the email: ${err}`);
            setMessage(
              `Could not send the email to the employee, please try again`
            );
            setSeverity("error");
            setShowAlert(true);
            setTimeout(() => {
              setShowAlert(false);
            }, 3500);
          });
      })
      .catch((err) => {
        setDisabled(false);
        console.log(`Could not upload to S3: ${err.response.data}`);
        setMessage(`Could not upload the document, please try again`);
        setSeverity("error");
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 3500);
      });
  };

  return (
    <Box sx={style}>
      <Typography
        id="modal-modal-title"
        variant="h6"
        component="h2"
        sx={{
          textAlign: "center",
          marginBottom: "20px",
        }}
      >
        Probationary period passed letter
      </Typography>
      <Typography variant="body2">Please select length probation:</Typography>
      <select onChange={(e) => setProbLength(e.target.value)}>
        <option>--Please select one--</option>
        <option>three months</option>
        <option>six months</option>
      </select>
      <Divider sx={{ margin: "1% auto" }} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          justifyContent: "flex-start",
        }}
        id="letter"
      >
        <Box
          sx={{
            display: "flex",
            alignContent: "center",
            justifyContent: "space-between",
            margin: "2% auto",
          }}
        >
          <img src={logo} alt="RT Logo" width="20%" />
          <Typography variant="subtitle1">
            Private &amp; Confidential
          </Typography>
        </Box>
        <Typography variant="h3" sx={{ textAlign: "center" }}>
          {props.company.companyName}
        </Typography>
        <Typography variant="h6" sx={{ textAlign: "center" }}>
          {props.company.addressLine1}, {props.company.city},{" "}
          {props.company.postcode}
        </Typography>
        <Typography>{today.format("dddd, Do MMMM YYYY")}</Typography>
        <Typography variant="body1" sx={{ margin: "2% 0" }}>
          Dear {props.employee.firstName}:
        </Typography>
        <Typography variant="body1" sx={{ margin: "1% 0" }}>
          When you commenced employment with us on{" "}
          {props.employee.startDate != null ? (
            new Date(props.employee.startDate).toLocaleDateString('en-GB')
          ) : (
            <>N/A</>
          )}{" "}
          in the capacity of {props.position}, you were informed that your
          employment would be subject to the satisfactory completion of a
          probationary period of{" "}
          {probLength === "" ? (
            <strong>SELECT PROBATION LENGTH</strong>
          ) : (
            probLength
          )}
          .
        </Typography>
        <Typography variant="body1" sx={{ margin: "1% 0" }}>
          I am delighted to confirm that you have successfully completed your
          probationary period.
        </Typography>
        <Typography variant="body1" sx={{ margin: "1% 0" }}>
          I am sure that in the future you will continue to carry out your
          duties with the high-level commitment and professionalism shown so
          far.
        </Typography>
        <Typography variant="body1" sx={{ margin: "1% 0" }}>
          Regards,
        </Typography>
        <Typography variant="body1" sx={{ margin: "1% 0" }}>
          {props.company.siteName} Management Team.
        </Typography>
      </Box>

      {showAlert ? (
        <Alert severity={severity}>{message}</Alert>
      ) : (
        <Stack
          direction="row"
          spacing={12}
          alignItems="center"
          justifyContent="center"
        >
          <Button variant="outlined" color="error" onClick={props.close}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={handleSendLetter}
            disabled={disabled}
          >
            Send letter
          </Button>
        </Stack>
      )}
    </Box>
  );
}
