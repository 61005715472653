import React from "react";
import {Typography, List, ListItem, ListItemText, Box} from "@mui/material";

export default function CompanyList({companies, onSelectCompany}) {
  return (
    <Box sx={{py: 5, px: 3, backgroundColor: "white"}}>
      <Typography variant="h6">Company List</Typography>
      <List>
        {companies.map((company) => (
          <ListItem
            key={company.companyId}
            button
            onClick={() => onSelectCompany(company)}
            sx={{p: 0, m: 0, textDecoration: "underline"}}
          >
            <ListItemText primary={company.companyName} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
}
