//Every Route is protected with a different level
//Please check level before adding a new Route

import "./App.scss";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./components/login/Login";
import Home from "./components/main/Home";
import ATSHome from "./components/main/ATSHome";
import Aplicant from "./components/main/ats/Aplicant";
import IndvApplicant from "./components/main/ats/IndvApplicant";
import { useState } from "react";
import UserContext from "./components/context/UserContext";
import OnboardingHome from "./components/main/onboarding/OnboardingHome";
import Contracts from "./components/main/onboarding/contracts/Contracts";
import InterviewCalendar from "./components/main/onboarding/interviews/InterviewCalendar";
import InterviewSchedule from "./components/main/onboarding/interviews/InterviewSchedule";
import RescheduleInterview from "./components/main/onboarding/interviews/RescheduleInterview";
import AdminTasks from "./components/main/adminTasks/AdminTasks";
import CreateCompany from "./components/main/adminTasks/CreateCompany";
import UpdateCompany from "./components/main/adminTasks/UpdateCompany";
import EmployeeManagement from "./components/main/employeeManagement/EmployeeManagement";
import { AdminProtected } from "./components/protectRoutes/AdminProtected";
import { UserProtected } from "./components/protectRoutes/UserProtected";
import { AllUsers } from "./components/protectRoutes/AllUsers";
import NoAccess from "./components/protectRoutes/NoAccess";
// import RotaHome from "./components/main/employeeManagement/rotas/RotaHome";
import RotaHome from "./components/main/employeeManagement/rotasV2/RotaHome";
import EmployeeDash from "./components/main/employeeManagement/EmployeeDash";
import ManualContract from "./components/main/onboarding/contracts/ManualContract";
import PayRates from "./components/main/adminTasks/PayRates";
import CreateRoles from "./components/main/adminTasks/CreateRoles";
import Probations from "./components/main/employeeManagement/probations/Probations";
import Leavers from "./components/main/employeeManagement/leavers/Leavers";
import InterviewStatus from "./components/main/onboarding/status/InterviewStatus";
import NewManager from "./components/main/onboarding/newManager/NewManager";
import DiscrepHome from "./components/main/adminTasks/payroll/discrepancies/DiscrepHome";
import LettersHome from "./components/main/adminTasks/payroll/letters/LettersHome";
import ManLeave from "./components/main/employeeManagement/leave/ManLeave";
import HolidayCalendar from "./components/main/employeeManagement/leave/HolidayCalendar";
import StaffBanking from "./components/main/employeeManagement/banking/StaffBanking";
import Payroll from "./components/main/employeeManagement/payroll/Payroll";
import HolidayGroups from "./components/main/adminTasks/HolidayGroups";
import PrintRotaHome from "./components/main/employeeManagement/rotasV2/print_rotas/PrintRotaHome";
import DashboardHome from "./components/main/employeeManagement/dashboard/DashboardHome";

function App() {
    const [userData, setUserData] = useState({
        token: undefined,
        user: undefined,
    });

    return (
        <div className="App">
            <BrowserRouter>
                <UserContext.Provider value={{ userData, setUserData }}>
                    <Routes>
                        <Route exact path="/" element={<Login />} />
                        <Route
                            path="home"
                            element={
                                <AllUsers>
                                    <Home />
                                </AllUsers>
                            }
                        />
                        <Route
                            path="not-allowed"
                            element={
                                <AllUsers>
                                    <NoAccess />
                                </AllUsers>
                            }
                        />
                        {/* <Route path="ats" element={<ATSHome />} />
            <Route path="ats/applicant" element={<Aplicant />} />
            <Route path="ats/applicant/indv" element={<IndvApplicant />} /> */}

                        {/* ADMIN */}
                        <Route
                            path="admin"
                            element={
                                <AdminProtected>
                                    <AdminTasks />
                                </AdminProtected>
                            }
                        />
                        <Route
                            path="admin/discrepancies"
                            element={
                                <AdminProtected>
                                    <DiscrepHome />
                                </AdminProtected>
                            }
                        />
                        <Route
                            path="admin/discrepancy-letters"
                            element={
                                <AdminProtected>
                                    <LettersHome />
                                </AdminProtected>
                            }
                        />

                        <Route
                            path="admin/update-company"
                            element={
                                <AdminProtected>
                                    <UpdateCompany />
                                </AdminProtected>
                            }
                        />
                        <Route
                            path="admin/create-company"
                            element={
                                <AdminProtected>
                                    <CreateCompany />
                                </AdminProtected>
                            }
                        />
                        <Route
                            path="admin/pay-rates"
                            element={
                                <AdminProtected>
                                    <PayRates />
                                </AdminProtected>
                            }
                        />
                        <Route
                            path="admin/roles"
                            element={
                                <AdminProtected>
                                    <CreateRoles />
                                </AdminProtected>
                            }
                        />
                        <Route
                            path="admin/staff-banking"
                            element={
                                <AdminProtected>
                                    <StaffBanking />
                                </AdminProtected>
                            }
                        />
                        <Route
                            path="admin/update-company"
                            element={
                                <AdminProtected>
                                    <UpdateCompany />
                                </AdminProtected>
                            }
                        />
                        <Route
                            path="admin/create-company"
                            element={
                                <AdminProtected>
                                    <CreateCompany />
                                </AdminProtected>
                            }
                        />
                        <Route
                            path="admin/pay-rates"
                            element={
                                <AdminProtected>
                                    <PayRates />
                                </AdminProtected>
                            }
                        />
                        <Route
                            path="admin/roles"
                            element={
                                <AdminProtected>
                                    <CreateRoles />
                                </AdminProtected>
                            }
                        />
                        <Route
                            path="admin/staff-banking"
                            element={
                                <AdminProtected>
                                    <StaffBanking />
                                </AdminProtected>
                            }
                        />
                        <Route
                            path="admin/holiday-groups"
                            element={
                                <AdminProtected>
                                    <HolidayGroups />
                                </AdminProtected>
                            }
                        />

                        {/* END ADMIN */}
                        {/* <Route path="onboarding/*" element={<OnboardingHome />}>
              <Route path="interview-schedule" element={<InterviewSchedule />}/>
            </Route> */}
                        {/* onboarding */}
                        <Route
                            path="onboarding"
                            element={
                                <UserProtected>
                                    <OnboardingHome />
                                </UserProtected>
                            }
                        />
                        <Route
                            path="onboarding/manual-contract"
                            element={
                                <UserProtected>
                                    <ManualContract />
                                </UserProtected>
                            }
                        />
                        <Route
                            path="onboarding/status"
                            element={
                                <UserProtected>
                                    <InterviewStatus />
                                </UserProtected>
                            }
                        />

                        <Route
                            path="interview-calendar"
                            element={
                                <UserProtected>
                                    <InterviewCalendar />
                                </UserProtected>
                            }
                        >
                            <Route
                                path="schedule"
                                element={
                                    <UserProtected>
                                        <InterviewSchedule />
                                    </UserProtected>
                                }
                            />
                            <Route
                                path=":id"
                                element={
                                    <UserProtected>
                                        <RescheduleInterview />
                                    </UserProtected>
                                }
                            />
                        </Route>
                        <Route
                            path="contracts"
                            element={
                                <UserProtected>
                                    <Contracts />
                                </UserProtected>
                            }
                        ></Route>
                        <Route
                            path="onboarding/new-manager"
                            element={
                                <UserProtected>
                                    <NewManager />
                                </UserProtected>
                            }
                        />
                        {/* end oboarding */}
                        {/* EMPLOYEE MANAGEMENT */}
                        <Route
                            path="employee-management"
                            element={
                                <UserProtected>
                                    <EmployeeDash />
                                </UserProtected>
                            }
                        />
                        <Route
                            path="employee-management/employee"
                            element={
                                <UserProtected>
                                    <EmployeeManagement />
                                </UserProtected>
                            }
                        />
                        <Route
                            path="employee-management/holiday-calendar"
                            element={
                                <UserProtected>
                                    <HolidayCalendar />
                                </UserProtected>
                            }
                        />
                        <Route
                            path="employee-management/manual-leave"
                            element={
                                <UserProtected>
                                    <ManLeave />
                                </UserProtected>
                            }
                        />
                        <Route
                            path="employee-management/dashboard"
                            element={
                                <UserProtected>
                                    <DashboardHome />
                                </UserProtected>
                            } />
                        <Route
                            path="employee-management/rotas"
                            element={
                                <UserProtected>
                                    <RotaHome />
                                </UserProtected>
                            }
                        />
                        <Route
                            path="employee-management/rotas/print-rota/:rotaId/:siteId/:weekStart"
                            element={
                                <UserProtected>
                                    <PrintRotaHome />
                                </UserProtected>
                            }
                        />
                        <Route
                            path="employee-management/leavers"
                            element={
                                <UserProtected>
                                    <Leavers />
                                </UserProtected>
                            }
                        />
                        <Route
                            path="employee-management/probations"
                            element={
                                <UserProtected>
                                    <Probations />
                                </UserProtected>
                            }
                        />
                        <Route
                            path="employee-management/payroll"
                            element={
                                <UserProtected>
                                    <Payroll />
                                </UserProtected>
                            }
                        />

                        {/* END EMPLOYEE MANAGEMENT */}
                    </Routes>
                </UserContext.Provider>
            </BrowserRouter>
        </div>
    );
}

export default App;
