import { useState, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import axios from "axios";
import { Alert, Button } from "@mui/material";

export default function UnassignedRole(props) {

    const [role, setRole] = useState({})
    const [siteList, setSiteList] = useState([])
    const [groupList, setGroupList] = useState([])
    const [chosenGroup, setChosenGroup] = useState([])
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [token, setToken] = useState("")
    const config = { headers: { "Content-Type": "application/json", "x-auth-token": token } };

    useEffect(() => {
        setRole(props.role)
        setGroupList(props.groupData)
        let checkId = localStorage.getItem("user");
        let parsed = JSON.parse(checkId);
        let token = parsed.token
        setToken(token)
        axios.get(`${process.env.REACT_APP_API_URL}/company`, { headers: { "Content-Type": "application/json", "x-auth-token": token } }).then((resp) => {
            setSiteList(resp.data)
        }).catch((err) => {
            setMessage("Something Went Wrong. Please Try Again.")
            setSeverity("error");
            setShowAlert(true)
            setTimeout(() => {
                setShowAlert(false)
                setMessage("")
                setSeverity("")
            }, 3000)
        })

    }, [props])

    const confirmAssignment = async () => {
        if (chosenGroup.length == 0) {
            setMessage("Select group before confirming!")
            setSeverity("warning");
            setShowAlert(true)
            setTimeout(() => {
                setShowAlert(false)
                setMessage("")
                setSeverity("")
            }, 3000)
        } else {
            let data = {
                roleId: role.roleId,
                groupData: chosenGroup
            }

            await axios.post(`${process.env.REACT_APP_API_URL}/groups/add-role`, data, config).then((resp) => {
                setMessage("Role has been successfully grouped.")
                setSeverity("success");
                setShowAlert(true)
                setTimeout(() => {
                    setShowAlert(false)
                    setMessage("")
                    setSeverity("")
                    props.reset();
                }, 3000)
            }).catch((err) => {
                setMessage("Something Went Wrong. Please Try Again.")
                setSeverity("error");
                setShowAlert(true)
                setTimeout(() => {
                    setShowAlert(false)
                    setMessage("")
                    setSeverity("")
                }, 3000)
            })
        }
    }

    const updateRoles = async (siteId, groupId) => {
        var idx = chosenGroup.findIndex((s) => s.siteId == siteId);
        if (idx >= 0) {
            if (chosenGroup[idx].groupId == groupId || groupId == 0) {
                setChosenGroup([...chosenGroup.slice(0, idx), ...chosenGroup.slice(idx + 1)]);
            } else {
                var newData = { siteId: chosenGroup[idx].siteId, groupId: groupId }
                setChosenGroup([...chosenGroup.slice(0, idx), newData, ...chosenGroup.slice(idx + 1)]);
            }
        } else {
            setChosenGroup(oldData => [...oldData, { siteId: siteId, groupId: groupId }])
        }
    }

    return (
        <>
            <Accordion sx={{ width: "100%", margin: "0 auto" }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    sx={{ fontSize: "1rem" }}
                >
                    <div className="shiftData">
                        <p>{role.roleName}</p>
                    </div>
                </AccordionSummary>
                <AccordionDetails sx={{ borderTop: "1px solid navy", textAlign:"center" }}>
                    {siteList.map(function (d, i) {
                        return (
                            <div key={i} style={{ display: "flex", justifyContent: "space-between", width: "75%", margin: "0 auto", marginBottom: "2%", borderBottom: "1px solid black" }}>
                                <p>{d.siteName}</p>
                                <select style={{ height: "50px" }} onChange={(e) => updateRoles(d.companyId, parseInt(e.target.value))}>
                                    <option value={0}>--- ASSIGN GROUP ---</option>
                                    {groupList.map(function (d, i) {
                                        return (
                                            <option value={d.groupId}>{d.name}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        )
                    })}
                    {showAlert ? (
                        <Alert severity={severity} >{message}</Alert>
                    ) : null}
                    <Button variant="contained" color="success" onClick={confirmAssignment}>Confirm</Button>
                    
                </AccordionDetails>
            </Accordion>
        </>
    )
}