import Header from "../../../header/Header";
import { useState, useEffect } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import moment from "moment";
import TinyLoader from "../../../animations/TinyLoader";
import "./leave.scss";
import {
  getCompanies,
  getEmployeesByCompany,
  getLeaveTypes,
} from "../../../../utils/apiUtils";
import { decryptData } from "../../../../utils/cryptoUtils";

export default function ManLeave() {
  const url = process.env.REACT_APP_API_URL;
  const [user, setUser] = useState("")
  const [token, setToken] = useState("");
  const [siteList, setSiteList] = useState([]);
  const [staffName, setStaffName] = useState("");
  const [staffNameList, setStaffNameList] = useState([]);
  const [leaveTypes, setLeaveTypes] = useState([]);
  const [leave, setLeave] = useState("");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [daysTaken, setDaysTaken] = useState("");
  //alert
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");

  const config = { headers: { "x-auth-token": token } };

  useEffect(() => {
    let user = localStorage.getItem("user");
    let parseU = JSON.parse(user);
    setUser(parseU.user)
    let localToken = parseU.token;
    setToken(localToken);
    const config = {
      headers: { "x-auth-token": localToken },
    };

    try {
      getCompanies(localToken)
        .then((sites) => {
          setSiteList(sites);
        })
        .catch((err) => {
          console.log(`Could not get sites: ${err}`);
        });
    } catch (err) {
      console.log(`Could not get sites: ${err}`);
    }

    getLeaveTypes(config)
      .then((leave) => {
        setLeaveTypes(leave);
      })
      .catch((err) => {
        console.log(`Error getting the leave types: ${err}`);
      });
  }, []);

  const handleSite = async (siteId) => {
    setStaffNameList([]);
    setStaffName("");
    await getEmployeesByCompany(siteId, token)
      .then((employees) => {
        for (let e = 0; e < employees.length; e++) {
          setStaffNameList((staffNameList) => [
            ...staffNameList,
            {
              firstName: decryptData(
                employees[e].firstName,
                process.env.REACT_APP_EMP_KEY
              ),
              lastName: decryptData(
                employees[e].lastName,
                process.env.REACT_APP_EMP_KEY
              ),
              employeeDetailsId: employees[e].employeeDetailsId,
            },
          ]);
        }
      })
      .catch((err) => {
        console.log(`could not fetch employees: ${err}`);
      });
  };

  const handleSubmit = () => {
    document.getElementById("submit_btn").style.display = "none";
    if (staffName === "--Please Select Staff--") {
      setMessage("Please select a member of staff");
      setSeverity("warning");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
        document.getElementById("submit_btn").style.display = "flex";
      }, 3000);
    } else if (start === "" || end === "") {
      setMessage("Please select start and end days");
      setSeverity("warning");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
        document.getElementById("submit_btn").style.display = "flex";
      }, 3000);
    } else if (daysTaken === "") {
      setMessage("Please select days taken");
      setSeverity("warning");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
        document.getElementById("submit_btn").style.display = "flex";
      }, 3000);
    } else if (parseFloat(daysTaken) < 0) {
      setMessage("Days taken cannot be less than 0");
      setSeverity("warning");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
        document.getElementById("submit_btn").style.display = "flex";
      }, 3000);
    } else {
      let data = {
        user: user,
        employeeDetailsId: staffName,
        leaveType: leave,
        startDate: moment(start).format("DD/MM/YYYY"),
        endDate: moment(end).format("DD/MM/YYYY"),
        days: daysTaken,
      };
      axios
        .post(`${url}/holiday/submit/manual/new`, data, config)
        .then((res) => {
          console.log(res.data);
          setMessage("Holiday submitted");
          setSeverity("success");
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
            document.getElementById("submit_btn").style.display = "flex";
          }, 3000);
        })
        .catch((err) => {
          console.log(`error submiting: ${err}`);
          setMessage("Something went wrong, please try again");
          setSeverity("error");
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
            document.getElementById("submit_btn").style.display = "flex";
          }, 3000);
        });
    }
  };

  return (
    <main className="manual_leave">
      <Header />
      <h1>Please select manual leave for an employee</h1>
      <label htmlFor="where" className="main_label">
        What site does the employee work at?
      </label>
      <select name="where" onChange={(e) => handleSite(e.target.value)}>
        <option>--Select One--</option>
        {siteList.map(function (d, i) {
          return (
            <option key={i} value={d.companyId}>
              {d.siteName}
            </option>
          );
        })}
      </select>
      <section className="form">
        {staffNameList.length < 1 ? (
          <TinyLoader />
        ) : (
          <>
            <label>
              Staff Name<span>*</span>
            </label>
            <select onChange={(e) => setStaffName(e.target.value)}>
              <option>--Please Select Staff--</option>
              {staffNameList.map((staff, i) => {
                return (
                  <option key={i} value={staff.employeeDetailsId}>
                    {staff.firstName} {staff.lastName}
                  </option>
                );
              })}
            </select>
          </>
        )}
        {staffName != "" && staffName != "--Please Select Staff--" ? (
          <>
            <label>
              Leave type <span>*</span>
            </label>
            <select onClick={(e) => setLeave(e.target.value)}>
              <option>--Please select one--</option>
              {leaveTypes.map((leave, index) => {
                return (
                  <option key={index} value={leave.leaveId}>
                    {leave.name}
                  </option>
                );
              })}
            </select>
            <label>
              Start date <span>*</span>
            </label>
            <input type="date" onChange={(e) => setStart(e.target.value)} />
            <label>
              {" "}
              End date <span>*</span>
            </label>
            <input type="date" onChange={(e) => setEnd(e.target.value)} />
            <label>Days taken</label>
            <input
              type="number"
              onChange={(e) => setDaysTaken(e.target.value)}
              pattern="[0-9]*"
            />
          </>
        ) : (
          <label>Please select staff member</label>
        )}
      </section>
      <Button
        color="success"
        variant="contained"
        disabled={!leave || leave === "--Please select one--"}
        onClick={handleSubmit}
        id="submit_btn"
      >
        Submit
      </Button>
      {showAlert ? (
        <Alert severity={severity} sx={{ marginTop: "1%" }}>
          {message}
        </Alert>
      ) : null}
    </main>
  );
}
