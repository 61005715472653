import React, {useState, useEffect} from "react";
import {
  Typography,
  Box,
  TextField,
  Button,
  IconButton,
  Alert,
} from "@mui/material";
import {Edit} from "@mui/icons-material";
// import {useAlert} from "../../context/AlertContext";
import { useAlert } from "../../context/AlertContext";

export default function CompanyDetails({
  company,
  onEditCompany,
  onDeleteCompany,
}) {
  const [editingField, setEditingField] = useState(null);
  const [editedCompany, setEditedCompany] = useState({...company});
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);

  const {showAlert} = useAlert();

  const isFieldNotEmpty = (val) => typeof val !== "string" || val.trim() !== "";
  const requiredFields = [
    "companyName",
    "siteName",
    "addressLine1",
    "city",
    "county",
    "postcode",
    "phoneNumber",
  ];

  useEffect(() => {
    setIsSaveEnabled(
      requiredFields.every((field) => isFieldNotEmpty(editedCompany[field]))
    );
  }, [editedCompany]);

  const toggleEdit = (field) => {
    setEditingField(field);
  };

  useEffect(() => {
    setEditedCompany({...company});
  }, [company]);

  const handleSaveClick = async () => {
    if (isValidCompany(editedCompany)) {
      try {
        onEditCompany(editedCompany);
        setEditingField(null);
      } catch (error) {
        console.error("Error updating company:", error);
        showAlert("Error updating company");
      }
    } else {
      console.error("Invalid company data");
      showAlert("Invalid company data");
    }
  };

  const handleDeleteClick = async () => {
    try {
      await onDeleteCompany(editedCompany.companyId);

      showAlert("Company deleted successfully", "success");
    } catch (error) {
      console.error("Error deleting company:", error);
      showAlert("Error deleting company");
    }
  };

  const updateField = (field, value) => {
    setEditedCompany((prevCompany) => ({
      ...prevCompany,
      [field]: value,
    }));
  };

  const isValidCompany = (company) => {
    return requiredFields.every((field) => isFieldNotEmpty(company[field]));
  };

  const renderField = (label, field, isEditable, fieldType = "text") => {
    return (
      <Box display="flex" alignItems="baseline" gap="10px">
        <Typography sx={{fontWeight: "bold"}}>{label}: </Typography>
        <Typography>{company[field]}</Typography>
        {editingField === field ? (
          <TextField
            size="small"
            type={fieldType}
            value={editedCompany[field] || ""}
            onChange={(e) => {
              let maxLength = 50;
              if (field === "phoneNumber") {
                maxLength = 14;
              }
              if (e.target.value.length <= maxLength) {
                updateField(field, e.target.value);
              }
            }}
            inputProps={{
              maxLength: 50,
            }}
          />
        ) : (
          <>
            {isEditable && (
              <IconButton onClick={() => toggleEdit(field)}>
                <Edit fontSize="small" />
              </IconButton>
            )}
          </>
        )}
      </Box>
    );
  };

  return (
    <Box sx={{p: 5, backgroundColor: "white"}}>
      <Typography component="h2" variant="h6" sx={{mb: 1}}>
        Company Details
      </Typography>
      {renderField("*Company Name", "companyName", true)}
      {renderField(" Site Name", "siteName", false)}
      {renderField("*Address Line 1", "addressLine1", true)}
      {renderField(" Address Line 2", "addressLine2", true)}
      {renderField("*City", "city", true)}
      {renderField("*County", "county", true)}
      {renderField("*Postcode", "postcode", true)}
      {renderField("*Phone Number", "phoneNumber", true, "number")}

      {editingField && (
        <Button
          variant="contained"
          onClick={handleSaveClick}
          disabled={!isSaveEnabled}
          sx={{mt: 2, mr: 2}}
        >
          Update
        </Button>
      )}
      <Button
        variant="contained"
        color="error"
        onClick={handleDeleteClick}
        sx={{mt: 2}}
      >
        Delete
      </Button>
      {!isValidCompany(editedCompany) && (
        <Box mt={2}>
          <Alert severity="error">Please fill in all required fields.</Alert>
        </Box>
      )}
    </Box>
  );
}
