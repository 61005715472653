// use this route protection when only access by managers/directors/devs
//check UserProtected if you are looking for less protection

import { Navigate } from "react-router-dom";

const AdminProtected =  ({children}) => {
    let checkId = localStorage.getItem("user")
    let parsed = JSON.parse(checkId)
    let id = parsed.id
    if (id != 2 && id != 3){
        return <Navigate to="/not-allowed" replace />
    }
    return children
}

export {AdminProtected}