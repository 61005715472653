import Box from "@mui/material/Box";
import { Button, Stack, Alert, IconButton } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Typography from "@mui/material/Typography";
import logo from "../../../adminTasks/payroll/letters/modals/RT_Logo.png";
import Divider from "@mui/material/Divider";
import html2pdf from "html2pdf.js";
import moment from "moment";
import { useState, useEffect } from "react";
import { getS3Link } from "../../../../../utils/apiUtils";
import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  maxHeight: "90vh",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "1rem",
  boxShadow: 24,
  p: 4,
  overflowY: "scroll",
};

export default function ProbFail(props) {
  const url = process.env.REACT_APP_API_URL;
  const today = moment();
  const [concerns, setConcerns] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [token, setToken] = useState("");
  const config = { headers: { "x-auth-token": token } };
  const [user, setUser] = useState("");
  const [reasons, setReasons] = useState([]);
  const [note, setNote] = useState("");
  const [returnDate, setReturnDate] = useState("");
  //   alert
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    let user = localStorage.getItem("user");
    let parseU = JSON.parse(user);
    setToken(parseU.token);
    setUser(parseU.user);
  }, []);

  const getTargetElement = () => document.getElementById("letter");

  const handleSendLetter = async () => {
    if (reasons.length == 0 || returnDate == "") {
      setMessage(`Please make sure all field are filled`);
      setSeverity("warning");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
        setDisabled(false);
      }, 3500);
    } else {
      setDisabled(true);

      const data = {
        type: 25,
        employeeDetailsId: props.employee.employeeDetailsId,
        fileName: `${props.employee.employeeDetailsId
          }-Email - Probation Failed-${moment().format("YYYY-MM-DD-HH-mm")}.pdf`,
        user: user
      };
      await getS3Link(data, config)
        .then((res) => {
          console.log(res);
          createPdf(res);
        })
        .catch((err) => {
          console.log(`Could not get the S3 link: ${err.response.data}`);
          setMessage(`Could not upload the document, please try again`);
          setSeverity("warning");
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
            setDisabled(false);
          }, 3500);
        });
    }
  };

  const createPdf = async (details) => {
    const element = getTargetElement();
    let opt = {
      html2canvas: { scale: 2 },
      margin: [1.4, 0.7, 1.7, 0.7], //10.5, 1, 26.5, 0 //Top, Left, Bottom, Right
      jsPDF: { unit: "cm", format: "a4", orientation: "portrait" },
    };

    const pdfNew = await html2pdf()
      .from(element)
      .set(opt)
      .output("arraybuffer");
    console.log("------SENDING LETTER------");

    // Upload to S3
    await axios
      .put(details.url, pdfNew)
      .then((res) => {
        // TODO: Sent email with the attachment
        let resend = false;
        const data = {
          documentId: details.documentId,
          user: user,
          resend: resend,
        };
        axios
          .post(`${url}/employee/emailS3Documents`, data, config)
          .then((res) => {
            setMessage(`Letter sent`);
            setSeverity("success");
            setShowAlert(true);
            setTimeout(() => {
              setShowAlert(false);
              props.close();
            }, 3500);
          })
          .catch((err) => {
            console.log(`Could not send the email: ${err}`);
            setMessage(
              `Could not send the email to the employee, please try again`
            );
            setSeverity("error");
            setShowAlert(true);
            setTimeout(() => {
              setShowAlert(false);
            }, 3500);
          });
      })
      .catch((err) => {
        setDisabled(false);
        console.log(`Could not upload to S3: ${err.response.data}`);
        setMessage(`Could not upload the document, please try again`);
        setSeverity("error");
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 3500);
      });
  };

  function handlePlus(index) {
    if (note === "") {
      alert("All values must be filled")
    } else {
      setReasons([...reasons, { note: note }]);
      setNote("");
    }
  }

  return (
    <Box sx={style}>
      <Typography
        id="modal-modal-title"
        variant="h6"
        component="h2"
        sx={{
          textAlign: "center",
          marginBottom: "20px",
        }}
      >
        Probationary period failed letter
      </Typography>
      <Typography variant="body2">Please select date for return of possessions:</Typography>
      <input type="date" onChange={(e) => setReturnDate(e.target.value)} />
      <Typography variant="body2">Please type concerns:</Typography>
      <textarea
        onChange={(e) => setNote(e.target.value)}
        cols={50}
        rows={5}
        spellcheck="true"
        value={note}
      /><IconButton>
        <CheckCircleIcon
          fontSize="large"
          sx={{ color: "#2c53a0" }}
          onClick={() => handlePlus(reasons.length)}
        />
      </IconButton>
      <Divider sx={{ margin: "1% auto" }} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          justifyContent: "flex-start",
        }}
        id="letter"
      >
        <Box
          sx={{
            display: "flex",
            alignContent: "center",
            justifyContent: "space-between",
            margin: "2% auto",
          }}
        >
          <img src={logo} alt="RT Logo" width="20%" />
          <Typography variant="subtitle1">
            Private &amp; Confidential
          </Typography>
        </Box>
        <Typography variant="h3" sx={{ textAlign: "center" }}>
          {props.company.companyName}
        </Typography>
        <Typography variant="h6" sx={{ textAlign: "center" }}>
          {props.company.addressLine1}, {props.company.city},{" "}
          {props.company.postcode}
        </Typography>
        <Typography>{today.format("dddd, Do MMMM YYYY")}</Typography>
        <Typography variant="body1" sx={{ margin: "2% 0" }}>
          Dear {props.employee.firstName}:
        </Typography>
        <Typography variant="body1" sx={{ margin: "1% 0" }}>
          As you are aware, when you started work with us, we had high hopes and
          expectations that you would meet the standards we require.
          Unfortunately, this has not proved to be the case.
        </Typography>
        <Typography variant="body1" sx={{ margin: "1% 0" }}>
          As you are aware our concerns were:
        </Typography>
        {reasons.length > 0 ? (
          <>
            {reasons.map(function (d, i) {
              return (
                <li style={{ marginLeft: "3%" }}>{d.note}</li>
              );
            })}
          </>
        ) : (
          <Typography variant="h6">
            TYPE YOUR CONCERNS ON THE BOX ABOVE
          </Typography>
        )}
        <Typography variant="body1" sx={{ margin: "1% 0" }}>
          Having given careful consideration to the above points, it is with
          regret that I confirm that for the above reasons that you have failed
          your probationary period and therefore your employment is terminated
          with immediate effect.
        </Typography>
        <Typography variant="body1" sx={{ margin: "1% 0" }}>
          I will arrange for any outstanding wages to be sent to you under
          separate cover together with your P45.
        </Typography>
        <Typography>
          When you started your employment with us you signed a uniform policy document which states you are
          aware and understand that these items are for work purposes only and should be laundered and returned
          within 5 working days. Please return all company property including keys and uniform which are in your
          possession by
          {returnDate == "" ?
            <strong> [ PLEASE SELECT DATE ] </strong>
            : <> {moment(returnDate, "YYYY-MM-DD").format("dddd Do MMMM YYYY")
              // new Date(returnDate).toLocaleDateString("en-GB")
            }</>
          }.
        </Typography>
        <Typography variant="body1" sx={{ margin: "1% 0" }}>
          You may appeal against my decision and should you wish to do so, you
          should write to HR within five days giving the full reasons for your
          appeal.
        </Typography>
        <Typography variant="body1" sx={{ margin: "1% 0" }}>
          Yours Sincerely,
        </Typography>
        <Typography variant="body1" sx={{ margin: "1% 0" }}>
          {props.company.siteName} Management Team.
        </Typography>
      </Box>

      {showAlert ? (
        <Alert severity={severity}>{message}</Alert>
      ) : (
        <Stack
          direction="row"
          spacing={12}
          alignItems="center"
          justifyContent="center"
        >
          <Button variant="outlined" color="error" onClick={props.close}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={handleSendLetter}
            disabled={disabled}
          >
            Send letter
          </Button>
        </Stack>
      )}
    </Box>
  );
}
