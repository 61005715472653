//Packages
import moment from "moment";
//MUI
import FollowTheSignsIcon from "@mui/icons-material/FollowTheSigns";
import { Modal } from "@mui/material";
//Components
import LeaveModal from "./LeaveModal";
import { useEffect, useState } from "react";

export default function IndvWeek({ week, employee }) {
    const [leaveModal, setLeaveModal] = useState(false);
    const [actualWeek, setActualWeek] = useState({});

    useEffect(() => {
        setActualWeek(employee[week.keyName]);
    }, []);
    return (
        <tr
            style={{
                position: "relative",
            }}
        >
            <th>
                {(actualWeek.holidays && actualWeek.holidays.length > 0) ||
                (actualWeek.sickness && actualWeek.sickness.length > 0) ? (
                    <FollowTheSignsIcon
                        color="primary"
                        sx={{
                            position: "absolute",
                            left: "5px",
                            cursor: "pointer",
                        }}
                        onClick={() => setLeaveModal(true)}
                    />
                ) : null}
                Week {week.calanderWeekNo} (
                {moment(week.start).format("DD/MM/YY")} -{" "}
                {moment(week.end).format("DD/MM/YY")})
            </th>
            <td>{employee[week.keyName].shift}</td>
            <td>{employee[week.keyName].actual}</td>
            {/* LEAVE MODAL */}
            <Modal open={leaveModal}>
                <LeaveModal
                    actualWeek={actualWeek}
                    setLeaveModal={setLeaveModal}
                />
            </Modal>
        </tr>
    );
}
