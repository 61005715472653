import "moment/min/locales";
import PrintIndvWeek from "./PrintIndvWeek";
import "../rota.scss";

export default function PrintRota({
    site,
    siteName,
    fullDates,
    staffList,
    published,
    currentDate,
    rotaId,
    shortDates,
    user,
    reloadData,
    confirmedDates,
}) {
    return (
        <table>
            <thead>
                <tr>
                    <th colSpan={1}>Name</th>
                    {shortDates.map(function (day, i) {
                        return <th key={i}>{day}</th>;
                    })}
                </tr>
            </thead>
            <tbody>
                {staffList.map(function (staffMember, i) {
                    let showBrand = false;
                    if (!staffList[i - 1]) {
                        showBrand = true
                    } else if (staffList[i - 1].name !== staffMember.name) {
                        showBrand = true
                    }
                    return (
                        <>{showBrand ?
                            <tr style={{ backgroundColor: staffMember.brandColour }}>
                                <td colSpan={8}><p style={{fontWeight: "bold", color: "#000"}}>{staffMember.name.toUpperCase()}</p></td>
                            </tr>
                            : null}
                            <PrintIndvWeek
                                key={i}
                                staffMember={staffMember}
                                fullDates={fullDates}
                                currentDate={currentDate}
                                site={site}
                                siteName={siteName}
                                rotaId={rotaId}
                                user={user}
                                published={published}
                                shortDates={shortDates}
                                reloadData={reloadData}
                                confirmedDates={confirmedDates}
                            />
                        </>
                    );
                })}
            </tbody>
        </table>
    );
}
